import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SolutionSpace } from 'src/app/models/solution';

@Component({
  selector: 'app-zx-component-detail-preferences',
  templateUrl: './zx-component-detail-preferences.component.html',
  styleUrls: ['./zx-component-detail-preferences.component.scss'],
})
export class ZxComponentDetailPreferencesComponent implements OnInit, OnDestroy {
  private paramSubscription: Subscription;
  productType: SolutionSpace;
  componentId: string;

  projectPreferencesControl = new UntypedFormControl(true);
  shockCheckControl = new UntypedFormControl(true);
  shockControl = new UntypedFormControl('5.0');
  batteryCheckControl = new UntypedFormControl(true);
  batteryControl = new UntypedFormControl('20');
  commCheckControl = new UntypedFormControl(true);

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.paramSubscription = this.route.params.subscribe((el) => {
      this.productType = el.utilorhumid as SolutionSpace;
      this.componentId = el.id as string;
    });
  }

  ngOnDestroy(): void {
    this.paramSubscription.unsubscribe();
  }
}
