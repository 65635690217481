<app-component-incidents
  *ngIf="solutionSpace$ | async as solutionSpace"
  [solutionSpace]="solutionSpace"
  [incidents$]="incidents$"
  [updateIncidentsReponse$]="updateIncidentsResponse$"
  (changeIncidentsFilter)="onChangeIncidentsFilter($event)"
  (changePagination)="onChangePagination($event)"
  (updateIncidents)="onUpdateIncidents($event)"
>
</app-component-incidents>
