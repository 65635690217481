import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectableOption } from '../filter.component';

@Component({
  selector: 'app-filter-measurement',
  templateUrl: './filter-measurement.component.html',
  styleUrls: ['./filter-measurement.component.scss'],
})
export class FilterMeasurementComponent implements OnInit {
  @Input() measurementOptions: SelectableOption[] = [];

  @Output() apply = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() optionChange: EventEmitter<SelectableOption> = new EventEmitter<SelectableOption>();

  currentSelectedStateFilter = '';

  ngOnInit(): void {
    const selectedOption = this.measurementOptions.find((option) => option.isSelected);

    if (selectedOption) {
      this.currentSelectedStateFilter = selectedOption.value;
    }
  }

  onApply() {
    this.apply.emit();
  }

  onBack() {
    this.back.emit();
  }

  onOptionChange(option: SelectableOption) {
    this.optionChange.emit(option);
  }
}
