<div class="lifeCycle-wrapper">
  <mat-card class="info-card">
    <div class="cycle-content">
      <div class="title">Turns monitoring</div>
      <div class="desc">
        Turn Monitoring allows you to track the turns of your component. You can view the current turn count for each
        component and set the equipment to “In service" or “Release it”. This information can help you optimize the
        usage and maintenance of your transport equipment."
      </div>
      <table>
        <tr class="border-bottom-2">
          <th style="width: 5%">Turns</th>
          <th style="width: 10%">Start date</th>
          <th style="width: 10%">End date</th>
          <th style="width: 75%">Journey</th>
        </tr>
        <ng-container *ngIf="isLoaded">
          <tr *ngFor="let c of cycles; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ c.startDate }}</td>
            <td>{{ i === cycles.length - 1 ? "" : c.endDate }}</td>
            <td class="flex-space-between">
              <div
                [ngClass]="i !== c.journey.length - 1 ? 'container' : 'align-center'"
                style.width="calc({{
                  calculateJourneyWidth(journey.endDate, journey.startDate, c.totalCycleDays)
                }}% + 50px)"
                *ngFor="let journey of c.journey; let i = index"
              >
                <svg-icon
                  [matTooltipDisabled]="!journey.showTooltip"
                  matTooltip="{{ displayTooltipText(journey) }}"
                  matTooltipClass="journeyTooltip"
                  [matTooltipPosition]="'below'"
                  [svgStyle]="{ 'padding-right.px': 8, 'padding-left.px': 8 }"
                  src="{{ getJourneyIcon(journey.type) }}"
                ></svg-icon>
                <div class="line"><mat-divider></mat-divider></div>
              </div>

              <button
                class="service-button receive"
                mat-raised-button
                *ngIf="!c.isCompleted && c.journey.length > 1"
                (click)="setStateToReceived()"
              >
                <svg-icon
                  [svgStyle]="{ 'width.px': 32, 'height.px': 32, 'margin-right.px': 4 }"
                  src="/assets/icons/journey/service.svg"
                ></svg-icon>
                Receive
              </button>

              <button
                class="service-button release"
                mat-raised-button
                *ngIf="c.isCompleted && c.journey.length === 1"
                (click)="setStateToReleasedFromService()"
              >
                <svg-icon
                  [svgStyle]="{ 'width.px': 32, 'height.px': 32, 'margin-right.px': 4 }"
                  src="/assets/icons/journey/active.svg"
                ></svg-icon>
                Release
              </button>
            </td>
          </tr>
        </ng-container>
      </table>
      <div class="center-item text-empty-label" *ngIf="!isLoaded">loading life turns .....</div>
    </div>
  </mat-card>
</div>
