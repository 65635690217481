import { Store, StoreConfig } from '@datorama/akita';

export interface AuthState {
  redirectPath?: string;
}

export function createInitialState(): AuthState {
  return {};
}

@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState());
  }
}
