/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authCodeFlowConfig: AuthConfig = {
  issuer: environment.issuer,
  redirectUri: window.location.origin + '/callback',
  clientId: environment.clientId,
  logoutUrl: window.location.origin + '/landing',
  responseType: 'code',
  scope: 'openid',
  showDebugInformation: true,
  strictDiscoveryDocumentValidation: false,
  timeoutFactor: 0.75,
};
