/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, OnInit } from '@angular/core';
import { UserQuery } from '../services/api/state/user.query';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  canAccessComponent$: Observable<boolean>;
  canAccessProject$: Observable<boolean>;
  canAccessSetting$: Observable<boolean>;

  infoUrl: string = environment.infopage;

  constructor(private userQuery: UserQuery, private authService: AuthenticationService) {}

  ngOnInit(): void {
    const canAccess$ = this.userQuery.organisation$.pipe(
      map((org) => {
        return org?.canAccess || [];
      })
    );
    this.canAccessComponent$ = canAccess$.pipe(
      map((canAccess) => {
        return (
          !!this.authService.hasValidAccessToken && canAccess.includes('web_app') && canAccess.includes('components')
        );
      })
    );
    this.canAccessProject$ = canAccess$.pipe(
      map((canAccess) => {
        return (
          !!this.authService.hasValidAccessToken && canAccess.includes('web_app') && canAccess.includes('projects')
        );
      })
    );
    this.canAccessSetting$ = canAccess$.pipe(
      map((canAccess) => {
        return (
          !!this.authService.hasValidAccessToken && canAccess.includes('web_app') && canAccess.includes('settings')
        );
      })
    );
  }

  logout(): void {
    this.authService.logoutCognitoDirect();
  }
}
