import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SolutionSpace } from 'src/app/models/solution';
import { zxProjectModel } from 'src/app/models/zxProject.model';
import { ProjectsService } from 'src/app/services/api/projects.service';

@Component({
  selector: 'app-zx-project-edit',
  templateUrl: './zx-project-edit.component.html',
  styleUrls: ['./zx-project-edit.component.scss'],
})
export class ZxProjectEditComponent implements OnInit {
  project: zxProjectModel;
  private paramSubscription: Subscription;
  constructor(private projectsService: ProjectsService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.paramSubscription = this.route.queryParams.subscribe((el) => {
      const solutionSpace = el.solution === 'humidity' ? SolutionSpace.Humidity : SolutionSpace.Utilization;

      const projectId = el.id as string;
      if (projectId)
        this.projectsService.getProject(solutionSpace, projectId).subscribe((p) => {
          this.project = p;
        });
    });
  }
}
