<app-info-page
  title="{{ component ? component.name : '' }}"
  [hasBorderBottom]="true"
  [links]="links"
  [isComponentOrProject]="'COMPONENT'"
  [breadcrumbs]="breadcrumbs"
>
  <button
    *ngIf="costumButtom?.visible"
    [class]="costumButtom?.cssStyle"
    color="primary"
    mat-flat-button
    ngProjectAs="buttons"
    style="margin-right: 8px"
    (click)="costumButtom?.action()"
  >
    {{ costumButtom?.text }}
  </button>

  <button
    style="margin-left: 8px"
    mat-flat-button
    class="btn-grey"
    id="btn-edit-component"
    [routerLink]="['/components/edit']"
    [queryParams]="{
      solution: solutionSpace,
      id: component.id,
      origin: origin
    }"
    ngProjectAs="buttons"
  >
    <mat-icon>settings</mat-icon>
    Edit component
  </button>
</app-info-page>
