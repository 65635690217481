import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { uiState, popupState } from '../interfaces/ui-state.interface';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PolytechUiService {
  public svgSprite =
    'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgNjAgNjAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDYwIDYwOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CgoKPGcgaWQ9ImFycm93LWRvd24iPgoJPHBvbHlnb24gcG9pbnRzPSIxNy41OCAyMy45OSAyMC42NCAyMS40MiAzMC4yMSAzMi44MyAzOS43OSAyMS40MiA0Mi44NSAyMy45OSAzMC4yMSAzOS4wNiAxNy41OCAyMy45OSIvPgo8L2c+CjxnIGlkPSJhcnJvdy1sZWZ0Ij4KCTxwb2x5Z29uIHBvaW50cz0iMzYuMDEgMTcuNTggMzguNTggMjAuNjQgMjcuMTcgMzAuMjEgMzguNTggMzkuNzkgMzYuMDEgNDIuODUgMjAuOTQgMzAuMjEgMzYuMDEgMTcuNTgiLz4KPC9nPgo8ZyBpZD0iYXJyb3ctcmlnaHQiPgoJPHBvbHlnb24gcG9pbnRzPSIyMy41MSAxNy41OCAyMC45NCAyMC42NCAzMi4zNSAzMC4yMSAyMC45NCAzOS43OSAyMy41MSA0Mi44NSAzOC41OCAzMC4yMSAyMy41MSAxNy41OCIvPgo8L2c+CjxnIGlkPSJhcnJvdy11cCI+Cgk8cG9seWdvbiBwb2ludHM9IjQyLjY0IDM1LjI1IDM5LjU3IDM3LjgyIDMwIDI2LjQxIDIwLjQzIDM3LjgyIDE3LjM2IDM1LjI1IDMwIDIwLjE4IDQyLjY0IDM1LjI1Ii8+CjwvZz4KPGcgaWQ9ImNhbGVuZGFyIj4KCTxwYXRoIGQ9Ik0yNSwxMHY1SDM1VjEwaDcuNXY1aDVBMi41LDIuNSwwLDAsMSw1MCwxNy41aDB2MzBBMi41LDIuNSwwLDAsMSw0Ny41LDUwaC0zNUEyLjUsMi41LDAsMCwxLDEwLDQ3LjVoMHYtMzBBMi41LDIuNSwwLDAsMSwxMi41LDE1aDVWMTBaTTQ1LDIyLjVIMTVWNDVINDVaTTI1LDM1djVIMjBWMzVabTcuNSwwdjVoLTVWMzVaTTQwLDM1djVIMzVWMzVaTTI1LDI3LjV2NUgyMHYtNVptNy41LDB2NWgtNXYtNVptNy41LDB2NUgzNXYtNVoiLz4KPC9nPgoKPGcgaWQ9ImNsb3NlIj4KCTxwb2x5Z29uIHBvaW50cz0iMzguMiAxOC43NiA0MS4yNCAyMS44IDMzLjA0IDMwIDQxLjI0IDM4LjIgMzguMiA0MS4yNCAzMCAzMy4wNCAyMS44IDQxLjI0IDE4Ljc2IDM4LjIgMjYuOTYgMzAgMTguNzYgMjEuOCAyMS44IDE4Ljc2IDMwIDI2Ljk2IDM4LjIgMTguNzYiLz4KPC9nPgoKPC9zdmc+CgoKCg==';
  public numberOfInitialDays = 7;

  public nonNavigableoStates: Array<string> = [''];
  public blockedNavigationState(state: string): boolean {
    return this.nonNavigableoStates.indexOf(state) !== -1;
  }

  public blockDirtyUi = false;

  public blockedUIState(state: string, uiElement: HTMLElement): boolean {
    const genState: boolean = this.nonNavigableoStates.indexOf(state) !== -1;
    const popupState = false; // this.service.state.context.popup?.contains(uiElement) && state === 'popup';
    return genState && !popupState;
  }

  public uiState$: BehaviorSubject<uiState> = new BehaviorSubject<uiState>({ value: '__void__' });
  public popupState$: BehaviorSubject<popupState> = new BehaviorSubject<popupState>({
    open: false,
    element: undefined,
  });

  public bindStateMachine(stateMachine$: Observable<any>): void {
    stateMachine$.pipe(
      map((state: uiState) => {
        this.uiState$.next({ value: state.value });
      })
    );
  }

  public popupState(open: boolean, element?: HTMLElement): void {
    this.popupState$.next({ open: open, element: element });
  }
}
