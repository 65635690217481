import { Component } from '@angular/core';
import { UserQuery } from '../services/api/state/user.query';
import { filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { AuthQuery } from '../services/authentication/state/auth.query';
import { AuthStore } from '../services/authentication/state/auth.store';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss'],
})
export class CallbackComponent {
  constructor(
    private userQuery: UserQuery,
    private router: Router,
    private authQuery: AuthQuery,
    private authStore: AuthStore
  ) {}

  redirectSubscription = combineLatest([this.authQuery.redirectPath$, this.userQuery.canAccess$])
    .pipe(
      filter(([, canAccess]) => canAccess.length > 0),
      take(1)
    )
    .subscribe({
      next: ([redirectPath, canAccess]) => {
        if (redirectPath) {
          void this.router.navigateByUrl(redirectPath);
        } else {
          void this.router.navigateByUrl(this.homeRoute(canAccess));
        }
        this.authStore.update({ redirectPath: undefined });
      },
    });

  homeRoute(canAccess: string[]): string {
    if (!canAccess.includes('web_app')) {
      return '/forbidden';
    }

    if (canAccess.includes('components')) {
      if (canAccess.includes('utilization')) {
        return '/components/utilization';
      } else {
        return '/components/humidity';
      }
    }

    if (canAccess.includes('projects')) {
      if (canAccess.includes('utilization')) {
        return '/projects/utilization';
      } else {
        return '/projects/humidity';
      }
    }
    return '/forbidden';
  }
}
