<app-filter-option
  icon="/assets/icons/componentTypes.svg"
  [title]="'FILTER.MEASUREMENT.TITLE' | translate"
  (apply)="onApply()"
  (back)="onBack()"
>
  <mat-radio-group [(ngModel)]="currentSelectedStateFilter">
    <div *ngFor="let option of measurementOptions">
      <mat-radio-button
        class="radio"
        color="primary"
        (click)="$event.stopPropagation()"
        (change)="onOptionChange(option)"
        [value]="option.value"
      >
        {{ option.label | translate }}
      </mat-radio-button>
    </div>
  </mat-radio-group>
</app-filter-option>
