<div class="container">
  <h2 mat-dialog-title>{{ "component.INCIDENTS.ACKNOWLEDGE_MULTIPLE.TITLE" | translate : { count: count } }}</h2>

  <div class="form-field-container comment" style="margin-top: 13px">
    <p class="form-field-header">
      <span>{{ "component.INCIDENTS.ACKNOWLEDGE_MULTIPLE.COMMENT" | translate }}</span
      ><span style="float: right">{{ commentLength }}/{{ maxCommentLength }}</span>
    </p>
    <mat-form-field style="width: 100%; height: 95px">
      <textarea
        matInput
        name="commment"
        [formControl]="commentControl"
        placeholder="Comment"
        value="incidentView.comment"
        (input)="onCommentChange()"
      ></textarea>
    </mat-form-field>
  </div>

  <mat-dialog-actions style="float: right; padding: 0px 0px 24px 0px">
    <button mat-flat-button [mat-dialog-close]="null">{{ "SHARED.CANCEL" | translate }}</button>
    <button style="padding: 0px 34px" mat-flat-button [mat-dialog-close]="dialogState" mat-primary color="primary">
      {{ "SHARED.DONE" | translate }}
    </button>
  </mat-dialog-actions>
  <div style="clear: both"></div>
</div>
