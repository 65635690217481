import { Type } from 'class-transformer';
export class Measurement {
  measurementType: MeasurementsType;
  value: number;
  preferencesAtTimeOfMeasurement: {
    availabilityRangeLow: number;
    availabilityRangeHigh: number;
    batteryThreshold: number;
    humidityThreshold: number;
    normalizedShockEnergyIncidentThreshold: number;
  };
  @Type(() => Date)
  timestamp: Date;
}

export enum MeasurementsType {
  ACC_X,
  ACC_Y,
  ACC_Z,
  Humidity,
  Temperature,
  Distance,
  NormalizedShockEnergy,
}
