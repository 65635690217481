import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZxProjectsOverviewComponent } from './zx-projects-overview/zx-projects-overview.component';
import { SharedModule } from '../shared/shared.module';
import { ZxProjectListCardComponent } from './zx-project-list-card/zx-project-list-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { ZxProjectDetailsOverviewComponent } from './zx-project-details-overview/zx-project-details-overview.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { ZxProjectDetailsComponent } from './zx-project-details/zx-project-details.component';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { MatGridListModule } from '@angular/material/grid-list';
import { FlexModule } from '@angular/flex-layout';
import { ZxProjectDetailsIncidentsComponent } from './zx-project-details-incidents/zx-project-details-incidents.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ZxProjectFormComponent } from './zx-project-form/zx-project-form.component';
import { ZxProjectCreateComponent } from './zx-project-create/zx-project-create.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ZxProjectEditComponent } from './zx-project-edit/zx-project-edit.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ArchiveDialogComponent } from './zx-project-form/archive-dialog/archive-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
//import { DebounceKeyUpDirective } from '../directives/DebounceKeyUpDirective';

@NgModule({
  declarations: [
    ZxProjectsOverviewComponent,
    ZxProjectListCardComponent,
    ZxProjectDetailsComponent,
    ZxProjectDetailsOverviewComponent,
    ZxProjectDetailsIncidentsComponent,
    ZxProjectFormComponent,
    ZxProjectCreateComponent,
    ZxProjectEditComponent,
    ArchiveDialogComponent,
    //DebounceKeyUpDirective,
  ],
  imports: [
    MatStepperModule,
    CommonModule,
    SharedModule,
    TranslateModule,
    MatButtonModule,
    RouterModule,
    MatGridListModule,
    FlexModule,
    FlexLayoutModule,
    MatTabsModule,
    MatCardModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSidenavModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatSlideToggleModule,
    AngularSvgIconModule.forRoot(),
    MatCheckboxModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
  ],
  exports: [ZxProjectsOverviewComponent, ZxProjectFormComponent],
})
export class ZxProjectsModule {}
