<h2 mat-dialog-title>
  <ng-container *ngIf="data.numComponents === 1"> Set project for one component</ng-container>
  <ng-container *ngIf="data.numComponents > 1"> Set project for {{ data.numComponents }} components</ng-container>
</h2>

<div class="form-container">
  <form [formGroup]="formGroup">
    <div class="form-field-container">
      <mat-form-field appearance="outline">
        <mat-select formControlName="projects" placeholder="Select a project">
          <mat-option *ngFor="let project of data.projects" value="{{ project.projectId }}">{{
            project.projectName
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</div>

<mat-dialog-actions>
  <button mat-flat-button [disabled]="addClicked" [mat-dialog-close]="'cancel'">Cancel</button>
  <button
    mat-flat-button
    [disabled]="addClicked || noProjectSelected()"
    [class.spinner]="addClicked"
    (click)="onClick()"
    mat-primary
    color="primary"
  >
    Update
  </button>
</mat-dialog-actions>
