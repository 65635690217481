import { Component, OnInit, ViewEncapsulation, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, combineLatest, merge, Observable, Subject } from 'rxjs';
import { SolutionSpace } from 'src/app/models/solution';
import { Datalog } from 'src/app/models/datalog';
import { getDatalogMaptrackState, getPositionType, humidityValues, shockValues } from 'src/app/models/transforms';
import { MatDialog } from '@angular/material/dialog';
import { ExportDialogComponent } from 'src/app/shared/export-dialog/export-dialog.component';
import { DateTime, DateTimeJSOptions } from 'luxon';
import { PolytechUiDatePickerComponent } from '../../shared/polytech-ui/date-picker/date-picker.component';
import { PageEvent } from '@angular/material/paginator';
import { TableCell, TableColumn, TableRow } from 'src/app/shared/table/table.component';
import { isDefined } from 'src/app/shared/utils';
import { DateInterval } from 'src/app/models/date.model';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TableComponent } from '../table/table.component';
import { NgIf } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { PolytechUiDatePickerComponent as PolytechUiDatePickerComponent_1 } from '../polytech-ui/date-picker/date-picker.component';

@Component({
  selector: 'app-component-datalog',
  templateUrl: './component-datalog.component.html',
  styleUrls: ['./component-datalog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    PolytechUiDatePickerComponent_1,
    MatButtonModule,
    MatCardModule,
    NgIf,
    TableComponent,
    MatProgressSpinnerModule,
    TranslateModule,
  ],
})
export class ComponentDatalogComponent implements OnInit {
  @Input() solutionSpace: SolutionSpace;
  @Input() componentId: string;
  @Input() datalogs$: Observable<Datalog[]>;
  @Output() dateRangeUpdate: EventEmitter<DateInterval> = new EventEmitter<DateInterval>();

  dataSource: TableRow[];
  isLoaded = false;
  pageSize = 50;
  pageIndex = 1;
  totalItems = 0;
  displayedColumns: TableColumn[];
  displayedDateRange = 'Last 14 days';
  shortcutFilter = { label: 'Last 3 months', value: '90' };
  pageChanged$ = new BehaviorSubject(false);

  constructor(private dialog: MatDialog) {}

  @ViewChild('datePickerRef') datePickerRef: PolytechUiDatePickerComponent;

  ngOnInit(): void {
    this.displayedColumns = this.getDisplayedColumns();
    combineLatest([this.pageChanged$, this.datalogs$]).subscribe({
      next: ([pageChanged, datalogs]) => {
        this.totalItems = datalogs.length;
        const startingPagination = (this.pageIndex - 1) * this.pageSize;
        const endpagination = this.pageIndex * this.pageSize;
        const pagedDatalogs = datalogs.slice(startingPagination, endpagination);
        this.dataSource = this.mapDatalogToTableRow(pagedDatalogs);
        this.isLoaded = true;
      },
    });
  }

  onPaginationChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageChanged$.next(true);
  }

  onDateRangeShortcutClicked(item: { label: string; value: string }) {
    const today = new Date();
    let previous = new Date();
    this.displayedDateRange = item.label;
    switch (item.value) {
      case 'ALL': {
        this.shortcutFilter = { label: 'All Time', value: 'ALL' };
        previous = new Date(1962, 6, 7);
        break;
      }
      case '14': {
        this.shortcutFilter = { label: 'Last 3 months', value: '90' };
        previous = new Date(Date.now() - 12096e5);
        break;
      }
      case '30': {
        this.shortcutFilter = { label: 'Last 3 months', value: '90' };
        previous.setMonth(today.getMonth() - 1);
        break;
      }
      case '90': {
        this.shortcutFilter = { label: 'Last 6 months', value: '180' };
        previous.setMonth(today.getMonth() - 4);
        break;
      }
      case '180': {
        this.shortcutFilter = { label: 'All time', value: 'ALL' };
        previous.setMonth(today.getMonth() - 6);
        break;
      }
    }
    this.dateRangeUpdate.emit({
      start: previous,
      end: today,
    });
    this.isLoaded = false;
  }

  convertDateTime(timeEpoch: number, addDays = 0): Date {
    const zone: DateTimeJSOptions = { zone: 'Africa/Abidjan' };
    const adjustTime = DateTime.fromMillis(timeEpoch, zone).plus({ days: addDays }).toMillis();
    const overrideZone: DateTime = DateTime.fromMillis(adjustTime);
    const newLocal = overrideZone.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    return newLocal.toJSDate();
  }

  updateDateRange(evt: { start: number; end: number }): void {
    const start: Date = this.convertDateTime(evt.start);
    const end: Date = this.convertDateTime(evt.end, 1);
    this.dateRangeUpdate.emit({
      start: start,
      end: end,
    });
    this.isLoaded = false;
  }

  selectAllData(): void {
    const startDate: DateTime = DateTime.fromISO('2020-01-01', { setZone: true, zone: 'Africa/Abidjan' });

    this.datePickerRef.setStartDate(startDate.toJSDate());
    this.datePickerRef.resetCalendars();
  }

  openDialog(): void {
    this.dialog.open(ExportDialogComponent, {
      autoFocus: false,
      data: {
        solutionSpace: this.solutionSpace,
        componentsInputs: {
          ids: [this.componentId],
          timeStart: undefined,
          timeEnd: new Date(),
        },
      },
    });
  }

  private mapDatalogToTableRow(datalogs: Datalog[]): TableRow[] {
    return datalogs.map((datalog) => {
      const tableRow = {
        time: {
          value: datalog.time,
        },
        positionType: {
          value: '',
          iconLeading: getPositionType(datalog.position).iconPath,
          tooltipText: getPositionType(datalog.position).tooltipText,
        },
        countryName: {
          value: datalog.countryName,
        },
        regionName: {
          value: datalog.regionName,
        },
        placeName: {
          value: datalog.placeName,
        },
        postcodeName: {
          value: datalog.postcodeName,
        },
        temperature: {
          value:
            datalog.temperature !== null && typeof datalog.temperature === 'number'
              ? `${Math.round(datalog.temperature)} °C`
              : '',
        },
        humidity: humidityValues(datalog.humidity, datalog.humidityWarningValue),

        accelerationX: {
          value: datalog.accelerationX,
        },
        accelerationY: {
          value: datalog.accelerationY,
        },
        accelerationZ: {
          value: datalog.accelerationZ,
        },
        pitch: {
          value: datalog.pitch,
        },
        roll: {
          value: datalog.roll,
        },
        radarMeasurement: this.radarMeasurementValues(datalog),
        utilization: getDatalogMaptrackState(datalog.state),
        shock: shockValues(datalog.shock, datalog.shockThreshold),
      };

      return tableRow;
    });
  }

  private radarMeasurementValues(datalog: Datalog): TableCell {
    if (!isDefined(datalog.radarMeasurementInMillimeter)) {
      return { value: '' };
    }

    if (datalog.radarMeasurementInMillimeter === Infinity) {
      return {
        iconLeading: '../../../assets/icons/infinity.svg',
        value2: ` / ${datalog.availabilityRangeLowInMillimeter} - ${datalog.availabilityRangeHighInMillimeter} mm`,
      };
    }

    return {
      value: `${Math.round(datalog.radarMeasurementInMillimeter as number)} mm`,
      value2: ` / ${datalog.availabilityRangeLowInMillimeter} - ${datalog.availabilityRangeHighInMillimeter} mm`,
    };
  }

  private getDisplayedColumns(): TableColumn[] {
    if (this.solutionSpace === SolutionSpace.Humidity) {
      return [
        { name: 'Measurements time (UTC) ', key: 'time' },
        { name: '', key: 'positionType', indicatorIcon: true },
        { name: 'Country', key: 'countryName' },
        { name: 'Region', key: 'regionName' },
        { name: 'Place', key: 'placeName' },
        { name: 'Postcode', key: 'PostcodeName' },
        { name: 'Temperature °C', key: 'temperature' },
        { name: 'Humidity %', key: 'humidity' },
        { name: 'X-Axis', key: 'accelerationX' },
        { name: 'Y-Axis', key: 'accelerationY' },
        { name: 'Z-Axis', key: 'accelerationZ' },
        { name: 'NSE', key: 'shock' },
        { name: 'Pitch °', key: 'pitch' },
        { name: 'Roll °', key: 'roll' },
      ];
    } else {
      return [
        { name: 'Measurements time (UTC) ', key: 'time' },
        { name: '', key: 'positionType', indicatorIcon: true },
        { name: 'Country', key: 'countryName' },
        { name: 'Region', key: 'regionName' },
        { name: 'Place', key: 'placeName' },
        { name: 'Postcode', key: 'PostcodeName' },
        { name: 'Radar mm', key: 'radarMeasurement' },
        { name: 'Utilization', key: 'utilization' },
        { name: 'X-Axis', key: 'accelerationX' },
        { name: 'Y-Axis', key: 'accelerationY' },
        { name: 'Z-Axis', key: 'accelerationZ' },
        { name: 'NSE', key: 'shock' },
        { name: 'Pitch °', key: 'pitch' },
        { name: 'Roll °', key: 'roll' },
      ];
    }
  }
}
