<app-button
  class="filter-search-area-button-radius center-horizontal"
  (click)="onSearchInArea()"
  iconUrl="/assets/images/icon-24-search.svg"
  buttonText="Search in this area"
  theme="primary"
></app-button>
<app-map-view
  #mapView
  customCluster="Rectangle"
  [clusterPoints]="clusterPoints | async"
  [hasRouting]="false"
  [markerData]="markerData | async"
  [solution]="solution"
></app-map-view>
