import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoCardComponent } from './info-card/info-card.component';
import { MatCardModule } from '@angular/material/card';
import { InfoPageComponent } from './info-page/info-page.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MapViewComponent } from './map-view/map-view.component';
import { MapOverlayCardComponent } from './map-overlay-card/map-overlay-card.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TableComponent } from './table/table.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { FlexModule } from '@angular/flex-layout';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ButtonComponent } from './button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { RecentHistoryComponent } from './recent-history/recent-history.component';
import { PreferencesComponent } from './preferences/preferences.component';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ExportDialogComponent } from './export-dialog/export-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SearchFieldComponent } from './search-field/search-field.component';
import { SelectFieldComponent } from './select-field/select-field.component';
import { MeteorologyChartComponent } from './meteorology-chart/meteorology-chart.component';
import { accelerationChartComponent } from './acceleration-chart/acceleration-chart.component';
import { UtilizationStatisticsComponent } from './utilization-statistics/utilization-statistics.component';
import { FormattingPipe } from './formatting-pipe';
import { InputFieldComponent } from './input-field/input-field.component';
import { TypeFieldComponent } from './type-field/type-field.component';
import { MultiSelectFieldComponent } from './multi-select-field/multi-select-field.component';
import { MultiCheckboxComponent } from './multi-checkbox/multi-checkbox.component';
import { SnackBarTemplateComponent } from './snack-bar-template/snack-bar-template.component';
import { ChartsModule } from 'ng2-charts';
import { MatSortModule } from '@angular/material/sort';
import { MatDividerModule } from '@angular/material/divider';
import { PolytechUiModule } from './polytech-ui/polytech-ui.module';
import { ComponentsMenuComponent } from './components-menu/components-menu.component';
import { NumberRangeInputComponent } from './components-menu/number-range-input/number-range-input.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MapComponent } from './map/map.component';
import { FormatCellPipe } from './pipes/format-cell-pipe';
import { FormatDatePipe } from './pipes/format-date-pipe';
import { FilterComponent } from './components-menu/filter/filter.component';
import { FilterOptionComponent } from './components-menu/filter-option/filter-option.component';
import { FilterUtilizationComponent } from './components-menu/filter/filter-utilization/filter-utilization.component';
import { FilterTypeComponent } from './components-menu/filter/filter-type/filter-type.component';
import { FilterLocationComponent } from './components-menu/filter/filter-location/filter-location.component';
import { FilterDaysInStateComponent } from './components-menu/filter/filter-days-in-state/filter-days-in-state.component';
import { FilterProjectComponent } from './components-menu/filter/filter-project/filter-project.component';
import { FilterHumidityComponent } from './components-menu/filter/filter-humidity/filter-humidity.component';
import { FilterTemperatureComponent } from './components-menu/filter/filter-temperature/filter-temperature.component';
import { FilterLastCommunicatedComponent } from './components-menu/filter/filter-last-communicated/filter-last-communicated.component';
import { FilterMeasurementComponent } from './components-menu/filter/filter-measurement/filter-measurement.component';
import { ComponentDatalogComponent } from './component-datalog/component-datalog.component';
import { ComponentDetailComponent } from './component-detail/component-detail.component';
import { ComponentIncidentsComponent } from './component-incidents/component-incidents.component';
import { IncidentAcknowledgeMultipleComponent } from './component-incidents/incident-acknowledge-multiple/incident-acknowledge-multiple.component';
import { IncidentDialogComponent } from './component-incidents/incident-dialog/incident-dialog.component';
import { ReopenDialogComponent } from './component-incidents/reopen-dialog/reopen-dialog.component';
import { ComponentDetailCardComponent } from './component-detail-card/component-detail-card.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ComponentInsightsComponent } from './component-insights/component-insights';
import { ComponentsOverviewComponent } from './components-overview/components-overview.component';

@NgModule({
  declarations: [
    InfoCardComponent,
    InfoPageComponent,
    TableComponent,
    ButtonComponent,
    MapComponent,
    MapOverlayCardComponent,
    MapViewComponent,
    RecentHistoryComponent,
    PreferencesComponent,
    ExportDialogComponent,
    SearchFieldComponent,
    SelectFieldComponent,
    MeteorologyChartComponent,
    accelerationChartComponent,
    UtilizationStatisticsComponent,
    ComponentsMenuComponent,
    NumberRangeInputComponent,
    FormattingPipe,
    InputFieldComponent,
    TypeFieldComponent,
    MultiSelectFieldComponent,
    MultiCheckboxComponent,
    SnackBarTemplateComponent,
    FormatCellPipe,
    FormatDatePipe,
    FilterComponent,
    FilterOptionComponent,
    FilterUtilizationComponent,
    FilterTypeComponent,
    FilterLocationComponent,
    FilterDaysInStateComponent,
    FilterProjectComponent,
    FilterHumidityComponent,
    FilterTemperatureComponent,
    FilterLastCommunicatedComponent,
    FilterMeasurementComponent,
    ComponentDatalogComponent,
    ComponentDetailComponent,
    ComponentInsightsComponent,
    ComponentIncidentsComponent,
    IncidentAcknowledgeMultipleComponent,
    IncidentDialogComponent,
    ReopenDialogComponent,
    ComponentDetailCardComponent,
    ComponentsOverviewComponent,
  ],
  imports: [
    AngularSvgIconModule.forRoot(),
    CommonModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatCardModule,
    MatToolbarModule,
    MatIconModule,
    MatProgressBarModule,
    MatButtonModule,
    MatSortModule,
    FlexLayoutModule,
    FlexModule,
    RouterModule,
    MatTableModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatTabsModule,
    TranslateModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatMenuModule,
    MatDividerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatRadioModule,
    ChartsModule,
    PolytechUiModule,
    MatDialogModule,
    MatSidenavModule,
  ],
  exports: [
    InfoCardComponent,
    InfoPageComponent,
    TableComponent,
    ButtonComponent,
    MapComponent,
    MapOverlayCardComponent,
    MapViewComponent,
    RecentHistoryComponent,
    ComponentsMenuComponent,
    NumberRangeInputComponent,
    PreferencesComponent,
    SearchFieldComponent,
    SelectFieldComponent,
    TypeFieldComponent,
    MeteorologyChartComponent,
    accelerationChartComponent,
    UtilizationStatisticsComponent,
    InputFieldComponent,
    MultiSelectFieldComponent,
    MultiCheckboxComponent,
    SnackBarTemplateComponent,
    MatRadioModule,
    MatDividerModule,
    PolytechUiModule,
    FilterComponent,
    ComponentDatalogComponent,
    ComponentDetailComponent,
    ComponentIncidentsComponent,
    ComponentInsightsComponent,
    ComponentsOverviewComponent,
  ],
  providers: [FormattingPipe],
})
export class SharedModule {}
