/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, OnDestroy, OnInit, ViewChild, Renderer2, Input, Output, EventEmitter } from '@angular/core';

import { finalize, map, Observable, Subject, Subscription, take } from 'rxjs';
import { SolutionSpace } from '../../models/solution';
import { positionType } from 'src/app/services/api/components.service';
import { ComponentState, ZXComponent } from 'src/app/models/component';
import { ComponentActivity } from 'src/app/shared/recent-history/recent-history.component';
import { HttpErrorResponse } from '@angular/common/http';
import { BackendComponentActivity } from 'src/app/models/backend/backend-component-activity';
import { MetData } from 'src/app/shared/meteorology-chart/meteorology-chart.component';
import { Measurement, MeasurementsType } from 'src/app/models/measurement';
import { ResponseHandlerService } from 'src/app/services/responseHandler.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { HistoricalUtilStatistic } from 'src/app/models/historicalUtilStatistic';
import {
  getDatalogMaptrackState,
  getStatusFromDatalog,
  datalogToTableRow,
  componentActivityMapper,
} from 'src/app/models/transforms';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { IncidentType } from 'src/app/services/api/incidents.service';
import { DateTime } from 'luxon';
import { PolytechUiDatePickerComponent } from '../../shared/polytech-ui/date-picker/date-picker.component';
import { convertDateTime } from 'src/app/shared/utils';

import { IMapData, IPin } from '../../shared/map-view/map-view.component';

import { UtilizationMapIcons } from '../../models/map/utilizationType';

import { ISimplePopupData } from '../../shared/map-view/factories/popups/Interfaces';
import { MapUtil } from 'src/app/shared/map-util';
import { TableColumn, TableRow } from 'src/app/shared/table/table.component';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ResponseObj } from 'src/app/models/errorHandling';
import { FilterOption, FilterType } from 'src/app/shared/components-menu/filter/filter.component';
import { FilterTypes, humFilter, utilFilter } from 'src/app/models/filter';
import { Datalog } from 'src/app/models/datalog';
import { DateInterval } from 'src/app/models/date.model';
import { FilterUpdate } from 'src/app/zx-components/zx-components-list/zx-components.component';
import { PopupType } from '../map-view/factories/popups/FactoryPopup';

@Component({
  selector: 'app-component-detail',
  templateUrl: './component-detail.component.html',
  styleUrls: ['./component-detail.component.scss'],
})
export class ComponentDetailComponent implements OnInit, OnDestroy {
  @Input() component: ZXComponent;
  @Input() trackingPoints$: Observable<Datalog[]>;
  @Input() componentActivities$: Observable<BackendComponentActivity[]>;
  @Input() utilizationStatistics$: Observable<HistoricalUtilStatistic>;
  @Input() measurements$: Observable<Measurement[]>;
  @Input() solutionSpace: SolutionSpace;

  @Output() updateDateRangeTrackingPoints: EventEmitter<DateInterval> = new EventEmitter<DateInterval>();
  @Output() updateDateRangeMeasurements: EventEmitter<DateInterval> = new EventEmitter<DateInterval>();

  @ViewChild('maptrackPicker', { read: undefined, static: false }) maptrackPicker: MatDatepicker<Date>;
  @ViewChild('drawer') drawer: MatSidenav;
  @ViewChild('datePickerHumRef') datePickerRef: PolytechUiDatePickerComponent;

  allTrackingPoints: Datalog[] = [];
  currentFilter: FilterUpdate = {} as FilterUpdate;
  dataSource: TableRow[] = [];
  displayedColumns: TableColumn[] = [
    { key: 'time', name: 'Measurements Time' },
    { name: 'Incidents', key: 'incident' },
  ];
  filtersOptions: Array<FilterOption> = [];
  isActivitiesLoaded = false;
  isMaptrackLoaded = false;
  isTableOpen = true;
  markerData: Array<IMapData>;
  routeData: Array<GeoJSON.Position>;
  shortcutFilter = { text: 'SHARED.LAST_COMMUNICATED.3_MONTHS', value: '90' };
  activities$: Observable<ComponentActivity[]>;
  metData$: Observable<MetData[]>;

  readonly DAYS_14_IN_MS = 12096e5;
  private cacheDateRange: DateInterval | undefined;
  private clusteredTrackingPoints: Datalog[] = [];
  private componentMapTrackingData: Subject<Datalog[]> = new Subject<Datalog[]>();
  private mapBoxSelectedTracker: Array<IPin> = new Array<IPin>();
  private mapDistanceThresholdMeters = 250;
  private paramSubscription: Subscription;
  private selectedTrackingPoint: Datalog | undefined;

  constructor(private reponseHandler: ResponseHandlerService, private snackBar: SnackbarService) {}

  ngOnInit(): void {
    this.filtersOptions = [
      {
        filterType: FilterType.MEASUREMENT,
        label: 'Component Measurement',
        icon: '/assets/icons/componentTypes.svg',
        shouldShowInList: true,
        solution: this.solutionSpace,
      },
      {
        filterType: FilterType.LASTCOMMUNICATED,
        label: 'Date Range',
        icon: '/assets/icons/calendar.svg',
        shouldShowInList: true,
      },
    ];

    if (this.solutionSpace === 'humidity') {
      this.displayedColumns.push({ name: 'Humidity', key: 'humidity' });
    } else {
      this.displayedColumns.push({ name: 'Utilization', key: 'utilization' });
    }

    this.activities$ = this.componentActivities$.pipe(
      map((activities) => componentActivityMapper(activities)),
      take(1),
      finalize(() => (this.isActivitiesLoaded = true))
    );

    this.metData$ = this.measurements$.pipe(map((measurements) => this.mapMeasurementsToMetData(measurements)));

    this.getMapTrackPoint();
  }

  getMapTrackPoint(): void {
    this.isMaptrackLoaded = false;
    this.trackingPoints$
      .pipe(
        map((trackingPoints) => {
          trackingPoints.forEach((tp) => {
            if (tp.humidity) {
              tp.humidity = Math.round(tp.humidity);
            }
          });
          return trackingPoints.sort((tpa, tpb) => {
            const ta = tpa.time.getTime();
            const tb = tpb.time.getTime();
            return ta - tb;
          });
        })
      )
      .subscribe({
        next: (trackingPoints) => {
          this.dataSource = [];
          this.clusteredTrackingPoints = this.setupGlobalTrackingClusers(trackingPoints);
          this.allTrackingPoints = trackingPoints.reverse();
          this.mapBoxSelectedTracker = new Array<IPin>();
          this.selectedTrackingPoint = undefined;
          this.setMarkerData();
          this.setRouteData();

          this.updateStatusFilter(this.stringToEnum(this.currentFilter.measurement));

          this.isMaptrackLoaded = true;

          this.componentMapTrackingData.next(
            this.allTrackingPoints.filter((x) => x.position?.type == positionType.GNSS)
          );
        },
        error: (error: HttpErrorResponse) => {
          this.reponseHandler.error(error);
        },
      });
  }

  private stringToEnum(value: string | undefined): utilFilter | humFilter {
    if (value === undefined) {
      return utilFilter.all;
    }

    const utilMeasurement = utilFilter[value as keyof typeof utilFilter];
    if (utilFilter !== undefined) {
      return utilMeasurement;
    }

    const humMeasurement = humFilter[value as keyof typeof humFilter];
    return humMeasurement;
  }

  toggle(drawer: MatDrawer): void {
    this.isTableOpen = !this.isTableOpen;
    void drawer.toggle();
  }

  addTrackPointToMap(selectedtracker: TableRow): void {
    const selectedTrackingPoint = this.allTrackingPoints.find(
      (trackingPoint) => trackingPoint.time === selectedtracker?.time.value
    );

    if (!selectedTrackingPoint) {
      const obj: ResponseObj = {
        title: 'SHARED.ERROR',
        message: 'ERROR.SELECTED_ELEMENT_NOT_EXIST',
      };
      this.snackBar.openSnackBar(obj, 'ERROR');
      return;
    }

    if (selectedTrackingPoint.position && selectedTrackingPoint.time !== this.selectedTrackingPoint?.time) {
      this.mapBoxSelectedTracker = [
        {
          accuracy: selectedTrackingPoint.position.accuracy,
          lat: selectedTrackingPoint.position.lat,
          lng: selectedTrackingPoint.position.lng,
          status: UtilizationMapIcons[selectedTrackingPoint.state],
        },
      ];
      this.selectedTrackingPoint = selectedTrackingPoint;
    } else {
      this.mapBoxSelectedTracker = new Array<IPin>();
      this.selectedTrackingPoint = undefined;
    }

    this.setMarkerData();
    this.setRouteData();
  }

  updateStatusFilter(measurement: utilFilter | humFilter): void {
    if (measurement === 'ALL') {
      this.dataSource = datalogToTableRow(this.allTrackingPoints, this.solutionSpace);
    } else {
      if (this.solutionSpace === 'utilization') {
        // inuse/ available Acceleration  filter
        const filteredTrackingPoints = this.allTrackingPoints.filter((track) => {
          if (track.state === ComponentState.Available && measurement === utilFilter.available) return track;
          if (track.state === ComponentState.InUse && measurement === utilFilter.inUse) return track;
          if (track.state === ComponentState.InReturn && measurement === utilFilter.inReturn) return track;
          if (track.state === ComponentState.InService && measurement === utilFilter.inService) return track;
          if (
            measurement === utilFilter.shock &&
            getStatusFromDatalog(track, this.solutionSpace) === IncidentType.NormalizedShockEnergy
          )
            return track;
          return false;
        });
        this.dataSource = datalogToTableRow(filteredTrackingPoints, this.solutionSpace);
      } else {
        // shock or humidity
        const filteredTrackingPoints = this.allTrackingPoints.filter((track) => {
          if (
            measurement === humFilter.humid &&
            getStatusFromDatalog(track, this.solutionSpace) !== IncidentType.HUMIDITY
          )
            return false;
          if (
            measurement === humFilter.shock &&
            getStatusFromDatalog(track, this.solutionSpace) !== IncidentType.NormalizedShockEnergy
          )
            return false;
          return true;
        });
        this.dataSource = datalogToTableRow(filteredTrackingPoints, this.solutionSpace);
      }
    }
  }

  mapMeasurementsToMetData(result: Measurement[]): MetData[] {
    const currentMetaData = [];
    const grouppedMeasuements: {
      [key: string]: Array<{ measurementType: MeasurementsType; value: number }>;
    } = {};
    result.forEach((measurement: Measurement) => {
      const index = new Date(measurement.timestamp).toISOString();
      grouppedMeasuements[index] = grouppedMeasuements[index] || [];
      grouppedMeasuements[index].push({
        measurementType: measurement.measurementType,
        value: measurement.value,
      });
    });
    for (const group in grouppedMeasuements) {
      const measurment: MetData = {
        date: new Date(group),
        humidity: 0,
        temperature: 0,
        dewPoint: 0,
        threshold: 0,
      };
      if (grouppedMeasuements[group][0].measurementType === MeasurementsType.Humidity) {
        measurment.humidity = Math.round(grouppedMeasuements[group][0].value);
        measurment.temperature = Math.round(grouppedMeasuements[group][1].value);
      } else {
        measurment.temperature = Math.round(grouppedMeasuements[group][0].value);
        measurment.humidity = Math.round(grouppedMeasuements[group][1].value);
      }
      measurment.dewPoint = Math.round(measurment.temperature - (100 - measurment.humidity) / 5);
      measurment.threshold = this.component ? this.component.type.humidityThreshold : 0;

      currentMetaData.push(measurment);
    }
    return currentMetaData;
  }

  setFilterDateRange(range: string): void {
    switch (range) {
      case '180': {
        this.updateMapTrack({ label: 'Last 6 months', value: '180' });
        break;
      }
      case '14':
      case '90': {
        this.updateMapTrack({ label: 'Last quarter', value: '90' });
        break;
      }
      case 'ALL': {
        this.updateMapTrack({ label: 'All Time', value: 'ALL' });
        break;
      }
    }
  }

  updateMapTrack(item: { label: string; value: string }) {
    const today = new Date();
    let previous = new Date();
    switch (item.value) {
      case 'ALL': {
        this.shortcutFilter = { text: 'SHARED.LAST_COMMUNICATED.ALL_TIME', value: 'ALL' };
        previous = new Date(1962, 6, 7);
        break;
      }
      case '14': {
        this.shortcutFilter = { text: 'SHARED.LAST_COMMUNICATED.3_MONTHS', value: '90' };
        previous = new Date(Date.now() - this.DAYS_14_IN_MS);
        break;
      }
      case '30': {
        this.shortcutFilter = { text: 'SHARED.LAST_COMMUNICATED.3_MONTHS', value: '90' };
        previous.setMonth(today.getMonth() - 1);
        break;
      }
      case '90': {
        this.shortcutFilter = { text: 'SHARED.LAST_COMMUNICATED.6_MONTHS', value: '180' };
        previous.setMonth(today.getMonth() - 4);
        break;
      }
      case '180': {
        this.shortcutFilter = { text: 'SHARED.LAST_COMMUNICATED.ALL_TIME', value: 'ALL' };
        previous.setMonth(today.getMonth() - 6);
        break;
      }
    }
    this.updateDateRangeTrackingPoints.emit({ start: previous, end: today });
    this.isMaptrackLoaded = false;
  }

  selectAllData(): void {
    const startDate: DateTime = DateTime.fromISO('2020-01-01', { setZone: true, zone: 'Africa/Abidjan' });

    this.datePickerRef.setStartDate(startDate.toJSDate());
    this.datePickerRef.resetCalendars();
  }

  updateDateRange(evt: { start: number; end: number } | undefined, type: 'MAPTRACK' | 'MEASUREMENTS'): void {
    if (evt === undefined) {
      return;
    }
    if (type === 'MAPTRACK') {
      this.updateDateRangeTrackingPoints.emit({ start: convertDateTime(evt.start), end: convertDateTime(evt.end, 1) });
      this.isMaptrackLoaded = false;
    } else if (type === 'MEASUREMENTS') {
      this.updateDateRangeMeasurements.emit({ start: convertDateTime(evt.start), end: convertDateTime(evt.end, 1) });
    }
  }

  ngOnDestroy(): void {
    this.paramSubscription && this.paramSubscription.unsubscribe();
  }

  setRouteData(): void {
    const positionData = this.clusteredTrackingPoints
      .filter((point) => point.position && point.position.type != positionType.BASE_STATION)
      .map((point) => ({
        lat: point.position.lat,
        lng: point.position.lng,
      }));

    this.routeData = this.findClusters(positionData).map((position) => [position.lng, position.lat]);
  }

  updateData(filterUpdate: FilterUpdate) {
    const mappedFilterUpdate = {
      selectedStatus: { value: this.mapFilterTypesToUtilOrHumFilter(filterUpdate.measurement), label: '' },
    };

    if (this.currentFilter?.measurement !== filterUpdate?.measurement) {
      this.currentFilter.measurement = filterUpdate.measurement;
      this.applyFilters(mappedFilterUpdate);
      return;
    }

    let dateRange: DateInterval | undefined;

    if (filterUpdate.lastCommunicationTimeFrom !== undefined && filterUpdate.lastCommunicationTimeTo !== undefined) {
      dateRange = {
        start: filterUpdate.lastCommunicationTimeFrom,
        end: filterUpdate.lastCommunicationTimeTo,
      };
    }
    if (this.cacheDateRange !== dateRange) {
      if (dateRange === undefined) {
        dateRange = {
          start: new Date(Date.now() - this.DAYS_14_IN_MS),
          end: new Date(),
        };

        this.currentFilter.lastCommunicationTimeFrom = undefined;
        this.currentFilter.lastCommunicationTimeTo = undefined;
      } else {
        this.currentFilter.lastCommunicationTimeFrom = new Date(dateRange.start);
        this.currentFilter.lastCommunicationTimeTo = new Date(dateRange.end);
      }

      this.updateDateRange({ start: dateRange.start.getTime(), end: dateRange.end.getTime() }, 'MAPTRACK');
      this.cacheDateRange = dateRange;

      this.shortcutFilter = { text: 'SHARED.LAST_COMMUNICATED.3_MONTHS', value: '90' };

      this.componentMapTrackingData.pipe(take(1)).subscribe(() => {
        this.applyFilters(mappedFilterUpdate);
      });
    }
    this.applyFilters(mappedFilterUpdate);
  }

  private mapFilterTypesToUtilOrHumFilter(filterType: string | undefined): utilFilter | humFilter {
    switch (filterType) {
      case FilterTypes.AVAILABLE:
        return utilFilter.available;
      case FilterTypes.INUSE:
        return utilFilter.inUse;
      case FilterTypes.HUMIDITY:
        return humFilter.humid;
      case FilterTypes.IN_SERVICE:
        return utilFilter.inService;
      case FilterTypes.IN_RETURN:
        return utilFilter.inReturn;
      case FilterTypes.SHOCK:
        return this.solutionSpace === SolutionSpace.Utilization ? utilFilter.shock : humFilter.shock;
      default:
        return this.solutionSpace === SolutionSpace.Utilization ? utilFilter.all : humFilter.all;
    }
  }

  setMarkerData(): void {
    const points = this.clusteredTrackingPoints
      .filter((x) => x.position && x.position.type != positionType.BASE_STATION)
      .map((x) => x.position);
    const clusteredPoints = this.findClusters(points);
    const finalClusteredPoint = clusteredPoints
      .splice(-1)
      .map((x) => ({ ...x, status: 'map-pin-last-clustered-tracking' }));

    this.markerData = [
      {
        image: 'assets/images/map/map-pin-clustered-tracking.png',
        size: 0.8,
        position: clusteredPoints,
      },
      {
        image: 'assets/images/map/map-pin-last-clustered-tracking.png',
        size: 1,
        iconOffset: { x: 0, y: 0 },
        position: finalClusteredPoint,
      },
      {
        image: 'assets/images/map/map-pin-component-available.png',
        size: 1,
        iconOffset: { x: 0, y: 0 },
        position: this.mapBoxSelectedTracker,
        //animate: true,
        popupFunction: (renderer: Renderer2) =>
          MapUtil.getPopup(renderer, PopupType.Simple, this.solutionSpace, this.getPopupData()),
      },
    ];
  }

  getPopupData(): ISimplePopupData {
    if (!this.selectedTrackingPoint) {
      const obj: ResponseObj = {
        title: 'SHARED.ERROR',
        message: 'ERROR.SELECTED_ELEMENT_NOT_EXIST',
      };
      this.snackBar.openSnackBar(obj, 'ERROR');
      throw Error();
    }

    return {
      state: this.selectedTrackingPoint.state,
      id: this.component.id,
      position: this.selectedTrackingPoint.position,
      utilization: getDatalogMaptrackState(this.selectedTrackingPoint.state),
      humidity: {
        value: this.selectedTrackingPoint.humidity ? `${Math.round(this.selectedTrackingPoint.humidity)}%` : '',
        value2: this.selectedTrackingPoint.humidityWarningValue
          ? ` / ${Math.round(this.selectedTrackingPoint.humidityWarningValue)}%`
          : '',
      },
    };
  }

  findClusters(positionData: { lat: number; lng: number }[]): { lat: number; lng: number }[] {
    if (positionData.length == 0) {
      return [];
    }

    const clusterPositionData: { lat: number; lng: number }[] = [];
    let currentClusterPosition: { lat: number; lng: number };
    let currentClusterLat: number;
    let currentClusterLng: number;
    let prevPosition: { lat: number; lng: number };

    positionData.forEach((position) => {
      if (!currentClusterPosition) {
        currentClusterPosition = position;
        currentClusterLat = position.lat;
        currentClusterLng = position.lng;
        prevPosition = position;
        return;
      }

      const distance = this.measure(position.lat, position.lng, currentClusterLat, currentClusterLng);

      if (distance > this.mapDistanceThresholdMeters) {
        currentClusterPosition = position;
        currentClusterLat = position.lat;
        currentClusterLng = position.lng;
        clusterPositionData.push(prevPosition);
      }
      prevPosition = position;
    });

    clusterPositionData.push(positionData[positionData.length - 1]);

    return clusterPositionData;
  }

  measure(lat1: number, lon1: number, lat2: number, lon2: number): number {
    // generally used geo measurement function
    const R = 6378.137; // Radius of earth in KM
    const dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
    const dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d * 1000; // meters
  }

  setupGlobalTrackingClusers(data: Array<Datalog>): Array<Datalog> {
    let newData = data.filter((element) => {
      return element?.position !== null && element?.position !== undefined;
    });

    newData = newData.filter((element, index, array) => {
      if (index === 0) {
        return true;
      }
      const prevPoint = array[index - 1];

      const { lat, lng } = element.position;
      const { position: prevPos } = prevPoint;
      const { lat: prevLat, lng: prevLng } = prevPos;

      const distanceToLast = this.measure(lat, lng, prevLat, prevLng);
      return distanceToLast > this.mapDistanceThresholdMeters;
    });

    return newData;
  }

  applyFilters(event: { selectedStatus: { value: string; label: string } }) {
    if (event.selectedStatus !== undefined) {
      if (this.solutionSpace === 'utilization') {
        this.updateStatusFilter(<utilFilter>(event.selectedStatus.value as unknown));
      } else {
        this.updateStatusFilter(<humFilter>(event.selectedStatus.value as unknown));
      }
    }
  }
}
