import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectableOption } from '../filter.component';

@Component({
  selector: 'app-filter-type',
  templateUrl: './filter-type.component.html',
  styleUrls: ['./filter-type.component.scss'],
})
export class FilterTypeComponent {
  @Input() typeOptions: SelectableOption[] = [];

  @Output() apply = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() optionChange: EventEmitter<SelectableOption[]> = new EventEmitter<SelectableOption[]>();

  onApply() {
    this.apply.emit();
  }

  onBack() {
    this.back.emit();
  }

  onOptionChange(event: SelectableOption[]) {
    this.optionChange.emit(event);
  }
}
