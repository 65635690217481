import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent implements OnInit {
  @Output() valueChanged = new EventEmitter<string>();
  @Input() defaultValue: string | number | undefined;
  @Input() type? = 'text';
  @Input() icon: string;
  @Input() placeHolder: string;
  @Input() cssClass?: string;

  inputFormGroup: UntypedFormGroup;

  constructor() {}

  ngOnInit(): void {
    this.inputFormGroup = new UntypedFormGroup({
      input: new UntypedFormControl(this.defaultValue),
    });
  }

  sendSearchQuery(): void {
    this.valueChanged.emit(this.inputFormGroup.controls['input'].value as string);
  }

  clearSearch(): void {
    this.inputFormGroup.patchValue({ input: '' });
    this.sendSearchQuery();
  }
}
