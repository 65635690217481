<ng-container *ngIf="solutionSpace$ | async as solutionSpace">
  <app-component-datalog
    *ngIf="componentId$ | async as componentId"
    [solutionSpace]="solutionSpace"
    [componentId]="componentId"
    [datalogs$]="datalogs$"
    (dateRangeUpdate)="updateDatalog($event)"
  >
  </app-component-datalog>
</ng-container>
