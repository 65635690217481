/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { Project } from 'src/app/models/project';
import { SolutionSpace } from 'src/app/models/solution';
import { formatDate } from 'src/app/shared/utils';

@Component({
  selector: 'app-zx-project-list-card',
  templateUrl: './zx-project-list-card.component.html',
  styleUrls: ['./zx-project-list-card.component.scss'],
  providers: [TranslatePipe],
})
export class ZxProjectListCardComponent implements OnInit {
  @Input() zxProject: Project;
  @Input() solution: SolutionSpace;

  infoMaps: { label: string; value: string | Date; icon?: string }[] = [];
  routerLink: string = '';

  constructor(private translate: TranslatePipe, private router: Router) {}
  ngOnInit(): void {
    this.infoMaps = this.getInfoMap();
    this.routerLink = `${this.zxProject.id}/overview`;
  }

  getLink(): string {
    return `${this.zxProject.id}/overview`;
  }

  solutionInfo(): { label: string; value: string | Date; icon?: string } {
    if (this.solution === SolutionSpace.Utilization) {
      return {
        label: this.translate.transform('project.componentsInUse'),
        value: this.zxProject.inUseCount.toString(),
        icon: '/assets/icons/icon-16-in-use.svg',
      };
    } else {
      const numHumidityComponents: number = this.zxProject.activeHumidityIncidentCount;
      return {
        label: this.translate.transform('project.humidity'),
        value: numHumidityComponents.toString(),
        icon:
          numHumidityComponents > 0
            ? '/assets/icons/icon-16-humidity-incidents.svg'
            : '/assets/icons/icon-16-humidity-incidents-light-grey.svg',
      };
    }
  }

  availableComponentsInfo(): {
    label: string;
    value: string | Date;
    icon?: string;
  } {
    return {
      label: this.translate.transform('project.componentsAvailable'),
      value: this.zxProject.availableCount.toString(),
      icon: '/assets/images/icon-40-not-in-use.svg',
    };
  }

  numComponentsInfo(): {
    label: string;
    value: string | Date;
    icon?: string;
  } {
    return {
      label: this.translate.transform('project.components'),
      value: this.zxProject.componentCount.toString(),
      icon: '/assets/icons/icon-24-components.svg',
    };
  }

  serviceStateCountsInfo(
    projectArray: {
      label: string;
      value: string | Date;
      icon?: string;
    }[]
  ) {
    projectArray.splice(2, 0, {
      label: this.translate.transform('project.componentsInService'),
      value: this.zxProject.inServiceCount.toString(),
      icon: 'assets/icons/icon-16-service-grey.svg',
    });
    projectArray.splice(2, 0, {
      label: this.translate.transform('project.componentsInReturn'),
      value: this.zxProject.inReturnCount.toString(),
      icon: 'assets/icons/icon-16-orange-return.svg',
    });
  }

  getInfoMap(): { label: string; value: string | Date; icon?: string }[] {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const projectArray = [
      this.solution === SolutionSpace.Utilization ? this.availableComponentsInfo() : this.numComponentsInfo(),
      this.solutionInfo(),

      {
        label: this.translate.transform('project.group'),
        value: this.zxProject.groupName,
      },
      {
        label: this.translate.transform('project.created'),
        value: formatDate(this.zxProject.created),
      },
    ];

    if (this.solution === SolutionSpace.Utilization) {
      this.serviceStateCountsInfo(projectArray);
    }
    return projectArray;
  }
}
