<mat-card class="info-card" [ngClass]="solution" [class.map-info-card]="isMapInfoCard">
  <mat-card-title class="title">
    <img *ngIf="iconUrl" src="{{ iconUrl }}" class="header-image" />
    <ng-container *ngIf="!headerRouterLink; else anchor">
      <p class="card-header" [class.icon]="iconUrl">{{ this.getTitle() }}</p>
    </ng-container>
    <ng-template #anchor>
      <a [routerLink]="headerRouterLink">
        <p class="card-header" [class.icon]="iconUrl">{{ this.getTitle() }}</p>
      </a>
    </ng-template>
  </mat-card-title>
  <mat-card-subtitle class="type-desc" *ngIf="description">{{ description }}</mat-card-subtitle>

  <mat-card-content>
    <div class="content-wrapper" [ngStyle]="columnsGrid()">
      <div *ngFor="let info of infoMap">
        <div [ngClass]="info.label === 'component.device' ? 'border-top width-30' : ''"></div>
        <p *ngIf="info.label && info.label !== ''" [ngClass]="info.label === 'component.device' ? 'mt-xs' : ''">
          {{ info.label | translate }}:
        </p>
        <div class="value-container">
          <svg-icon
            *ngIf="info.icon"
            src="{{ info.icon }}"
            [svgStyle]="{ 'width.px': 18, 'height.px': 18 }"
            class="content-image"
          >
          </svg-icon>
          <span
            [ngClass]="{
              value: true,
              line_under: info.label === 'component.project'
            }"
            (click)="info.path && info.label === 'component.project' && navigate(info.path)"
          >
            <ng-container *ngIf="!info.value2; else greycell">
              <span class="break-word" *ngIf="!valueIsDate(info.value)" [innerHTML]="info.value"></span>
              <span class="break-word" *ngIf="valueIsDate(info.value)">{{ formatInfoDate(info.value) }}</span>
            </ng-container>
            <ng-template #greycell>
              <span>{{ info.value }}</span>
              <span class="concrete-grey">{{ info.value2 }}</span>
            </ng-template>
          </span>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
