<polytech-ui-date-picker
  (onClose)="onBack()"
  initialTimeRange="14"
  [canAlwaysConfirm]="true"
  [alwaysOpen]="true"
  [headLess]="true"
  timeZone="Africa/Abidjan"
  (dateChanged)="onOptionChange($event)"
  #datePickerRef
>
  <dt (click)="datePickerRef.setDefinedRange('14d')">{{ "SHARED.LAST_COMMUNICATED.14_DAYS" | translate }}</dt>
  <dt (click)="datePickerRef.setDefinedRange('3m')">{{ "SHARED.LAST_COMMUNICATED.3_MONTHS" | translate }}</dt>
  <dt (click)="datePickerRef.setDefinedRange('6m')">{{ "SHARED.LAST_COMMUNICATED.6_MONTHS" | translate }}</dt>
  <dt (click)="selectAllData()">{{ "SHARED.LAST_COMMUNICATED.ALL_TIME" | translate }}</dt>
</polytech-ui-date-picker>
