import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, debounceTime, map, switchMap } from 'rxjs';
import { MeasurementsType } from 'src/app/models/measurement';

import { SolutionSpace } from 'src/app/models/solution';
import { ComponentsService } from 'src/app/services/api/components.service';
import { DateInterval } from 'src/app/models/date.model';

@Component({
  selector: 'app-zx-component-incidents',
  templateUrl: './zx-component-insights.html',
  styleUrls: ['./zx-component-insights.scss'],
})
export class ZxComponentInsightsComponent {
  measurementHumidityDateInterval$ = new BehaviorSubject({
    start: new Date(Date.now() - 12096e5),
    end: new Date(),
  });

  measurementNSEDateInterval$ = new BehaviorSubject({
    start: new Date(Date.now() - 12096e5),
    end: new Date(),
  });

  solutionSpace$ = this.route.params.pipe(
    map((el) => (el.utilorhumid === 'humidity' ? SolutionSpace.Humidity : SolutionSpace.Utilization))
  );

  componentId$ = this.route.params.pipe(map((el) => el.id as string));

  measurementsHumidiy$ = combineLatest([this.componentId$, this.measurementHumidityDateInterval$]).pipe(
    debounceTime(500),
    switchMap(([componentId, dateInterval]) => {
      const measurementsDataPoints = [MeasurementsType.Humidity, MeasurementsType.Temperature];
      return this.componentsService.getMeasurementsData(
        componentId,
        measurementsDataPoints,
        dateInterval.start,
        dateInterval.end
      );
    })
  );

  measurementsNSE$ = combineLatest([this.componentId$, this.measurementNSEDateInterval$]).pipe(
    debounceTime(500),
    switchMap(([componentId, dateInterval]) => {
      const measurementsDataPoints = [MeasurementsType.NormalizedShockEnergy];
      return this.componentsService.getMeasurementsData(
        componentId,
        measurementsDataPoints,
        dateInterval.start,
        dateInterval.end
      );
    })
  );

  constructor(private route: ActivatedRoute, private componentsService: ComponentsService) {}

  updateDateRangeNSE(newDateRange: DateInterval) {
    this.measurementNSEDateInterval$.next({ start: newDateRange.start, end: newDateRange.end });
  }

  updateDateRangeHumidity(newDateRange: DateInterval) {
    this.measurementHumidityDateInterval$.next({ start: newDateRange.start, end: newDateRange.end });
  }
}
