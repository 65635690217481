<app-filter-option
  icon="/assets/icons/daysInState.svg"
  [title]="'FILTER.DAYS_IN_STATE.TITLE' | translate"
  (apply)="onApply()"
  (back)="onBack()"
>
  <div class="pr-m pl-m">
    <p class="desc">{{ "FILTER.DAYS_IN_STATE.DESCRIPTION" | translate }}</p>
    <div class="flex-col">
      <span class="state-label">{{ "FILTER.DAYS_IN_STATE.UTILIZATION_STATE" | translate }}</span>
      <mat-form-field appearance="outline" style="width: 150px" (click)="$event.stopPropagation()">
        <mat-select [(ngModel)]="selectedUtilizationState" (selectionChange)="onUtilizationStateChange()">
          <ng-container>
            <mat-option
              id="option-{{ utlizationState.value }}"
              *ngFor="let utlizationState of utilizationOptions"
              value="{{ utlizationState.value }}"
              >{{ utlizationState.label }}</mat-option
            >
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="pr-m pl-m pt-m">
      <app-number-range-input
        [selectedIntervalOption]="selectedState.selectedIntervalOption"
        [unit]="'SHARED.DAYS' | translate"
        (UpdateNumberRange)="onOptionChange($event)"
      ></app-number-range-input>
    </div>
  </div>
</app-filter-option>
