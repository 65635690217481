/* function localDate(date: Date): Date {
  const tzoffset = new Date().getTimezoneOffset() * 60000;
  return new Date(date.getTime() - tzoffset);
} */
import { DateTime, DateTimeJSOptions } from 'luxon';

export function convertDateTime(timeEpoch: number, addDays = 0): Date {
  const zone: DateTimeJSOptions = { zone: 'Africa/Abidjan' };
  const adjustTime = DateTime.fromMillis(timeEpoch, zone).plus({ days: addDays }).toMillis();

  const overrideZone: DateTime = DateTime.fromMillis(adjustTime).setZone('Africa/Abidjan');

  const newLocal = overrideZone.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return newLocal.toJSDate();
}

export function convertDateNumber(timeEpoch: number, addDays = 0): number {
  const zone: DateTimeJSOptions = { zone: 'Africa/Abidjan' };
  const adjustTime = DateTime.fromMillis(timeEpoch, zone).plus({ days: addDays }).toMillis();

  const overrideZone: DateTime = DateTime.fromMillis(adjustTime).setZone('Africa/Abidjan');

  const newLocal = overrideZone.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  return newLocal.toMillis();
}

export function formatZonedDateTime(timeEpoch: number, withTime = false): string {
  const zone: DateTimeJSOptions = { zone: 'Africa/Abidjan' };
  const overrideZone: DateTime = DateTime.fromMillis(timeEpoch, zone);

  const basicTime = `${overrideZone.year}-${overrideZone.month.toString().padStart(2, '0')}-${overrideZone.day
    .toString()
    .padStart(2, '0')}`;

  return withTime
    ? `${basicTime} - ${overrideZone.hour.toString().padStart(2, '0')}:${overrideZone.minute
        .toString()
        .padStart(2, '0')}`
    : basicTime;
}

export function formatDate(date: Date): string {
  let d: string;
  try {
    d = date.toISOString();
  } catch (e) {
    d = new Date(date).toISOString();
    //  console.error('backend dateTime : wrong format', e);
  }
  // eslint-disable-next-line prettier/prettier
  return `${d.substr(0, 4)}-${d.substr(5, 2)}-${d.substr(8, 2)} - ${d.substr(11, 2)}:${d.substr(14, 2)}`;
}

/**
 * Returns a new Date with the same date, but with the time set to the last second of the day.
 */
export function endOfDay(date: Date): Date {
  const copy = new Date(date);
  copy.setHours(23, 59, 59);
  return copy;
}

export function formatDashesToColon(macAddress: string): string {
  return macAddress.replace(/-/g, ':');
}

export function formatDeviceBatteryLevel(readout: number): string {
  if (readout < 0 || readout === undefined) return '';
  return `${Math.round(readout).toString()}%`;
}

export function isDefined<T>(x: T | null | undefined): x is T {
  return typeof x !== 'undefined' && x !== null;
}

export const zip = <T, U>(a: T[], b: U[]): [T, U][] => {
  if (a.length !== b.length) {
    throw new Error('Cannot zip arrays with unequal length');
  }
  return a.map((value, index) => [value, b[index]]);
};

/**
 * @param input
 * @returns true if input is an array with 1 or more elements, otherwise false
 */
export const isArrayWithElements = <T>(input: T[] | null | undefined): input is T[] => {
  return Array.isArray(input) && input.length > 0;
};

export const stripUndefined = <T extends object>(filter: T): Partial<T> => {
  return Object.fromEntries(Object.entries(filter).filter(([_key, value]) => isDefined(value))) as Partial<T>;
};
