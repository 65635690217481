import { Transform, Type } from 'class-transformer';
import { ActivityType } from 'src/app/shared/recent-history/recent-history.component';

export enum BackendComponentActivityDescription {
  Created_By_Username,
  Battery_Incidents,
  Preferences_Updated_By_Username,
  Settings_Updated_By_Username,
  Status_Changed_To,
  X_Incident_Acknowledged_By_Username,
  X_Incident_Unacknowledged_By_Username,
  Normalized_Shock_Energy_Incident,
  Default,
  Loaded,
  Unloaded,
  Archived,
  Received_In_Service,
  Released_From_Service,
  Added_To_Project,
  Removed_From_Project,
}

export class BackendComponentActivity {
  username: string;
  @Transform(
    (act) =>
      BackendComponentActivityDescription[
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        act.value.toString() as keyof typeof BackendComponentActivityDescription
      ]
  )
  description: BackendComponentActivityDescription;
  status: string;
  count: number;
  readout: number;
  @Type(() => Date)
  occurredAt: Date;
  @Type(() => Date)
  updated: Date;

  getText(): string {
    switch (this.description) {
      case BackendComponentActivityDescription.Created_By_Username:
        return `Component created by ${this.username}`;
      case BackendComponentActivityDescription.Settings_Updated_By_Username:
        return `Settings updated by ${this.username}`;
      case BackendComponentActivityDescription.Preferences_Updated_By_Username:
        return `Preferences updated by ${this.username}`;
      case BackendComponentActivityDescription.Battery_Incidents:
        return `Battery level low ${Math.round(this.readout)}%`;
      case BackendComponentActivityDescription.Normalized_Shock_Energy_Incident:
        return `NSE detected`;
      case BackendComponentActivityDescription.Status_Changed_To:
        return `Utilization changed to ${this.status}`; // this is not used and does not really make sense unless status is
      case BackendComponentActivityDescription.Loaded:
        return `The component is In use`;
      case BackendComponentActivityDescription.Unloaded:
        return `The component is ready to be returned`;
      case BackendComponentActivityDescription.Archived:
        return `Component archived by ${this.username}`;
      case BackendComponentActivityDescription.Released_From_Service:
        return `Released by ${this.username}`;
      case BackendComponentActivityDescription.Received_In_Service:
        return `Set to service by ${this.username}`;
      case BackendComponentActivityDescription.Default:
        return ``;
      case BackendComponentActivityDescription.X_Incident_Acknowledged_By_Username:
        if (this.count > 1) return `${this.count} incidents resolved by ${this.username}`;
        else return `Incident resolved by ${this.username}`;
      case BackendComponentActivityDescription.X_Incident_Unacknowledged_By_Username:
        if (this.count > 1) return `${this.count} incidents reopened by ${this.username}`;
        else return `Incident reopened by ${this.username}`;
      case BackendComponentActivityDescription.Added_To_Project:
        return `Component added to project by ${this.username}`;
      case BackendComponentActivityDescription.Removed_From_Project:
        return `Component removed from project by ${this.username}`;
    }
  }

  // maps to icon more or less
  getActivityType(): ActivityType {
    switch (this.description) {
      case BackendComponentActivityDescription.Created_By_Username:
        return ActivityType.Created;
      case BackendComponentActivityDescription.Status_Changed_To:
        return ActivityType.Available;
      case BackendComponentActivityDescription.Battery_Incidents:
        return ActivityType.Battery;
      case BackendComponentActivityDescription.Preferences_Updated_By_Username:
        return ActivityType.PreferencesUpdated;
      case BackendComponentActivityDescription.Settings_Updated_By_Username:
        return ActivityType.SettingsUpdated;
      case BackendComponentActivityDescription.Normalized_Shock_Energy_Incident:
        return ActivityType.Shock;
      case BackendComponentActivityDescription.X_Incident_Acknowledged_By_Username:
        return ActivityType.Acknowledge;
      case BackendComponentActivityDescription.X_Incident_Unacknowledged_By_Username:
        return ActivityType.Reopened;
      case BackendComponentActivityDescription.Loaded:
        return ActivityType.InUse;
      case BackendComponentActivityDescription.Unloaded:
        return ActivityType.inReturn;
      case BackendComponentActivityDescription.Archived:
        return ActivityType.archived;
      case BackendComponentActivityDescription.Received_In_Service:
        return ActivityType.inService;
      case BackendComponentActivityDescription.Released_From_Service:
        return ActivityType.Available;
      case BackendComponentActivityDescription.Default:
        return ActivityType.default;
      case BackendComponentActivityDescription.Added_To_Project:
      case BackendComponentActivityDescription.Removed_From_Project:
        return ActivityType.Incidents;
    }
  }
}
