import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-set-project-dialog',
  templateUrl: './set-project-dialog.component.html',
  styleUrls: ['./set-project-dialog.component.scss'],
})
export class SetProjectDialogComponent {
  addClicked = false;
  formGroup = new UntypedFormGroup({
    projects: new UntypedFormControl(),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      numComponents: number;
      selectedProject: string;
      addSubject: Subject<string>;
      projects: { projectId: string; projectName: string }[];
    }
  ) {}

  noProjectSelected(): boolean {
    return !this.formGroup.get('projects')?.dirty;
  }

  onClick(): void {
    if (this.addClicked) return;
    this.addClicked = true;
    this.data.selectedProject = this.formGroup.get('projects')?.value as string;
    this.data.addSubject.next('pressed');
  }
}
