import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserManagement } from 'src/app/models/user-management-list';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private http: HttpClient) {}

  getUsersUsermanagement(): Observable<UserManagement[]> {
    return this.http.get<UserManagement[]>(`http://localhost:3000/api/1/admin/users`);
  }
}
