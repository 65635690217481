<app-filter-option
  icon="/assets/icons/icon-16-set-project.svg"
  [title]="'FILTER.PROJECT.TITLE' | translate"
  (back)="onApply()"
  (apply)="onBack()"
>
  <app-search-field
    #searchcontrol
    class="search-field pl-l"
    [placeHolder]="'FILTER.PROJECT.PLACEHOLDER' | translate"
    (click)="$event.stopPropagation()"
    (keyUp)="filterProjectName($event)"
  ></app-search-field>
  <div>
    <app-multi-checkbox [optionArray]="filteredProjects" (optionChange)="onSelectProjects($event)"></app-multi-checkbox>
  </div>
</app-filter-option>
