import { IGroup, ILayer } from './Interfaces';
import { Renderer2 } from '@angular/core';

export class GroupRect implements IGroup {
  public HTML: HTMLElement;
  private _renderer: Renderer2;
  private _type = '';

  constructor(type: string) {
    this._type += `${type}`;
  }

  public build(renderer: Renderer2): IGroup {
    this._renderer = renderer;
    this.HTML = <HTMLElement>renderer.createElement('div');
    renderer.setProperty(this.HTML, 'className', this._type);

    return this;
  }

  public addChild(child: ILayer): IGroup {
    this._renderer.appendChild(this.HTML, child.HTML);
    return this;
  }
}
