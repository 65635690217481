<div class="container px-m">
  <div *ngFor="let elem of optionArray" class="multi-selection-box py-s">
    <mat-checkbox
      color="primary"
      [(ngModel)]="elem.isSelected"
      (ngModelChange)="onOptionChange()"
      (click)="$event.stopPropagation()"
    >
      {{ elem.label }}</mat-checkbox
    >
  </div>
</div>
