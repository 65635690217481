<app-info-page title="{{ 'project.projectsOverviewTitle' | translate }}"
  [breadcrumbs]="[{ link: '.', label: 'Projects' }]" [isComponentOrProject]="'PROJECT'">


  <app-button theme="primary" [id]="'btn-create-project'" class="btn-create-project" [routerLink]="['/projects/create']"
    [queryParams]="{ solution: solutionSpace, origin: getOrigin() }" ngProjectAs="buttons"
    buttonText="{{ 'project.createProject' | translate }}" iconUrl="/assets/icons/icon-16-add.svg"></app-button>

  <nav mat-tab-nav-bar *ngIf="dualSolutions()" class="solution-tabs grey-bg">
    <a mat-tab-link [routerLink]="link.path" *ngFor="let link of links" (click)="activeLink = link.id"
      [active]="activeLink === link.id">
      {{ link.label | translate }}
    </a>
  </nav>
  <div class="filter-container space-between grey-bg project-filter-padding">
    <app-search-field class="search-field" (keyUp)="updateData($event)"
      [placeHolder]="'component.OVERVIEW.FORM-FIELD.PLACE_HOLDER' | translate"></app-search-field>
    <button class="hover-button margin-right-xs" mat-button [matMenuTriggerFor]="filter">
      <svg-icon [svgStyle]="{ 'width.px': 16 }" class="icon-hover" src="/assets/icons/filter.svg"> </svg-icon>
      {{ displayedText }}
    </button>
  </div>
  <mat-menu #filter="matMenu">
    <button mat-menu-item (click)="setArchiveControl(false)">Active</button>
    <button mat-menu-item (click)="setArchiveControl(true)">Archived</button>
  </mat-menu>

  <div *ngIf="loaded; else spinner" fxLayout="row wrap" fxLayoutGap="25px grid" class="project-wrapper">
    <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%" *ngFor="let project of projects">
      <app-zx-project-list-card [zxProject]="project" [solution]="solutionSpace"></app-zx-project-list-card>
    </div>
  </div>
  <mat-paginator class="project-mat-paginator" [pageIndex]="pageNumber - 1" [length]="totalItems" [pageSize]="pageSize"
    (page)="handlePageEvent($event)" hidePageSize showFirstLastButtons>
  </mat-paginator>
  <ng-template #spinner>
    <div class="spinner-wrapper">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</app-info-page>