import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-archive-dialog',
  templateUrl: './archive-dialog.component.html',
  styleUrls: ['./archive-dialog.component.scss'],
})
export class ArchiveDialogComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ArchiveDialogComponent>) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {}

  onCancel(): void {
    this.dialogRef.close();
  }
}
