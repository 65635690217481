import { Type } from 'class-transformer';
import { NumericUtilOrHumid } from './solution';

export class Device {
  id: string;
  identifier: string;
  solutionSpace: NumericUtilOrHumid;
  firmware: string;
  type: string;
  @Type(() => Date)
  lastCommunicationTime: Date;
  groupId: string;
  componentName: string;
  componentId: string;
  groupName: string;
}

export const enum DeviceStatus {
  Ok = 0,
  WarningHumidityHigh = 1,
  WarningAccelerometerShock = 2,
  AlertNoCommunication = 3,
  AlertBatteryLow = 4,
  ERROR = 5,
}
