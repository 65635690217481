<app-info-page
  [links]="links"
  [title]="getTitle()"
  [showNav]="false"
  [breadcrumbs]="[
    { link: './devices', label: 'Logistics settings' },
    { link: './devices', label: 'Device Pool' }
  ]"
>
</app-info-page>
