<mat-form-field appearance="outline">
  <mat-select [formControl]="formControl" (selectionChange)="notify()" [disabled]="disabled">
    <mat-option
      *ngFor="let option of optionArray; trackBy: identify"
      id="option-{{ option.label }}"
      value="{{ option.value }}"
      >{{ option.label }}</mat-option
    >
  </mat-select>
</mat-form-field>
