import { ILayer } from './Interfaces';
import { EntityRect } from './EntityRect';
import { Renderer2 } from '@angular/core';

abstract class ClusterEntity {
  public abstract creator(renderer: Renderer2): ILayer;

  create(renderer: Renderer2): ILayer {
    const group: ILayer = this.creator(renderer);
    group.build(renderer);

    return group;
  }
}

class AvailableRectangle extends ClusterEntity {
  public creator(): ILayer {
    return new EntityRect('available ');
  }
}

class ServiceRectangle extends ClusterEntity {
  public creator(): ILayer {
    return new EntityRect('service ');
  }
}

class ReturnRectangle extends ClusterEntity {
  public creator(): ILayer {
    return new EntityRect('return ');
  }
}

class UseRectangle extends ClusterEntity {
  public creator(): ILayer {
    return new EntityRect('use ');
  }
}

class HumidityOKRectangle extends ClusterEntity {
  public creator(): ILayer {
    return new EntityRect('humidity-ok ');
  }
}

class HumidityWarningRectangle extends ClusterEntity {
  public creator(): ILayer {
    return new EntityRect('humidity-warning ');
  }
}

export function RectEntityFactory(entityType: string): ClusterEntity {
  switch (entityType) {
    case 'Available':
      return new AvailableRectangle();
    case 'Service':
      return new ServiceRectangle();
    case 'Return':
      return new ReturnRectangle();
    case 'Use':
      return new UseRectangle();
    case 'HumidityOk':
      return new HumidityOKRectangle();
    case 'HumidityWarning':
      return new HumidityWarningRectangle();
    default:
      return new AvailableRectangle();
  }
}
