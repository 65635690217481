<div class="header-wrapper">
  <p class="header-text">User management</p>
  <button mat-flat-button color="primary" ngProjectAs="buttons" [routerLink]="['/admin/create-user']">+ User</button>
</div>

<mat-card class="filtercard">
  <form>
    <div class="forms-container">
      <div class="form-field-container">
        <p class="form-field-header">
          {{ "component.OVERVIEW.FORM-FIELD.LABEL.SEARCH" | translate }}
        </p>
        <app-search-field
          class="search-field"
          (keyUp)="updateData($event)"
          [placeHolder]="'component.OVERVIEW.FORM-FIELD.PLACE_HOLDER' | translate"
        ></app-search-field>
      </div>

      <div class="form-field-container">
        <p class="form-field-header">
          {{ "USER_MANAGEMENT.USER_LIST.FORM-FIELD.LABEL.STATUS" | translate }}
        </p>
        <app-select-field
          class="select-field"
          (selectionChange)="setStatusControl($event)"
          [optionArray]="[
            { value: '_all_', label: 'All' },
            { value: 'active', label: 'Active' },
            { value: 'not-active', label: 'Not active' },
            { value: 'pending', label: 'Pending' }
          ]"
          [defaultChoice]="'_all_'"
        ></app-select-field>
      </div>

      <div class="form-field-container">
        <p class="form-field-header">
          {{ "USER_MANAGEMENT.USER_LIST.FORM-FIELD.LABEL.GROUP" | translate }}
        </p>
        <app-select-field
          class="select-field"
          (selectionChange)="setGroupControl($event)"
          [optionArray]="groups"
          [defaultChoice]="'_all_'"
        ></app-select-field>
      </div>

      <div class="form-field-container">
        <p class="form-field-header">
          {{ "component.OVERVIEW.FORM-FIELD.LABEL.SORT" | translate }}
        </p>
        <app-select-field
          class="select-field"
          (selectionChange)="setSortControl($event)"
          [optionArray]="[
            { value: '_all_', label: 'All' },
            { value: 'name', label: 'Name' },
            { value: 'status', label: 'Status' },
            { value: 'group', label: 'Group' }
          ]"
          [defaultChoice]="'_all_'"
        ></app-select-field>
      </div>
    </div>
  </form>
</mat-card>

<app-table [tableCols]="displayedColumns" [dataSource]="dataSource"></app-table>
