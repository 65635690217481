<div class="incident-project-wrapper border-top-1">
  <div class="filter-container border-bottom-1 space-between pb-m">
    <div>
      <button
        class="hover-button margin-right-xs"
        *ngIf="isHumidity()"
        mat-button
        [matMenuTriggerFor]="typeMenu"
        (click)="isFilterClicked = true"
        [ngClass]="{ 'focus-button': isFilterClicked }"
      >
        <svg-icon class="orange-color incident-icon mr-s black-icon" src="{{ incidentTypeIcon }}"> </svg-icon>
        {{ incidentTypeText }}
      </button>

      <button class="hover-button" mat-button (click)="setIncidentStatus()">
        <span>
          <svg-icon class="orange-color mr-s" [src]="iconResolve"> </svg-icon>
        </span>
        {{ resolvedFilterText }}
      </button>
    </div>
  </div>
  <mat-card class="shadow-card">
    <mat-menu #typeMenu="matMenu" (closed)="isFilterClicked = false" (menuOpened)="isFilterClicked = true">
      <button style="width: 200px" mat-menu-item *ngFor="let type of incidentTypes" (click)="setIncidentType(type)">
        <svg-icon
          class="black-icon"
          [svgStyle]="{ 'width.px': 14, 'margin-right.px': 12 }"
          src="{{ type.icon }}"
        ></svg-icon>
        {{ type.label }}
      </button>
    </mat-menu>
    <div class="table_content">
      <app-table
        [hidden]="!loaded"
        [tableCols]="displayedColumns"
        [dataSource]="incidents"
        [showSum]="true"
        [selectedPageIndex]="pageNumber"
        [pageSize]="pageSize"
        [totalItems]="totalItemCount"
        (paginationChanged)="handlePaginationEvent($event)"
        (sortingChanged)="sortColumns($event)"
      >
      </app-table>
    </div>

    <div [hidden]="loaded" class="spinner-wrapper">
      <mat-spinner></mat-spinner>
    </div>
  </mat-card>
</div>
