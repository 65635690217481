<div fxLayout="column" class="content p-l" fxLayoutGap="16px">
  <div class="flex-row">
    <!-- first details + statistics section-->
    <div class="flex-col flex-basis-1-1-auto">
      <app-component-detail-card
        *ngIf="component"
        class="mb-m mr-m"
        [solution]="solutionSpace"
        [component]="component"
      ></app-component-detail-card>

      <mat-card class="info-card flex-basis-1 mat-p-overwite mr-m">
        <ng-container *ngIf="solutionSpace === 'utilization'">
          <div class="flex-row space-between mb-xl">
            <span class="auto-margin-top-bottom humidity-title capitalize">{{ solutionSpace }}</span>
          </div>
          <div class="mb-xl">
            <div class="grey-title grey mb-s mt-l1">
              {{ "component.DETAILS.OVERVIEW.UTILIZATION_STATISTICS_TITLE" | translate }}
            </div>
            <div class="flex" *ngIf="utilizationStatistics$ | async as utilizationStatistics">
              <div style="font-weight: 700; font-size: 36px; letter-spacing: 0.25; padding-right: 4px">
                {{
                  utilizationStatistics.daysAvailable +
                    utilizationStatistics.daysInUse +
                    utilizationStatistics.daysInReturn +
                    utilizationStatistics.daysInService
                }}
              </div>
              <div class="prefix">{{ "SHARED.DAYS" | translate }}</div>
            </div>
          </div>
          <div>
            <app-utilization-statistics
              *ngIf="utilizationStatistics$ | async as utilizationStatistics"
              [data]="utilizationStatistics"
            ></app-utilization-statistics>
          </div>
        </ng-container>
        <ng-container *ngIf="solutionSpace === 'humidity'">
          <div class="flex-row space-between mb-l">
            <span class="humidity-title">{{
              "component.DETAILS.OVERVIEW.HUMIDITY_AND_TEMPERATURE_TITLE" | translate
            }}</span>
            <div class="flex-row">
              <polytech-ui-date-picker
                (dateChanged)="updateDateRange($event, 'MEASUREMENTS')"
                initialTimeRange="14"
                [canAlwaysConfirm]="true"
                timeZone="Africa/Abidjan"
                #datePickerHumRef
              >
                <ng-container>
                  <dt (click)="datePickerHumRef.setDefinedRange('14d')">
                    {{ "SHARED.LAST_COMMUNICATED.14_DAYS" | translate }}
                  </dt>
                  <dt (click)="datePickerHumRef.setDefinedRange('3m')">
                    {{ "SHARED.LAST_COMMUNICATED.3_MONTHS" | translate }}
                  </dt>
                  <dt (click)="datePickerHumRef.setDefinedRange('6m')">
                    {{ "SHARED.LAST_COMMUNICATED.6_MONTHS" | translate }}
                  </dt>
                  <dt (click)="selectAllData()">{{ "SHARED.LAST_COMMUNICATED.ALL_TIME" | translate }}</dt>
                </ng-container>
              </polytech-ui-date-picker>
            </div>
          </div>
          <mat-card-content>
            <app-meteorology-chart *ngIf="metData$ | async as metData" [data]="metData"></app-meteorology-chart>
          </mat-card-content>
        </ng-container>
      </mat-card>
    </div>
    <div style="padding-bottom: 0px !important; min-height: 100%; width: 50%" class="flex-basis-1-1-auto">
      <app-recent-history
        [isHistoryLoaded]="isActivitiesLoaded"
        [timeline]="(activities$ | async) ?? undefined"
      ></app-recent-history>
    </div>
  </div>
  <!-- maptrack section -->
  <div class="br-8 info-card maptrack-card">
    <mat-sidenav-container class="full-height br-8 sidenav-container" autosize>
      <mat-sidenav #drawer class="sidenav-drawer" opened="true" mode="side" disableClose="true">
        <div style="padding: 24px" class="flex-col">
          <section>
            <span class="auto-margin-top-bottom humidity-title">
              {{ "component.DETAILS.OVERVIEW.TABLE.TITLE" | translate }}
            </span>
            <div class="form-container" style="display: flex">
              <div class="form-field-container pr-s main-page flex-child-100">
                <app-filter
                  [filterOptions]="filtersOptions"
                  [toggleSearchBar]="false"
                  (updateData)="updateData($event)"
                ></app-filter>
              </div>
            </div>
          </section>
          <section class="scroll_table">
            <ng-container *ngIf="isMaptrackLoaded">
              <app-table
                [tableCols]="displayedColumns"
                [dataSource]="dataSource"
                (rowClicked)="addTrackPointToMap($event)"
                class="max-height"
              ></app-table>
              <div style="text-align: center; padding-top: 64px" *ngIf="allTrackingPoints.length === 0">
                <h2>{{ "component.DETAILS.OVERVIEW.TABLE.NO_DATA_HEADER" | translate }}</h2>
                <ng-container
                  *ngIf="
                    dataSource.length === 0 &&
                    (currentFilter.lastCommunicationTimeFrom === undefined ||
                      currentFilter.lastCommunicationTimeTo === undefined) &&
                    shortcutFilter.value !== 'All'
                  "
                >
                  <h4 class="text-empty-label mb-m">
                    {{ "component.DETAILS.OVERVIEW.TABLE.NO_DATA_INFO" | translate }}
                  </h4>
                  <button
                    mat-flat-button
                    style="background-color: #4682b4; color: white"
                    (click)="setFilterDateRange(shortcutFilter.value)"
                  >
                    {{ shortcutFilter.text | translate }}
                  </button>
                </ng-container>
              </div>
            </ng-container>
          </section>
          <ng-container *ngIf="!isMaptrackLoaded">
            <h4 style="margin: auto; padding-top: 64px" class="text-empty-label">
              {{ "component.DETAILS.OVERVIEW.TABLE.LOADING_DATA" | translate }}
            </h4>
            <mat-spinner class="spinner-position"></mat-spinner>
          </ng-container>
        </div>
      </mat-sidenav>
      <mat-sidenav-content style="position: relative">
        <ng-template [ngIf]="markerData">
          <app-map-view
            mapId="map-track-detail"
            [markerData]="markerData"
            [routeData]="routeData"
            [hasRouting]="true"
          ></app-map-view>
        </ng-template>
        <div class="btn-toggle" (click)="toggle(drawer)">
          <svg-icon *ngIf="isTableOpen" src="/assets/icons/icon-40-collapse.svg" [class]="'icon-mobile'"></svg-icon>
          <svg-icon *ngIf="!isTableOpen" src="/assets/icons/icon-40-expand.svg" [class]="'icon-mobile'"></svg-icon>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
