import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ComponentType } from 'src/app/models/component-type';
import { SolutionSpace } from 'src/app/models/solution';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-type-field',
  templateUrl: './type-field.component.html',
  styleUrls: ['./type-field.component.scss'],
})
export class TypeFieldComponent implements OnInit {
  @Output() selectionChange = new EventEmitter<ComponentType>();
  @Output() createNew = new EventEmitter();
  @Output() edit = new EventEmitter<string>();
  @Input() optionArray: ComponentType[];
  @Input() defaultChoice: string | undefined;
  @Input() solution: SolutionSpace;
  @Input() enableCreation = true;
  @Input() isEditable = true;

  formControl: UntypedFormControl;

  constructor() {}

  setValue(search: string): void {
    this.formControl.setValue(search);
  }

  identify(index: number, item: ComponentType): string {
    return item.id;
  }

  ngOnInit(): void {
    this.formControl = new UntypedFormControl(this.defaultChoice);
  }

  notify(): void {
    this.selectionChange.emit(this.optionArray.find((c) => c.id == this.formControl.value));
  }

  createNewClicked(): void {
    this.createNew.emit();
  }

  editClicked(event: MouseEvent, value: string): void {
    this.edit.emit(value);
  }

  isHumidity(): boolean {
    return this.solution === SolutionSpace.Humidity;
  }
}
