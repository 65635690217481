<div class="flex-col full-height">
  <ng-container *ngIf="!isProjectComponentlist">
    <app-info-page
      title="{{ 'component.componentsOverviewTitle' | translate }}"
      [breadcrumbs]="[{ link: '.', label: 'Components' }]"
      [isComponentOrProject]="isProjectComponentlist ? 'PROJECT' : 'COMPONENT'"
    >
      <div ngProjectAs="buttons">
        <app-button
          theme="primary"
          [id]="'btn-create-component'"
          class="btn-create-component"
          [fxHide]="this.responsiveService.isMobile()"
          [routerLink]="['/components/create']"
          [queryParams]="{ solution: activeLink, origin: getOrigin() }"
          buttonText="{{ 'component.createComponent' | translate }}"
          iconUrl="/assets/icons/icon-16-add.svg"
        ></app-button>
      </div>
    </app-info-page>
    <nav mat-tab-nav-bar *ngIf="dualSolutions()" class="solution-tabs">
      <a
        mat-tab-link
        [id]="'link-tab-' + link.id"
        [routerLink]="link.path"
        *ngFor="let link of links"
        (click)="activeLink = link.id"
        [active]="activeLink === link.id"
      >
        {{ link.label | translate }}
      </a>
    </nav>
  </ng-container>
  <div class="filter-wrapper">
    <app-components-menu
      #componentsFilters
      (updateData)="updateFilter($event)"
      [canSwapToMap]="true"
      [isProjectComponentlist]="isProjectComponentlist"
      [types]="types"
      [projects]="projects"
      [selectedRows]="selectedIds.length"
      [filterOptions]="solutionSpace === ENUM_UtilOrHumid.Utilization ? utilizationFiltersOption : humFiltersOption"
      (exportComponents)="openExportDialog($event)"
      (archiveComponents)="openArchiveDialog()"
      (projectDialogTrigger)="openProjectDialog()"
      (unselectTrigger)="onClearSelectionTrigger()"
      (switchView)="onSwitchView($event)"
    ></app-components-menu>
  </div>

  <div
    class="components-content"
    [ngClass]="{
      'border-top-1': isProjectComponentlist
    }"
  >
    <ng-container *ngIf="showMap">
      <div *ngIf="loadingMapData" class="progress-container">
        <div class="progress-bar" id="progress-bar" [style.width]="loadingProgressPercentage + '%'"></div>
      </div>
      <app-map
        id="component-map"
        [components]="(mapData | async) || []"
        [solution]="solutionSpace"
        (searchInArea)="onSearchInArea($event)"
      ></app-map>
    </ng-container>

    <ng-container *ngIf="!showMap">
      <div class="table-wrapper pr-l">
        <mat-card class="card-margin info-card shadow-card pt-xs">
          <mat-card-content>
            <div [hidden]="!loaded" id="component-list-table-wrapper">
              <app-table
                id="component-table"
                #tableComponent
                [bulkEnabled]="true"
                [tableCols]="displayedColumns"
                [showSum]="true"
                [dataSource]="dataSource"
                [selectedPageIndex]="pageNumber"
                [pageSize]="pageSize"
                [totalItems]="totalItemCount"
                (paginationChanged)="onPaginationChanged($event)"
                (selectionChanged)="onSelectionChanged($event)"
                (sortingChanged)="onSortingChanged($event)"
                [pageSizeOptions]="pageSizeOptions"
              ></app-table>
            </div>
            <div *ngIf="!loaded" class="spinner-wrapper">
              <mat-spinner></mat-spinner>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </ng-container>
  </div>
</div>
