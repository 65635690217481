<div [ngClass]="isMobile() ? 'devices-mobile-wrapper' : 'devices-wrapper'">
  <div class="filter-container mb-m">
    <div class="flex-col">
      <button class="hover-button margin-right-xs no-padding-left" [matMenuTriggerFor]="filter" mat-button>
        <div style="float: left">
          <svg-icon
            [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
            class="icon-hover pr-xs black-icon"
            src="{{ displayedFilter.icon }}"
          ></svg-icon>
          {{ displayedFilter.label }}
        </div>
      </button>
    </div>

    <mat-menu #filter="matMenu">
      <ng-container matMenuContent *ngFor="let type of deviceTypes">
        <button mat-menu-item class="button-size" (click)="setDeviceTypeControl(type)">
          <svg-icon
            [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
            class="icon-hover pr-xs black-icon"
            src="{{ type.icon }}"
          ></svg-icon>

          {{ type.label }}
        </button>
      </ng-container>
    </mat-menu>

    <app-search-field
      class="search-field"
      (keyUp)="setSearchControl($event)"
      [placeHolder]="'component.OVERVIEW.FORM-FIELD.PLACE_HOLDER' | translate"
      #searchcontrol
    ></app-search-field>
  </div>
  <mat-card class="shadow-card">
    <ng-container>
      <div *ngIf="!isLoaded" class="spinner-wrapper">
        <mat-spinner></mat-spinner>
      </div>

      <div [ngClass]="{ 'pl-l pt-l': !isMobile(), 'p-s': isMobile() }" id="devices-list-table-wrapper">
        <app-table
          id="devices-table"
          [hidden]="!isLoaded"
          [tableCols]="displayedColumns"
          [dataSource]="devices"
          [pageSize]="pageSize"
          [totalItems]="totalItemCount"
          [selectedPageIndex]="pageNumber"
          [showSum]="true"
          (sortingChanged)="setSorting($event)"
          [showSum]="isMobile() ? false : true"
          (paginationChanged)="handlePaginationEvent($event)"
        >
        </app-table>
      </div>
    </ng-container>
  </mat-card>
</div>
