<mat-form-field appearance="outline" class="no-border">
  <div class="flex">
    <svg-icon
      *ngIf="typing"
      (click)="cleanupSearch()"
      src="/assets/images/icon-40-close.svg"
      class="orange"
      [svgStyle]="{ 'width.px': 18, 'height.px': 22 }"
    ></svg-icon>

    <svg-icon
      class="orange"
      [ngClass]="{ orange: !typing && isFocused }"
      *ngIf="!typing"
      src="/assets/images/icon-24-search.svg"
      [svgStyle]="{ 'width.px': 18, 'height.px': 22 }"
    ></svg-icon>

    <input
      matInput
      class="input-search"
      type="search"
      (keyup)="notify()"
      (focus)="focusNotify()"
      (focusout)="isFocused = false"
      placeholder="{{ placeHolder }}"
      [formControl]="searchControl"
    />
  </div>
</mat-form-field>
