<mat-card class="info-card" style="min-height: 100%">
  <mat-card-title class="title">
    <div class="flex-row">
      <span style="padding-left: 8px">{{ title ? title : ("Activity" | translate) }}</span>
      <mat-paginator
        (page)="triggerPageChange($event)"
        class="historyPagination"
        [pageSize]="pageSize"
        [length]="timeline?.length"
      ></mat-paginator>
    </div>
  </mat-card-title>
  <mat-card-content>
    <div>
      <ul class="timeline" *ngIf="isHistoryLoaded">
        <li *ngFor="let item of displayedTimeLine">
          <svg-icon src="{{ getActivityIcon(item.activity) }}"></svg-icon>
          <div class="timeline-text">
            <span class="timeline-header" [innerHTML]="item.text | formatting"></span><br />
            <span class="timestamp">{{ formatDate(item.timestamp) }}</span>
          </div>
        </li>
      </ul>
      <div class="timeline" *ngIf="!isHistoryLoaded">
        <mat-spinner class="auto-margin spinner-position"></mat-spinner>
      </div>
    </div>
  </mat-card-content>
</mat-card>
