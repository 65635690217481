import { DateTime, DateTimeJSOptions } from 'luxon';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export abstract class DateConversion {
  static get timeZone(): DateTimeJSOptions {
    return { zone: <string>localStorage.getItem('timezone') };
  }

  static get timeZoneString(): string {
    return <string>localStorage.getItem('timezone');
  }

  static daysDiffFromEpoch(timeEpoch1: number, timeEpoch2: number): number {
    const overrideZone1: DateTime = DateTime.fromMillis(timeEpoch1);
    const overrideZone2: DateTime = DateTime.fromMillis(timeEpoch2, DateConversion.timeZone);

    return <number>overrideZone1.diff(overrideZone2, 'days').toObject().days;
  }

  static minutesDiffFromEpoch(timeEpoch1: number, timeEpoch2: number): number {
    const overrideZone1: DateTime = DateTime.fromMillis(timeEpoch1, DateConversion.timeZone);
    const overrideZone2: DateTime = DateTime.fromMillis(timeEpoch2, DateConversion.timeZone);

    return <number>overrideZone1.diff(overrideZone2, 'minutes').toObject().minutes;
  }

  static dayStartFromEpoch(timeEpoch: number): number {
    const overrideZone: DateTime = DateTime.fromMillis(timeEpoch, DateConversion.timeZone);

    const newLocal: DateTime = overrideZone.set({ hour: 0, minute: 0 });
    return newLocal.toMillis();
  }

  static dayEndFromEpoch(timeEpoch: number): number {
    const overrideZone: DateTime = DateTime.fromMillis(timeEpoch, DateConversion.timeZone);

    const newLocal: DateTime = overrideZone
      .set({ hour: 24, minute: 0, second: 0, millisecond: 0 })
      .setZone(DateConversion.timeZoneString);
    return newLocal.toMillis();
  }

  static monthStartDayFromDate(timeDate: Date): number {
    const timeEpoch: number = timeDate.getTime();
    const overrideZone: DateTime = DateTime.fromMillis(timeEpoch, DateConversion.timeZone).set({ day: 1 });

    return overrideZone.weekday;
  }

  static dayStartFromDate(timeDate: Date): number {
    const timeEpoch: number = timeDate.getTime();
    const overrideZone: DateTime = DateTime.fromMillis(timeEpoch, DateConversion.timeZone);

    const newLocal = overrideZone
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .setZone(DateConversion.timeZoneString);
    return newLocal.toMillis();
  }

  static dayEndFromDate(timeDate: Date): number {
    const timeEpoch: number = timeDate.getTime();
    const overrideZone: DateTime = DateTime.fromMillis(timeEpoch, DateConversion.timeZone);

    const newLocal = overrideZone
      .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
      .setZone(DateConversion.timeZoneString);
    return newLocal.toMillis();
  }

  static addDays(timeDate: Date, days: number): number {
    const timeEpoch: number = timeDate.getTime();
    const overrideZone = DateTime.fromMillis(timeEpoch, DateConversion.timeZone);
    return overrideZone.plus({ days: days }).toMillis();
  }

  static addMonths(timeDate: Date, months: number): number {
    const timeEpoch: number = timeDate.getTime();
    const overrideZone: DateTime = DateTime.fromMillis(timeEpoch, DateConversion.timeZone);
    return overrideZone.plus({ months: months }).toMillis();
  }

  static addDaysToEpoch(timeEpoch: number, days: number): number {
    const overrideZone: DateTime = DateTime.fromMillis(timeEpoch, DateConversion.timeZone);
    return overrideZone.plus({ days: days }).toMillis();
  }

  static monthLength(timeDate: Date): number {
    const timeEpoch: number = timeDate.getTime();
    const overrideZone: DateTime = DateTime.fromMillis(timeEpoch, DateConversion.timeZone);
    return overrideZone.daysInMonth || 30;
  }

  static getTime(timeDate: Date): number {
    const timeEpoch: number = timeDate.getTime();
    const overrideZone: DateTime = DateTime.fromMillis(timeEpoch, DateConversion.timeZone);
    return overrideZone.toMillis();
  }

  static getHour(timeDate: Date): number {
    const timeEpoch: number = timeDate.getTime();
    const overrideZone: DateTime = DateTime.fromMillis(timeEpoch, DateConversion.timeZone);
    return overrideZone.hour;
  }

  static getMonthShortFromEpoch(timeEpoch: number): string {
    const overrideZone: DateTime = DateTime.fromMillis(timeEpoch, DateConversion.timeZone);
    return months[overrideZone.month - 1];
  }

  static getYeartFromEpoch(timeEpoch: number): string {
    const overrideZone: DateTime = DateTime.fromMillis(timeEpoch, DateConversion.timeZone);
    return overrideZone.year.toString();
  }

  static getDateFromEpoch(timeEpoch: number): Date {
    const overrideZone: DateTime = DateTime.fromMillis(timeEpoch, DateConversion.timeZone);
    const newLocal = overrideZone
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .setZone(DateConversion.timeZoneString);

    return newLocal.toJSDate();
  }

  static setDay(timeDate: Date, day: number): Date {
    const timeEpoch: number = timeDate.getTime();
    const overrideZone: DateTime = DateTime.fromMillis(timeEpoch, DateConversion.timeZone);
    return new Date(overrideZone.set({ day: day }).setZone(DateConversion.timeZoneString).toMillis());
  }

  static sameDay(timeDate1: Date, timeDate2: Date): boolean {
    const timeEpoch1: number = timeDate1.getTime();
    const timeEpoch2: number = timeDate2.getTime();

    const overrideZone1 = DateTime.fromMillis(timeEpoch1, DateConversion.timeZone).setZone(
      DateConversion.timeZoneString
    );
    const overrideZone2 = DateTime.fromMillis(timeEpoch2, DateConversion.timeZone).setZone(
      DateConversion.timeZoneString
    );

    return (
      overrideZone1.year === overrideZone2.year &&
      overrideZone1.month === overrideZone2.month &&
      overrideZone1.day === overrideZone2.day
    );
  }

  static toISO(timeEpoch: number): string {
    const overrideZone = DateTime.fromMillis(timeEpoch, DateConversion.timeZone).setZone(DateConversion.timeZoneString);
    return overrideZone.toUTC().toISO() || '';
  }
}
