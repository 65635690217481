<div class="center-flex">
  <ng-container *ngFor="let filter of activeFilters">
    <button
      id="filter-active-{{ filter.filterType }}"
      class="filter-button max-content"
      mat-raised-button
      (click)="goToCurrentClickedFilter(filter, $event)"
      [matTooltip]="filter.tooltipText"
    >
      <svg-icon class="fill-black" [svgStyle]="{ 'width.px': 16 }" src="{{ filter.icon }}"> </svg-icon>
      <!-- eliminate the last ccommunication long text for the main page filter -->
      <ng-container *ngIf="filter.filterType === FilterType.MEASUREMENT && selectedMeasurement; else label">
        <span> {{ selectedMeasurement.label | translate }} </span>
      </ng-container>
      <ng-template #label>
        {{ filter.label }}
      </ng-template>
      <ng-container *ngIf="filter.filterType === FilterType.TYPES && selectedTypes.length > 0">
        <span> ({{ selectedTypes.length }}) </span>
      </ng-container>
      <ng-container *ngIf="filter.filterType === FilterType.LASTCOMMUNICATED">
        <span
          >: {{ lastCommuncatedDateRange?.start | formatDate }} - {{ lastCommuncatedDateRange?.end | formatDate }}</span
        >
      </ng-container>
      <ng-container *ngIf="filter.filterType === FilterType.UTILIZATION && selectedStatusLength > 0">
        <span> ({{ selectedStatusLength }}) </span>
      </ng-container>
      <ng-container *ngIf="filter.filterType === FilterType.PROJECTS && selectedProjects.length > 0">
        <span> ({{ selectedProjects.length }}) </span>
      </ng-container>
      <ng-container
        *ngIf="filter.filterType === FilterType.LOCATION && selectedLocations && selectedLocations.length > 0"
      >
        <span> ({{ selectedLocations.length }}) </span>
      </ng-container>
      <ng-container *ngIf="filter.filterType === FilterType.AREA && searchInAreaCoords">
        <span> ({{ "Search In Area" }}) </span>
      </ng-container>
      <svg-icon
        [svgStyle]="{ 'width.px': 14 }"
        src="/assets/icons/close.svg"
        (click)="disableFilter(filter, $event)"
      ></svg-icon>
    </button>
  </ng-container>
  <button
    id="btn-filter"
    class="hover-button margin-right-xs filter text-black width-90"
    [ngClass]="{ 'focus-button': isFilterClicked }"
    style="padding: 0 12px"
    (click)="setFilterSelectionStyle($event)"
  >
    <span>
      <svg-icon [svgStyle]="{ 'width.px': 16, 'height.px': 16 }" class="icon-hover" src="/assets/icons/filter.svg">
      </svg-icon>
      Filter
    </span>
  </button>
  <ng-container *ngIf="toggleSearchBar">
    <app-search-field
      class="search-field"
      (keyUp)="setSearchControl($event)"
      [placeHolder]="'component.OVERVIEW.FORM-FIELD.PLACE_HOLDER' | translate"
      #searchcontrol
    ></app-search-field>
  </ng-container>
</div>
<div #filterMenuContainer *ngIf="isFilterClicked" class="menu-container box-shadow">
  <ng-container *ngIf="!selectedFilter">
    <ng-container *ngFor="let filter of filters">
      <ng-container *ngIf="filter.shouldShowInList">
        <button
          id="filter-{{ filter.filterType }}"
          class="button-size button-styling"
          [disabled]="filter.disabled"
          (click)="selectFilter(filter, $event)"
        >
          <div style="display: contents">
            <svg-icon
              [svgStyle]="{ 'width.px': 16 }"
              class="text-bottom-align pr-m pl-xxs"
              [ngClass]="filter.disabled ? 'grey-icon' : 'black-icon'"
              src="{{ filter.icon }}"
            >
            </svg-icon>
            {{ filter.label }}
          </div>
        </button>
      </ng-container>
    </ng-container>
  </ng-container>

  <!------------------  When measurement is selected ----------------->
  <ng-container *ngIf="selectedFilter === FilterType.MEASUREMENT">
    <app-filter-measurement
      [measurementOptions]="measurements"
      (apply)="onApplyFilter(FilterType.MEASUREMENT)"
      (back)="onApplyFilter(FilterType.MEASUREMENT)"
      (optionChange)="onMeasurementOptionChange($event)"
    ></app-filter-measurement>
  </ng-container>

  <!------------------  When utilization is selected ----------------->
  <ng-container *ngIf="selectedFilter === FilterType.UTILIZATION">
    <app-filter-utilization
      [utilizationOptions]="selectedStatus"
      (apply)="onApplyFilter(FilterType.UTILIZATION)"
      (back)="onApplyFilter(FilterType.UTILIZATION)"
      (optionChange)="onUtilizationOptionChange($event)"
    ></app-filter-utilization>
  </ng-container>

  <!-----------------  when type is selected -------------->
  <ng-container *ngIf="selectedFilter === FilterType.TYPES">
    <app-filter-type
      [typeOptions]="types"
      (apply)="onApplyFilter(FilterType.TYPES)"
      (back)="onApplyFilter(FilterType.TYPES)"
      (optionChange)="onTypeOptionChange($event)"
    ></app-filter-type>
  </ng-container>

  <!-------------------------- when coutry filter is selected------------------>
  <ng-container *ngIf="selectedFilter === FilterType.LOCATION">
    <app-filter-location
      [selectedLocations]="selectedLocations"
      (apply)="onApplyFilter(FilterType.LOCATION)"
      (back)="onApplyFilter(FilterType.LOCATION)"
      (optionChange)="onLocationOptionChange($event)"
    ></app-filter-location>
  </ng-container>

  <!-----------------  when days in state filter is selected -------------->
  <ng-container *ngIf="selectedFilter === FilterType.DAYSINSTATE">
    <app-filter-days-in-state
      [utilizationOptions]="selectedStatus"
      [selectedState]="selectedDaysInStateFilterState"
      (apply)="onApplyFilter(FilterType.DAYSINSTATE)"
      (back)="onApplyFilter(FilterType.DAYSINSTATE)"
      (optionChange)="onDaysInStateOptionChange($event)"
    ></app-filter-days-in-state>
  </ng-container>

  <!-------------------------- ------------------>
  <!-----------------  when project is selected -------------->
  <ng-container *ngIf="selectedFilter === FilterType.PROJECTS">
    <app-filter-project
      [projects]="projects"
      (apply)="onApplyFilter(FilterType.PROJECTS)"
      (back)="onApplyFilter(FilterType.PROJECTS)"
      (optionChange)="onSelectProjects($event)"
    ></app-filter-project>
  </ng-container>

  <!-- when last communicated filers is selected   -->
  <ng-container *ngIf="selectedFilter === FilterType.LASTCOMMUNICATED">
    <app-filter-last-communicated
      (apply)="onApplyFilter(FilterType.LASTCOMMUNICATED)"
      (back)="closePicker()"
      (optionChange)="updateLastCommunicatedDateRange($event)"
    ></app-filter-last-communicated>
  </ng-container>

  <!-- when humidity filters is selected-->
  <ng-container *ngIf="selectedFilter === FilterType.HUMIDITY">
    <app-filter-humidity
      [selectedIntervalOption]="selectedHumidityIntervalOption"
      (apply)="onApplyFilter(FilterType.HUMIDITY)"
      (back)="onApplyFilter(FilterType.HUMIDITY)"
      (optionChange)="onUpdateHumidityRange($event)"
    ></app-filter-humidity>
  </ng-container>

  <!-- when temperature filters is selected-->
  <ng-container *ngIf="selectedFilter === FilterType.TEMPERATURE">
    <app-filter-temperature
      [selectedIntervalOption]="selectedTemperatureIntervalOption"
      (apply)="onApplyFilter(FilterType.TEMPERATURE)"
      (back)="onApplyFilter(FilterType.TEMPERATURE)"
      (optionChange)="onUpdateTemperatureRange($event)"
    ></app-filter-temperature>
  </ng-container>
</div>
