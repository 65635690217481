import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'polytech-ui-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
})
export class PolytechUiInputFieldComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('inputRef', { read: ElementRef }) inputRef: ElementRef;

  public error = '';
  public errorMessage = '';
  private _submitters: NodeList;

  @Input() messages: ValidationErrors = {};
  @Input() control: AbstractControl;
  @Input() type: string;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() autocomplete: string;
  @Input() color = 'gray';

  inputName: string;

  ngOnInit() {
    this.inputName =
      this.label +
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, 5);
  }

  private _updateErrors: () => void;

  ngAfterViewInit(): void {
    const form = this.findFormTag(<HTMLElement>this.inputRef.nativeElement);
    if (form !== null) {
      this.setSubmitters(form.querySelectorAll('button'));
    }

    if (this._submitters.length !== 0) {
      this._updateErrors = this.updateErrors.bind(this);

      this._submitters.forEach((element) => {
        element.addEventListener('click', this._updateErrors, true);
      });
    }
  }

  setSubmitters(submitters: NodeList): void {
    this._submitters = submitters;
  }

  findFormTag(el: Node): HTMLFormElement | null {
    while (el.parentNode) {
      el = el.parentNode;
      if ((el as HTMLElement).tagName === 'FORM') return <HTMLFormElement>el;
    }
    return null;
  }

  updateValue(event: Event) {
    this.control?.markAsDirty();
    this.control?.setValue((event.target as HTMLInputElement).value);
    // this.inputChanged.emit(event.target.value);

    this.updateErrors();
  }

  onFocus() {
    this.error = '';
    this.errorMessage = '';
  }

  updateErrors(): void {
    let required = false;
    if (this.control.validator) {
      const validator = this.control.validator({} as AbstractControl);
      if (validator && validator['required']) {
        required = (this.control.value as string).length === 0;
      }
    }

    if (this.control?.errors && (this.control?.dirty || required)) {
      for (const key in this.control.errors) {
        this.errorMessage = <string>this.messages[key];
      }
    }

    this.error = !!this.control?.errors && (this.control?.dirty || required) ? 'error' : '';
  }

  ngOnDestroy(): void {
    if (this._submitters.length) {
      this._submitters.forEach((element) => {
        element.removeEventListener('click', this._updateErrors);
      });
    }
  }
}
