import { Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Type } from 'class-transformer';
import { ChartDataSets, ChartOptions, ChartPoint } from 'chart.js';
import 'chartjs-plugin-zoom';
import { BaseChartDirective } from 'ng2-charts';
export class MetData {
  @Type(() => Date)
  date: Date;
  humidity: number;
  temperature: number;
  dewPoint: number;
  threshold: number;
  schock?: number;
  shockThreshold?: number;
}

@Component({
  selector: 'app-meteorology-chart',
  templateUrl: './meteorology-chart.component.html',
  styleUrls: ['./meteorology-chart.component.scss'],
})
export class MeteorologyChartComponent implements OnInit, OnDestroy {
  private _data: MetData[] = [];
  HUMIDITY_LABEL = 'Humidity [%]';
  TEMPERATURE_LABEL = 'Temperature [°C]';
  DEW_POINT_LABEL = 'Dew Point [°C]';
  THRESHOLD = 'Threshold';
  @ViewChild(BaseChartDirective, { static: false }) lineChart: BaseChartDirective;
  @Input()
  showLegend = true;
  @Input()
  thresholdValue: number;
  @Input()
  hasHumidityThreshold?: boolean;
  @Input()
  hasTempThreshold?: boolean;
  humidityChartLabels: string[] = [];
  lineChartData: ChartDataSets[];
  options: ChartOptions;
  isZoomHidden = true;
  @Input()
  isMobile: boolean;

  @Input() set data(value: MetData[]) {
    if (value) {
      this._data = value.reverse();
      this.setLabels();
      this.setDataSet();
    }
  }

  constructor(private zone: NgZone) {}
  setLabels(): void {
    this.humidityChartLabels = this._data.map((d) => d.date.toISOString());
  }
  setDataSet() {
    const humidtidyData: ChartPoint[] = this._data.map((d) => ({ x: d.date, y: d.humidity }));
    const dewPoints: ChartPoint[] = this._data.map((d) => ({ x: d.date, y: d.dewPoint }));
    const temperature: ChartPoint[] = this._data.map((d) => ({ x: d.date, y: d.temperature }));
    const threshold: ChartPoint[] = this._data.map((d) => ({ x: d.date, y: d.threshold }));

    this.lineChartData = [
      {
        label: this.HUMIDITY_LABEL,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#4682B4',
        borderColor: '#4682B4',
        pointBorderColor: '#4682B4',
        pointBackgroundColor: '#4682B4',
        pointBorderWidth: 1,
        borderWidth: 2,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: 'rgba(46,82,B4, 0.2);',
        pointHoverBorderColor: '#4682B4',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,

        data: humidtidyData,
        yAxisID: 'yHumidity',
      },
      {
        label: this.TEMPERATURE_LABEL,
        fill: false,
        lineTension: 0.2,
        backgroundColor: '#dc2d0a',
        borderColor: '#dc2d0a',
        borderWidth: 2,
        pointBorderColor: '#dc2d0a',
        pointBackgroundColor: '#dc2d0a',
        pointBorderWidth: 1,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#dc2d0a',
        pointHoverBorderColor: '#dc2d0a',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: temperature,
        yAxisID: 'yTemperature',
      },
      {
        label: this.THRESHOLD,
        fill: false,
        lineTension: 0.1,
        showLine: true,
        backgroundColor: '#4682B4',
        borderColor: '#4682B4',

        borderDash: [1, 1],
        borderDashOffset: 0.0,
        pointBorderColor: '#4682B4',
        pointBackgroundColor: '#4682B4',
        borderWidth: 1.5,
        pointBorderWidth: 1,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#54585A',
        pointHoverBorderColor: '#54585A',
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        data: threshold,
        yAxisID: 'yHumidity',
      },
      {
        label: this.DEW_POINT_LABEL,
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#028767',
        borderColor: '#028767',
        borderCapStyle: 'butt',
        borderWidth: 2,
        pointBorderColor: '#028767',
        pointBackgroundColor: '#028767',
        pointBorderWidth: 1,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#028767',
        pointHoverBorderColor: '#028767',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        data: dewPoints,
        yAxisID: 'yTemperature',
      },
    ];
  }

  ngOnInit(): void {
    this.setOptions();
  }

  resetZoom(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.lineChart.chart['resetZoom']();
    this.isZoomHidden = true;
  }

  setOptions() {
    this.options = {
      plugins: {
        zoom: {
          zoom: {
            mode: 'xy',
            enabled: true,
            onZoom: () => {
              this.isZoomHidden = false;
            },

            drag: {
              enabled: true,
              backgroundColor: 'rgba(173, 216, 230, 0.2)',
              borderColor: 'rgba(173, 216, 230, 1)',
              borderWidth: 1,
            },
          },
        },
      },

      tooltips: {
        mode: 'label',
        position: 'nearest',
        displayColors: false,
        callbacks: {
          label: (tooltipItem, data) => {
            // redraw the tooltip to match the new design
            const index = tooltipItem.datasetIndex;
            if (index !== undefined && data.datasets) {
              const dataPortion = data.datasets[index];
              if (dataPortion.yAxisID) {
                switch (dataPortion.yAxisID) {
                  case 'yHumidity':
                    if (dataPortion.label === this.HUMIDITY_LABEL) {
                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      return `Humidity: ${tooltipItem.value}%`;
                    } else if (dataPortion.label === this.THRESHOLD) {
                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      return `Humidity Threshold: ${tooltipItem.value}%`;
                    }
                    break;
                  case 'yTemperature':
                    if (dataPortion.label === this.DEW_POINT_LABEL) {
                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      return `Dew Point:  ${tooltipItem.value}°C`;
                    } else if (dataPortion.label === this.TEMPERATURE_LABEL) {
                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      return `Temperature: ${tooltipItem.value}°C`;
                    }
                    break;
                }
              }
            }
            return '';
          },
        },
        custom: function (tooltip) {
          if (tooltip.labelColors && tooltip.labelColors.length > 0) tooltip.backgroundColor = '#54585a';
          tooltip.borderColor = 'white';
          tooltip.borderWidth = 2;
        },
      },

      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: this.showLegend,
        position: 'bottom',
        labels: {
          fontColor: '#54585A',
          boxWidth: 5,
          // boxHeight: 0, //0 means use fontSize
          padding: 10,
        },
      },

      scales: {
        xAxes: [
          {
            type: 'time',
            distribution: 'series',
            bounds: 'ticks',
            ticks: {
              fontColor: '#000000',
              fontSize: 12,
              maxTicksLimit: 7,
              minRotation: 0,
              maxRotation: 0,
            },

            time: {
              displayFormats: {
                millisecond: 'MMM DD HH:mm:ss',
                second: 'MMM DD HH:mm:ss',
                minute: 'MMM DD HH:mm',
                hour: 'MMM DD',
                day: 'MMM DD',
                week: 'MMM DD',
                month: 'MMM',
                quarter: 'MMM',
              },
            },
          },
        ],
        yAxes: [
          {
            id: 'yHumidity',
            scaleLabel: {
              display: true,
              labelString: 'Humidity %',
              fontColor: '#000000',
              fontSize: 15,
            },
            position: 'left',
            display: true,
            gridLines: { display: false },
            ticks: {
              beginAtZero: true,
              stepSize: 20,
              max: 100,

              fontColor: '#000000',
            },
          },
          {
            id: 'yTemperature',
            scaleLabel: {
              display: true,
              labelString: 'Temperature °C',
              fontColor: '#000000',
              fontSize: 15,
            },
            display: true,
            ticks: {
              fontColor: '#000000',
              stepSize: 4,
            },
            gridLines: { display: false },

            position: 'right',
          },
        ],
      },
    };
  }

  ngOnDestroy(): void {
    if (this.lineChart) this.lineChart.ngOnDestroy();
  }
}
