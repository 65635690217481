<app-filter-option
  icon="/assets/icons/icon-16-humidity.svg"
  [title]="'FILTER.HUMIDITY.TITLE' | translate"
  (back)="onApply()"
  (apply)="onBack()"
>
  <div class="pr-m pl-m pt-m">
    <app-number-range-input
      unit="%"
      [selectedIntervalOption]="selectedIntervalOption"
      (UpdateNumberRange)="onOptionChange($event)"
    ></app-number-range-input>
  </div>
</app-filter-option>
