import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'polytech-ui-tab-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class PolytechUiTabLinkComponent {
  @ViewChild('rippleRef', { read: ElementRef }) rippleRef: ElementRef;

  @Input() route: string;
  @Input() text: string;
  @Output() activeChanged = new EventEmitter<HTMLElement>();
  @ViewChild('linkRef', { read: ElementRef }) linkRef: ElementRef;

  onRouterLinkActive(event: boolean) {
    if (event === true) {
      this.activeChanged.emit(<HTMLElement>this.linkRef.nativeElement);
    }
  }
}
