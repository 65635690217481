export enum UtilizationMapIcons {
  'map-pin-component-available',
  'map-pin-component-last-in-use',
  'map-pin-component-return',
  'map-pin-component-service',
}

export enum UtilizationPopupTitleIcons {
  '/assets/images/icon-cards-availability-available.svg',
  '/assets/images/icon-cards-availability-in-use.svg',
  '/assets/images/icon-cards-return-40.svg',
  '/assets/images/icon-cards-service-40.svg',
}
