import { Type } from 'class-transformer';
import { SolutionSpace } from './solution';
export class ComponentHealth {
  activeShockIncident: boolean;
  activeHumidityIncident: boolean;
  activeShockIncidentValue: number;
  activeHumidityIncidentValue: number;
  activeBatteryLowIncident: boolean;
  activeNoCommIncident?: boolean;
  batteryLevel: number;
  latestHumidityMeasurement: number;
  latestHumidityThreshold: number;
  latestExternalTemperatureMeasurement: number;
  @Type(() => Date)
  lastCommunication: Date;
}

export function getHumidityIcon(componentHealth: ComponentHealth): string {
  // 2 alerts:
  if (componentHealth.activeBatteryLowIncident && componentHealth.activeNoCommIncident)
    return '/assets/images/icon-status-alert.svg';

  // a warning and an alert:
  if (
    (componentHealth.activeHumidityIncident || componentHealth.activeShockIncident) &&
    (componentHealth.activeNoCommIncident || componentHealth.activeBatteryLowIncident)
  )
    return '/assets/images/icon-status-alert-warning.svg';

  // 2 warnings:
  if (componentHealth.activeShockIncident && componentHealth.activeHumidityIncident)
    return '/assets/icons/icon-40-double-warning.svg';

  // single alerts/warnings:
  if (componentHealth.activeNoCommIncident) return '/assets/images/icon-status-no-comm.svg';
  if (componentHealth.activeBatteryLowIncident) return '/assets/images/icon-status-battery.svg';
  if (componentHealth.activeHumidityIncident) return '/assets/icons/icon-40-activity-log-humidity.svg';
  if (componentHealth.activeShockIncident) return '/assets/icons/icon-40-activity-log-shock.svg';

  // ok:
  return '/assets/images/icon-status-ok.svg'; //'/assets/icons/icon-40-active.svg';
}
export function getUtilizationIconandTitle(componentHealth: ComponentHealth): { text: string; icon: string } {
  if (
    (componentHealth.activeBatteryLowIncident && componentHealth.activeNoCommIncident) ||
    (componentHealth.activeShockIncident && componentHealth.activeNoCommIncident)
  ) {
    return {
      /*text: `Shock ${
        componentHealth.activeShockIncidentValue > 15 ? 16 : Math.round(componentHealth.activeShockIncidentValue)
      } g`,*/
      text: 'Accelearation',
      icon: '/assets/icons/icon-16-shock.svg',
    };
  }

  if (
    !componentHealth.activeBatteryLowIncident &&
    !componentHealth.activeHumidityIncident &&
    !componentHealth.activeNoCommIncident &&
    !componentHealth.activeShockIncident
  )
    return { text: 'OK', icon: '/assets/icons/icon-16-condition.svg' };
  if (componentHealth.activeShockIncident) {
    return {
      /* text: `Shock ${
        componentHealth.activeShockIncidentValue > 15 ? 16 : Math.round(componentHealth.activeShockIncidentValue)
      } g`,*/
      text: 'NSE',
      icon: '/assets/icons/icon-16-shock.svg',
    };
  }
  if (componentHealth.activeNoCommIncident) {
    return { text: 'No comm.', icon: '/assets/images/icon-16-no-comm.svg' };
  }
  if (componentHealth.activeBatteryLowIncident) {
    return { text: 'Low battery', icon: '/assets/images/icon-16-battery.svg' };
  }
  return { text: '', icon: '' };
}
export function getHumidityTitle(componentHealth: ComponentHealth): string {
  if (componentHealth.activeNoCommIncident) return 'No communication';
  if (componentHealth.activeBatteryLowIncident) return 'Low battery';
  if (componentHealth.activeShockIncident /*  && componentHealth.activeHumidityIncident */) {
    /*
    return `Shock: ${
      componentHealth.activeShockIncidentValue > 15 ? 16 : Math.round(componentHealth.activeShockIncidentValue)
    } g`;*/
    return 'NSE';
  }
  if (componentHealth.activeHumidityIncident) {
    return 'High Humidity';
  }
  return 'OK';
}

export function parseComponentHealtToString(componentHealth: ComponentHealth | undefined): string {
  // UTILIZATION Status!
  if (!componentHealth) return '';
  if (componentHealth.activeNoCommIncident) return 'No comm.';
  if (componentHealth.activeBatteryLowIncident) return 'Low battery';
  if (componentHealth.activeShockIncident) return 'NSE';
  /* return `Shock: ${
      componentHealth.activeShockIncidentValue > 15 ? 16 : Math.round(componentHealth.activeShockIncidentValue)
    } g`;*/
  return 'Ok';
}

export function parseComponentHealthToIcon(
  componentHealth: ComponentHealth | undefined,
  solution: SolutionSpace
): string {
  if (!componentHealth) return '';
  if (solution === SolutionSpace.Humidity) {
    if (componentHealth.activeHumidityIncident) return '/assets/icons/icon-16-alert.svg';
    // TODO return "bell" icon for combi (but why?)
    else return '/assets/icons/check-mark.svg';
  } else {
    // UTILIZATION:
    if (
      (componentHealth.activeNoCommIncident && componentHealth.activeBatteryLowIncident) ||
      (componentHealth.activeBatteryLowIncident && componentHealth.activeShockIncident) ||
      (componentHealth.activeNoCommIncident && componentHealth.activeShockIncident)
    )
      return '/assets/icons/icon-16-alarm_.svg';
    if (componentHealth.activeNoCommIncident) return '/assets/icons/icon-16-no-comm.svg';
    if (componentHealth.activeBatteryLowIncident) return '/assets/images/icon-16-battery.svg';
    if (componentHealth.activeShockIncident) return '/assets/icons/icon-16-shock.svg';
  }
  return '';
}

export function statusIconFromComponentHealth(componentHealth: ComponentHealth | undefined, archived = false): string {
  if (archived) return '/assets/icons/icon-16-condition-grey.svg';
  if (!componentHealth) return '';
  if (
    (componentHealth.activeNoCommIncident && componentHealth.activeBatteryLowIncident) ||
    (componentHealth.activeBatteryLowIncident && componentHealth.activeShockIncident) ||
    (componentHealth.activeNoCommIncident && componentHealth.activeShockIncident)
  )
    return '/assets/icons/icon-16-alarm_.svg';
  if (componentHealth.activeHumidityIncident || componentHealth.activeShockIncident)
    return '/assets/icons/icon-16-alert.svg';
  else return '/assets/icons/icon-16-condition.svg';
}

export function incidentValueFromHeathComponent(componentHealth: ComponentHealth): {
  iconLeading?: string;
  value: string;
} {
  if (
    !componentHealth.activeBatteryLowIncident &&
    !componentHealth.activeHumidityIncident &&
    !componentHealth.activeNoCommIncident &&
    !componentHealth.activeShockIncident
  )
    return {
      value: ' ',
    };
  if (componentHealth.activeBatteryLowIncident) {
    return {
      iconLeading: '/assets/icons/icon-16-battery.svg',
      value: 'Low battery',
    };
  }
  if (componentHealth.activeHumidityIncident) {
    return {
      iconLeading: '/assets/icons/icon-16-humidity.svg',
      value: 'Humidity',
    };
  }
  if (componentHealth.activeNoCommIncident) {
    return {
      iconLeading: '/assets/icons/icon-16-no-comm.svg',
      value: 'No communication',
    };
  }
  if (componentHealth.activeShockIncident) {
    return {
      iconLeading: '/assets/icons/icon-16-shock.svg',
      value: 'NSE',
    };
  }
  return {
    iconLeading: '/assets/icons/error.svg',
    value: '<error>',
  };
}
