import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectableOption } from '../filter.component';

@Component({
  selector: 'app-filter-utilization',
  templateUrl: './filter-utilization.component.html',
})
export class FilterUtilizationComponent {
  @Input() utilizationOptions: SelectableOption[] = [];

  @Output() apply = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() optionChange: EventEmitter<SelectableOption[]> = new EventEmitter<SelectableOption[]>();

  onApply() {
    this.apply.emit();
  }

  onBack() {
    this.back.emit();
  }

  onOptionChange(event: SelectableOption[]) {
    this.optionChange.emit(event);
  }
}
