/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Preferences } from 'src/app/models/preferences.model';
import { SolutionSpace } from 'src/app/models/solution';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
})
export class PreferencesComponent implements OnInit {
  preferencesGroup: UntypedFormGroup = new UntypedFormGroup({});
  shockValues: number[] = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  //@Input() show = true; // rendered?
  @Input() enabled = false; // opaque?
  @Input() solution: SolutionSpace;
  @Input() currentPreferences: Preferences | undefined;
  @Output() updateClicked = new EventEmitter();
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    if (this.currentPreferences) {
      this.preferencesGroup = this.fb.group({
        shockMeasurementValue: 0,
        shockMeasurementActive: this.currentPreferences.shockMeasurementActive,
        shockWarning: this.currentPreferences.shockWarning,
        shockWarningValue: this.currentPreferences.shockWarningValue.toString(),
        //batteryAlert: this.currentPreferences.batteryAlert,
        //batteryAlertValue: this.currentPreferences.batteryAlertValue.toString(),
        //noCommAlert: this.currentPreferences.noCommAlert,
      });
      if (this.solution === SolutionSpace.Humidity) {
        this.preferencesGroup.addControl(
          'humidityWarning',
          new UntypedFormControl(this.currentPreferences.humidityWarning)
        );
        this.preferencesGroup.addControl(
          'humidityWarningValue',
          new UntypedFormControl(this.currentPreferences.humidityWarningValue.toString())
        );
      }

      //
    } else {
      this.preferencesGroup = this.fb.group({
        shockMeasurementValue: 0,
        shockMeasurementActive: Preferences.prototype.shockMeasurementActive,
        shockWarning: Preferences.prototype.shockWarning,
        shockWarningValue: Preferences.prototype.shockWarningValue.toString(),
        // batteryAlert: Preferences.prototype.batteryAlert,
        // batteryAlertValue: Preferences.prototype.batteryAlertValue.toString(),
        // noCommAlert: Preferences.prototype.noCommAlert,
      });
      if (this.solution === SolutionSpace.Humidity) {
        this.preferencesGroup.addControl(
          'humidityWarning',
          new UntypedFormControl(Preferences.prototype.humidityWarning)
        );
        this.preferencesGroup.addControl(
          'humidityWarningValue',
          new UntypedFormControl(Preferences.prototype.humidityWarningValue.toString())
        );
      }
    }
  }

  get formGroup(): UntypedFormGroup {
    return this.preferencesGroup;
  }

  get shockMeasurementActive(): boolean {
    return !!this.preferencesGroup.value['shockMeasurementActive'];
  }

  get shockWarningValue(): number {
    return parseInt(this.preferencesGroup.get('shockWarningValue')?.value as string, 10);
  }

  onShockMeasureChange(): void {
    if (!this.shockMeasurementActive) {
      this.preferencesGroup.get('shockWarning')?.setValue(false);
    }
  }

  onShockValueChange(): void {
    this.preferencesGroup.get('shockMeasurementValue')?.setValue(this.shockWarningValue.toString());
  }
  createGroup(): UntypedFormGroup {
    return this.preferencesGroup;
  }

  showHumidity(): boolean {
    return this.solution === SolutionSpace.Humidity;
  }

  clickUpdate(setting: string): void {
    this.updateClicked.emit(setting);
  }
}
