<button class="pr-m button button-styling" (click)="onBack($event)">
  <mat-icon class="margin-right">keyboard_backspace</mat-icon>
  <svg-icon
    class="black-icon"
    [svgStyle]="{ 'width.px': 16, 'padding-right.px': 4, display: 'inherit' }"
    src="{{ icon }}"
  >
  </svg-icon>
  <span>{{ title }}</span>
</button>
<ng-content></ng-content>
<div>
  <button id="filter-apply" class="apply-button mt-l" [disabled]="isDisabled" (click)="onApply()">Apply</button>
</div>
