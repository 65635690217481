const bezierTypes = {
  CUBIC: 0,
  QUADRATIC: 1,
};

interface coords {
  x: number;
  y: number;
}

export class BezierSpline {
  type = 0;
  controls: Array<coords> = [];
  LUT: Array<coords> = [];
  distances: Array<number> = [];
  rotations: Array<number> = [];
  arcLength = 0;
  angle = 0;

  constructor(controls?: Array<coords>) {
    controls = controls || [
      { x: 0, y: 0 },
      { x: 0.5, y: 0.5 },
      { x: 1, y: 1 },
    ];
    this.setControls(controls);
  }

  public setControls = (controls: Array<coords>) => {
    this.type = controls.length === 3 ? bezierTypes.QUADRATIC : bezierTypes.CUBIC;
    this.controls = controls;
    this.createDrawData();
    this.angle = this.setAngle();
  };

  public calculate = (mu: number): coords => {
    return this.type === bezierTypes.CUBIC ? this.cubic(mu) : this.quadratic(mu);
  };

  private cubic = (mu: number) => {
    const mu1 = 1 - mu;
    const mu1_3 = mu1 * mu1 * mu1;
    const mu_3 = mu * mu * mu;
    const p = this.controls;

    const fac1 = 3 * mu * mu1 * mu1;
    const fac2 = 3 * mu * mu * mu1;
    const p0 = p[0],
      p1 = p[1],
      p2 = p[2],
      p3 = p[3];

    const x = mu1_3 * p0['x'] + fac1 * p1['x'] + fac2 * p2['x'] + mu_3 * p3['x'];
    const y = mu1_3 * p0['y'] + fac1 * p1['y'] + fac2 * p2['y'] + mu_3 * p3['y'];
    return { x: x, y: y };
  };

  private quadratic = (mu: number) => {
    const mu2 = mu * mu;
    const mu1 = 1 - mu;
    const mu1_2 = mu1 * mu1;
    const p = this.controls;
    const x = p[0]['x'] * mu1_2 + 2 * p[1]['x'] * mu1 * mu + p[2]['x'] * mu2;
    const y = p[0]['y'] * mu1_2 + 2 * p[1]['y'] * mu1 * mu + p[2]['y'] * mu2;
    return { x: x, y: y };
  };

  private setAngle = () => {
    let angle = Math.atan(
      (this.controls[3]['y'] - this.controls[0]['y']) / (this.controls[3]['x'] - this.controls[0]['x'])
    );

    if (angle < 0) {
      angle = -angle;
    }
    /*        if (this.controls[0]['x'] > this.controls[3]['x'] ){
            angle += Math.PI;
        }*/
    return angle;
  };

  private createDrawData = () => {
    this.createLookup();

    for (let s = 0; s < this.LUT.length - 1; ++s) {
      this.distances[s] = this.calculateDist(this.LUT[s], this.LUT[s + 1]);
      this.rotations[s] = this.calculateRot(this.LUT[s], this.LUT[s + 1]);
      this.arcLength += this.distances[s];
    }
  };

  private createLookup = () => {
    this.LUT[0] = this.calculate(0.0);
    this.LUT[1] = this.calculate(0.02);

    for (let kk = 1; kk < 9; ++kk) {
      this.LUT[kk + 1] = this.calculate(kk / 10);
    }

    this.LUT[10] = this.calculate(0.9);
    this.LUT[11] = this.calculate(0.98);
    this.LUT[12] = this.calculate(1);
  };

  private calculateDist = (a: coords, b: coords): number => {
    let x = b.x - a.x;
    let y = b.y - a.y;

    x *= x;
    y *= y;

    return Math.sqrt(x + y);
  };

  private calculateRot = (a: coords, b: coords) => {
    let rot = Math.atan((b.y - a.y) / (b.x - a.x));

    if (a.x > b.x) {
      rot += Math.PI;
    }
    return rot;
  };
}
