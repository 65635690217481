<h2 mat-dialog-title class="overwrite-margin">{{ this.data.isEdit ? "Edit" : "Create" }} component type</h2>

<div class="form-container type-dialog">
  <form [formGroup]="formGroup">
    <div class="form-field-container">
      <span class="form-field-header">COMPONENT TYPE NAME<span style="color: #de4020">*</span></span>
      <mat-form-field appearance="outline" class="name">
        <input id="input-component-type-name" matInput formControlName="name" required placeholder="Component type name" />
      </mat-form-field>
    </div>
    <div class="form-field-container">
      <span class="form-field-header">DESCRIPTION</span>
      <mat-form-field appearance="outline" class="description">
        <input id="input-component-type-description" matInput formControlName="description" required placeholder="E.g. My Component Type" />
      </mat-form-field>
    </div>
    <ng-container *ngIf="!isHumidity">
      <h3 style="margin-top: 24px; margin-bottom: 3px">Available / In use configuration</h3>
      <p style="font-weight: 400; font-size: 12px; margin-bottom: 16px !important">
        Input the expected minimum and maximum distance to the object that are target for measurement.
      </p>
      <div class="grid">
        <div class="form-field-container space-between">
          <span class="form-field-header">MIN DISTANCE (MM)<span style="color: #de4020">*</span></span>
          <mat-form-field appearance="outline" style="width: 150px">
            <mat-select
              id="select-min-distance"
              *ngIf="!isMobile(); else nativeSelectMin"
              formControlName="mindistance"
              required
              placeholder="Select distance"
              (selectionChange)="minChange()"
            >
              <mat-option
                id="option-min-{{ value }}"
                *ngFor="let value of minValues"
                value="{{ value }}"
              >
                {{ value }}
              </mat-option>
            </mat-select>
            <ng-template #nativeSelectMin>
              <select
                matNativeControl
                formControlName="mindistance"
                required
                placeholder="Select distance"
                (selectionChange)="minChange()"
              >
                <option *ngFor="let value of minValues" value="{{ value }}">
                  {{ value }}
                </option>
              </select>
            </ng-template>
          </mat-form-field>
        </div>
        <div class="form-field-container">
          <span class="form-field-header">MAX DISTANCE (MM)<span style="color: #de4020">*</span></span>
          <mat-form-field appearance="outline" style="width: 150px">
            <mat-select
              id="select-max-distance"
              *ngIf="!isMobile(); else nativeSelectMax"
              formControlName="maxdistance"
              required
              placeholder="Select distance"
              (selectionChange)="maxChange()"
            >
              <mat-option
                id="option-max-{{ value }}"
                *ngFor="let value of maxValues"
                value="{{ value }}"
              >
                {{ value }}
              </mat-option>
            </mat-select>
            <ng-template #nativeSelectMax>
              <select
                matNativeControl
                formControlName="maxdistance"
                required
                placeholder="Select distance"
                (selectionChange)="maxChange()"
              >
                <option *ngFor="let value of maxValues" value="{{ value }}">
                  {{ value }}
                </option>
              </select>
            </ng-template>
          </mat-form-field>
        </div>
      </div>
    </ng-container>
  </form>
</div>
<mat-dialog-actions>
  <ng-container *ngIf="this.data.isEdit">
    <div style="width: 25%">
      <button
        mat-flat-button
        [disabled]="deleteDisabled()"
        [mat-dialog-close]="'delete'"
        style="background-color: {{ deleteDisabled() ? '#777777' : '#f44336' }}; color: white"
      >
        Delete
      </button>
    </div>
  </ng-container>
  <div style="width: {{ this.data.isEdit ? 75 : 100 }}%; text-align: right;">
    <button
      id="btn-component-type-cancel"
      mat-flat-button
      [disabled]="createClicked"
      [mat-dialog-close]="'cancel'"
    >
      Cancel
    </button>
    <button
      id="btn-component-type-{{ this.data.isEdit ? 'update' : 'create' }}"
      mat-flat-button
      [disabled]="createClicked || invalidForm()"
      [class.spinner]="createClicked"
      (click)="onClick()"
      mat-primary
      color="primary"
    >
      {{ this.data.isEdit ? "Update" : "Create" }}
    </button>
  </div>
</mat-dialog-actions>
