<ng-container *ngIf="!isMobile">
  <div class="margin-right-web grid-template">
    <div id="graph">
      <canvas
        id="statisticsChart"
        baseChart
        [datasets]="datasets"
        [labels]="labels"
        [options]="options"
        chartType="bar"
      >
      </canvas>
    </div>
    <div>
      <div class="flex-basis-1 text-center">
        <div class="auto-top-bottom-margin">
          <h1 class="number-cycle">
            {{ data.cycles }}
          </h1>
        </div>
        <div class="flex-center">
          <svg-icon
            class="pr-xs recycle mt-xxxs"
            src="/assets/icons/loop.svg"
            style="fill: #ff6400"
            [svgStyle]="{ 'width.px': 14, 'height.px': 14 }"
          ></svg-icon>
          <span class="legend">Turns</span>
        </div>
      </div>
    </div>
  </div>

  <!-- ------------mobile---------------- -->
  <ng-container *ngIf="isMobile">
    <div class="flex-row flex-center" style="margin-left: 24px; margin-right: 24px">
      <div class="flex-child-30 auto-top-bottom-margin margin-right-mobile">
        <div class="flex-center">
          <div class="flex-row auto-margin">
            <h1 class="grey-labels">{{ data.daysAvailable }}</h1>
            <div class="prefix">Days</div>
          </div>

          <div class="absolute-bottom">
            <svg-icon
              class="pr-xs mt-xxxs"
              src="/assets/icons/check-mark.svg"
              [svgStyle]="{ 'width.px': 14, 'height.px': 14 }"
            ></svg-icon>
            <span class="legend">Available</span>
          </div>
        </div>
      </div>
      <div class="flex-child-30 auto-top-bottom-margin margin-right-mobile">
        <div class="flex-space-arround">
          <div class="flex-row auto-margin">
            <h1 class="grey-labels">{{ data.daysInUse }}</h1>
            <div class="prefix">Days</div>
          </div>

          <div class="absolute-bottom">
            <svg-icon
              class="pr-xs mt-xxxs"
              src="/assets/icons/icon-16-in-use.svg"
              [svgStyle]="{ 'width.px': 14, 'height.px': 14 }"
            ></svg-icon>
            <span class="legend">In use</span>
          </div>
        </div>
      </div>
      <div class="flex-child-30 auto-top-bottom-margin margin-right-mobile">
        <div class="flex-space-arround">
          <h1 class="grey-labels center">{{ data.cycles }}</h1>
          <div class="absolute-bottom">
            <svg-icon
              class="pr-xs recycle mt-xxxs"
              src="/assets/icons/loop.svg"
              style="fill: #ff6400"
              [svgStyle]="{ 'width.px': 14, 'height.px': 14 }"
            ></svg-icon>
            <span class="legend">Est. Turns </span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
