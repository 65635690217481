<div class="filter-container space-between">
  <app-filter
    [filterOptions]="filterOptions"
    [projects]="projects"
    [types]="types"
    (updateData)="updateData.emit($event)"
  ></app-filter>
  <!--
      extra button section
   -->
  <div class="buttons-container">
    <div *ngIf="!isProjectComponentlist">
      <button *ngIf="selectedRows > 0 && !archived" class="hover-button margin-right-xs filter" (click)="deselect()">
        <svg-icon [svgStyle]="{ 'width.px': 14 }" class="orange-color" src="/assets/icons/close.svg"> </svg-icon>
        {{ selectedRows }} selected
      </button>
      <button
        id="btn-archive"
        *ngIf="selectedRows > 0 && !archived"
        class="hover-button margin-right-xs filter"
        (click)="onArchive()"
      >
        <svg-icon [svgStyle]="{ 'width.px': 14 }" class="orange-color" src="/assets/icons/icon-16-archive.svg">
        </svg-icon>

        {{ "component.archive" | translate }}
      </button>
      <button
        id="btn-project"
        *ngIf="selectedRows > 0 && !archived"
        class="hover-button margin-right-xs filter"
        (click)="onProjectClick()"
      >
        <svg-icon [svgStyle]="{ 'width.px': 14 }" class="orange-color" src="assets/icons/icon-16-set-project.svg">
        </svg-icon>

        {{ "component.project" | translate }}
      </button>

      <button
        *ngIf="canSwapToMap"
        id="btn-swap-view"
        class="hover-button margin-right-xs filter"
        (click)="onShowMapClick()"
      >
        <svg-icon
          [ngClass]="selectedRows > 0 ? 'orange-color ' : 'black'"
          [svgStyle]="{ 'width.px': 14 }"
          src="{{ showMap ? '/assets/icons/list_export.svg' : '/assets/icons/icon-16-globe.svg' }}"
        >
        </svg-icon>

        {{ (showMap ? "component.showOnList" : "component.showOnMap") | translate }}
      </button>
    </div>
    <div>
      <button
        id="btn-export"
        class="hover-button margin-right-xs filter"
        [matMenuTriggerFor]="exportMenu"
        *ngIf="!archived && shouldExport"
      >
        <svg-icon
          [ngClass]="selectedRows > 0 ? 'orange-color ' : 'black'"
          [svgStyle]="{ 'width.px': 14 }"
          src="/assets/icons/export.svg"
        >
        </svg-icon>

        {{ "component.export" | translate }}
      </button>

      <!-- submenu of export button-->
      <mat-menu #exportMenu="matMenu">
        <button mat-menu-item (click)="onExport(exportType.FE_LIST)">
          <svg-icon [svgStyle]="{ 'width.px': 14 }" src="/assets/icons/list_export.svg"></svg-icon>
          <span class="pl-xs"> List export </span>
        </button>
        <button
          mat-menu-item
          [disabled]="selectedRows > 200"
          (click)="onExport(exportType.BE_DATA)"
          *ngIf="!isProjectComponentlist"
        >
          <svg-icon [svgStyle]="{ 'width.px': 14 }" src="/assets/icons/data_base.svg"></svg-icon>
          <span class="pl-xs"> Data export </span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
