<h2 mat-dialog-title>
  <svg-icon src="/assets/icons/icon-40-attention.svg"></svg-icon>
  {{ "component.INCIDENTS.REOPEN_DIALOG.TITLE" | translate : { incidentStatus: incidentStatus } }}
</h2>
<mat-dialog-actions style="float: right; padding: 0px 0px 24px 0px">
  <button mat-flat-button [mat-dialog-close]="null">
    {{ "SHARED.CANCEL" | translate }}
  </button>
  <button style="padding: 0px 34px" mat-flat-button [mat-dialog-close]="dialogState" mat-primary color="primary">
    {{ "SHARED.OPEN" | translate }}
  </button>
</mat-dialog-actions>
