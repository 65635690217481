import {
  Component,
  AfterContentInit,
  ElementRef,
  ViewChild,
  ContentChildren,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { PolytechUiTabLinkComponent } from './link/link.component';

@Component({
  selector: 'polytech-ui-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PolytechUiTabBarComponent implements AfterContentInit {
  @ViewChild('tabref', { read: ElementRef }) tabref: ElementRef;
  @ContentChildren(PolytechUiTabLinkComponent) tabs: QueryList<PolytechUiTabLinkComponent>;

  private subscriptions: Array<Subscription | undefined> = [];

  setMarker(element: HTMLElement) {
    const left: number = (element.parentElement?.offsetLeft || 0) >> 0;
    const width: number = element.offsetWidth >> 0;

    const marker: HTMLElement = <HTMLElement>this.tabref.nativeElement;
    marker.setAttribute('style', `left:${left + (width >> 1)}px; width: ${width}px`);
  }

  ngAfterContentInit(): void {
    for (let kk = 0; kk < this.tabs.length; ++kk) {
      this.subscriptions[kk] = this.tabs
        .get(kk)
        ?.activeChanged.subscribe((element: HTMLElement) => this.setMarker(element));
    }
    setTimeout(() => {
      (this.tabref.nativeElement as HTMLElement).classList.add('animate');
    }, 1);
  }

  ngDestroy() {
    for (let kk = 0; kk < this.tabs.length; ++kk) {
      this.subscriptions[kk]?.unsubscribe();
    }
  }
}
