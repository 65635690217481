<span class="header">{{ "component.DIALOG.TITLES.COMPONENT-DATA" | translate }}</span>
<div class="form-container">
  <form [formGroup]="exportGroup">
    <div class="form-field-container">
      <span class="form-field-header">{{ "component.DIALOG.FORM-FIELD.LABEL.TIME-RANGE" | translate }}</span>
      <polytech-ui-date-picker
        (dateChanged)="createExport($event)"
        initialTimeRange="14"
        [canAlwaysConfirm]="true"
        timeZone="Africa/Abidjan"
        [alwaysOpen]="true"
        [headLess]="true"
        confirmText="Export"
        (onClose)="close()"
        #datePickerRef
      >
        <dt (click)="datePickerRef.setDefinedRange('14d')">Last 14 days</dt>
        <dt (click)="datePickerRef.setDefinedRange('3m')">Last 3 months</dt>
        <dt (click)="datePickerRef.setDefinedRange('6m')">Last 6 months</dt>
        <dt (click)="selectAllData()">All Time</dt>
      </polytech-ui-date-picker>
    </div>
  </form>
</div>

<div *ngIf="exporting">
  Preparing export, please hold...
  <div class="loader">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
