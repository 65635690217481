import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-create-dialog',
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.scss'],
})
export class CreateDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      componentName: string;
      isMobile: boolean;
    }
  ) {}

  ngOnInit(): void {}

  dialogTitle(): string {
    return `Component ${this.data.componentName} created`;
  }

  isMobile(): boolean {
    return this.data.isMobile;
  }
}
