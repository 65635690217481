import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Output,
  OnInit,
  ViewChild,
  EventEmitter,
  ElementRef,
  HostListener,
} from '@angular/core';
import { map, Observable, take } from 'rxjs';
import { PolytechUiClickRippleComponent } from '../click-ripple/click-ripple.component';
import { PolytechUiService } from '../shared/services/polytech-ui.service';
import { uiState } from '../shared/interfaces/ui-state.interface';

@Component({
  selector: 'polytech-ui-svg-button',
  templateUrl: './svg-button.component.html',
  styleUrls: ['./svg-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolytechUiSvgButtonComponent implements OnInit {
  constructor(private polytechUiService: PolytechUiService, private changeDetectorRef: ChangeDetectorRef) {}

  private _icon: string;
  @Input() set icon(icon: string) {
    this._icon = icon;
    this.changeDetectorRef.markForCheck();
  }

  public get icon(): string {
    return this._icon;
  }

  @Input() link: string | undefined = undefined;
  @Input() color = '';
  @Input() backing = true;
  @Input() external = false;
  @Input() size: 'small' | 'normal' | 'large' | 'custom' = 'custom';
  @Input() disabled = false;
  @Input() toolTip = '';
  @Input() propagateClick = true;
  @Input() willBodyClick = false;
  @Input() componentId: string | undefined = undefined;

  @Output() buttonClick: EventEmitter<HTMLElement> = new EventEmitter<HTMLElement>();

  @ViewChild('rippleRef') rippleRef: PolytechUiClickRippleComponent;
  @ViewChild('buttonRef', { read: ElementRef }) buttonRef: ElementRef;

  /*
  @HostListener("pointerenter", ['$event'])
  onPointerEnter( event ): void {
      const values = this.buttonRef.nativeElement.getBoundingClientRect();

      this.appHeaderService.toolTip = this.toolTip;
      this.appHeaderService.toolTipPosition = { left: values.left + 80, top: values.top + ( values.height / 2 )};
  }


  @HostListener("pointerleave", ['$event'])
  onPointeLeave( event ): void {
      this.appHeaderService.toolTip = "";
  }
*/

  inactive$: Observable<boolean>;
  uiInactive$: Observable<boolean>;

  get svgPath(): string {
    const svgPath = 'assets/polytech.svg';
    return this.polytechUiService.svgSprite;
  }

  ngOnInit() {
    this.inactive$ = this.polytechUiService.uiState$.pipe(
      map((state: uiState) => this.polytechUiService.blockedNavigationState(state.value))
    );
    this.uiInactive$ = this.polytechUiService.uiState$.pipe(
      map((state: uiState) =>
        this.polytechUiService.blockedUIState(state.value, <HTMLElement>this.buttonRef?.nativeElement)
      )
    );
  }

  isExternalUrl(url = ''): boolean {
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, '');

    return /^(f|ht)tps?:\/\//i.test(newUrl) || this.external;
  }

  clickHandler(event: MouseEvent) {
    if (!this.propagateClick) {
      event.stopPropagation();

      if (this.willBodyClick) {
        document.body.click();
      }
    }

    if (!this.disabled) {
      this.rippleRef.createRipple(event);
      //document.dispatchEvent(new Event('click'));
    }

    this.uiInactive$.pipe(take(1)).subscribe((blocked: boolean) => {
      if (!blocked && this.link === undefined) {
        this.buttonClick.emit(<HTMLElement>event.target);
      }
    });
  }
}
