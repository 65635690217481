import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { PolytechUiInputFieldComponent } from './input-field/input-field.component';
//import { ModalComponent } from './modal/modal.component';
import { PolytechUiSvgButtonComponent } from './svg-button/svg-button.component';
import { PolytechUiClickRippleComponent } from './click-ripple/click-ripple.component';
import { PolytechUiModalCardComponent } from './modal-card/modal-card.component';
import { PolytechUiButtonComponent } from './button/button.component';

@NgModule({
  declarations: [
    PolytechUiInputFieldComponent,
    //        ModalComponent,
    PolytechUiSvgButtonComponent,
    PolytechUiButtonComponent,
    PolytechUiClickRippleComponent,
    PolytechUiModalCardComponent,
  ],
  imports: [CommonModule, RouterModule],
  exports: [
    PolytechUiInputFieldComponent,
    PolytechUiSvgButtonComponent,
    PolytechUiButtonComponent,
    PolytechUiClickRippleComponent,
    PolytechUiModalCardComponent,
  ],
})
export class PolytechUiLiteModule {}
