import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserQuery } from './state/user.query';

export type Group = {
  id: string;
  name: string;
};

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  private userQueryOrgSub: Subscription;
  private organisationId: string;
  private canAccess: string[] = [];

  constructor(private http: HttpClient, userQuery: UserQuery) {
    this.userQueryOrgSub = userQuery.organisation$.subscribe((organisation) => {
      if (organisation) {
        this.organisationId = organisation.organisationId;
        this.canAccess = organisation.canAccess;
      }
    });
  }

  ngOnDestroy() {
    if (this.userQueryOrgSub) {
      this.userQueryOrgSub.unsubscribe();
    }
  }

  get userUrl(): string {
    return `${environment.api}/api/${this.organisationId}/user/`;
  }

  getGroupsForComponentCreation(): Observable<Group[]> {
    if (this.canAccess.includes('component_create')) {
      return this.http.get<Group[]>(`${environment.api}/api/${this.organisationId}/group/`);
    }

    return of([]);
  }

  getGroupsForProjectCreation(): Observable<Group[]> {
    if (this.canAccess.includes('projects_create')) {
      return this.http.get<Group[]>(`${environment.api}/api/${this.organisationId}/group/`);
    }

    return of([]);
  }

  getOrganisationId(): string {
    return this.organisationId;
  }
}
