import { ILayer, IGroup } from './Interfaces';
import { Renderer2 } from '@angular/core';

export class EntityRect implements ILayer {
  public HTML: HTMLElement;
  private _renderer: Renderer2;
  private _classNames = 'box';
  private _label: HTMLElement;
  private _parentGroup: IGroup;

  constructor(type: string) {
    this._classNames += ` ${type}`;
  }

  public build(renderer: Renderer2): ILayer {
    this._renderer = renderer;
    this.HTML = <HTMLElement>renderer.createElement('div');
    this._label = <HTMLElement>renderer.createElement('span');

    renderer.setProperty(this.HTML, 'className', this._classNames);
    this._renderer.appendChild(this.HTML, this._label);
    return this;
  }

  public addClass(className: string): ILayer {
    this._classNames += ` ${className}`;
    this._renderer.setProperty(this.HTML, 'className', this._classNames);
    return this;
  }

  public set value(value: number) {
    if (value === 0) {
      this._renderer.removeChild(this._parentGroup.HTML, this.HTML);
    } else {
      this._renderer.appendChild(this._parentGroup.HTML, this.HTML);
    }
    this._label.innerHTML = value.toString();
  }

  public addTo(group: IGroup): ILayer {
    this._parentGroup = group;
    this._renderer.appendChild(group.HTML, this.HTML);
    return this;
  }
}
