<app-filter-option
  icon="/assets/icons/icon-16-tag.svg"
  [title]="'FILTER.LOCATION.TITLE' | translate"
  (apply)="onApply()"
  (back)="onBack()"
>
  <div class="pr-m pl-m">
    <mat-form-field
      id="form-field-location"
      (click)="$event.stopPropagation()"
      appearance="outline"
      style="font-size: 12px"
    >
      <div class="mat-chip-list-wrapper">
        <mat-chip-list #chipList class="chips">
          <mat-chip *ngFor="let location of selectedLocations; let indx = index" (removed)="onRemoveLocation(indx)">
            {{ location.name }}
            <mat-icon matChipRemove style="color: black; opacity: 100; min-height: 18px; min-width: 18px"
              >close</mat-icon
            >
          </mat-chip>
          <input
            matInput
            #LocationInput
            [formControl]="locationCtrl"
            [placeholder]="'FILTER.LOCATION.PLACEHOLDER' | translate"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          />
        </mat-chip-list>
      </div>
      <mat-autocomplete
        #auto="matAutocomplete"
        [panelWidth]="350"
        (optionSelected)="onOptionChange($event.option.value)"
        class="autocompletion-location-list"
      >
        <div *ngFor="let location of selectableLocations | async">
          <mat-option [value]="location">
            <div [ngClass]="typeToStyleClass[location.type]">
              <mat-icon class="no-margin">{{ typeToIcon[location.type] }}</mat-icon>
              {{ location.name }}
            </div>
          </mat-option>
        </div>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</app-filter-option>
