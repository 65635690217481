export interface LocationInfo {
  id: string;
  name: string;
  type: LocationType;
}

export enum LocationType {
  COUNTRY,
  REGION,
  PLACE,
  POSTCODE,
}

export interface CountryLocations {
  countries: Country[];
}

export interface Country {
  id: string;
  name: string;
  regions: Region[];
}

export interface Region {
  id: string;
  name: string;
  places: Place[];
}

export interface Place {
  id: string;
  name: string;
  postcodes: Postcode[];
}

export interface Postcode {
  id: string;
  name: string;
}
