<a class="nav-cancel" (click)="clickNav()">
  <svg-icon src="{{ iconPath }}"></svg-icon>
  <span>{{ navText | translate }}</span>
</a>
<div class="stepper-container">
  <header class="step-header">{{ stepHeader | translate }}
  </header>
  <form class="mat-mt-l mat-ml-l">
    <div class="form-field-container" *ngIf="doubleSolutionAccess">
      <p class="form-field-header">{{ "project.FORM.SOLUTION" | translate }}</p>
      <app-select-field class="select-field inverted " (selectionChange)="form.updateSolution($event)"
        [disabled]="!!project" [optionArray]="[
              { value: 'utilization', label: 'Utilization monitoring' },
              { value: 'humidity', label: 'Humidity monitoring' }
            ]" [defaultChoice]="form.solution" style="width: 1000px;"></app-select-field>
    </div>

    <div class="form-field-container">
      <p class="form-field-header">
        {{ "SHARED.NAME" | translate }}<span *ngIf="!form.nameValidated()" class="required">*</span>
      </p>
      <app-input-field class="input-name input-field inverted" (valueChanged)="form.updateName($event)"
        [defaultValue]="form.name"></app-input-field>
    </div>
    <div class="form-field-container">
      <p class="form-field-header">{{ "SHARED.DESCRIPTION" | translate }}</p>
      <app-input-field class="input-name input-field inverted" (valueChanged)="form.updateDescription($event)"
        [defaultValue]="form.description"></app-input-field>
    </div>

    <div class="form-field-container margin-last-field">
      <p class="form-field-header">
        {{ "SHARED.GROUP" | translate }}<span *ngIf="!form.groupValidated()" class="required">*</span>
      </p>
      <app-select-field class="select-field inverted" (selectionChange)="form.updateGroup($event)"
        [disabled]="!!project || !(groups && groups.length > 1)" [optionArray]="groupValues"
        [defaultChoice]="form.group"></app-select-field>
    </div>
    <hr />
    <div class="button-container create">
      <button class="btn-red button-margin button-left-align" [disabled]="project.archived" mat-flat-button
        color="warning" (click)="openArchiveDialog()" *ngIf="project !== undefined">
        {{ "project.FORM.ARCHIVE_PROJECT" | translate }}
      </button>
      <button *ngIf="project === undefined" mat-flat-button (click)="onSubmit()" color="primary"
        [disabled]="!form.valid() || loading" [class.spinner]="loading" class="btn-submit">
        {{ "project.FORM.CREATE_PROJECT" | translate }}
      </button>
      <button *ngIf="project !== undefined" mat-flat-button (click)="onSubmit()" color="primary"
        [disabled]="!form.valid() || form.pristine()">
        {{ "project.FORM.UPDATE_PROJECT" | translate }}
      </button>
    </div>
  </form>
</div>