<app-filter-option
  icon="/assets/icons/utilization-device.svg"
  [title]="'FILTER.UTILIZATION.TITLE' | translate"
  (apply)="onApply()"
  (back)="onBack()"
>
  <app-multi-checkbox
    (click)="$event.stopPropagation()"
    [optionArray]="utilizationOptions"
    (optionChange)="onOptionChange($event)"
  >
  </app-multi-checkbox>
</app-filter-option>
