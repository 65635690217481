import { Renderer2 } from '@angular/core';
import { IPin } from './map-view/map-view.component';
import { SolutionSpace } from '../models/solution';
import { PopupFactory, PopupType } from './map-view/factories/popups/FactoryPopup';
import { IPopupData, ISimplePopupData, IPopup } from './map-view/factories/popups/Interfaces';

export class MapUtil {
  static areEqual(a: IPin[], b: IPin[]): boolean {
    if (a.length !== b.length) {
      return false;
    }

    return a[0]?.lat === b[0]?.lat && a[0]?.lng === b[0]?.lng;
  }

  static getPopup(
    renderer: Renderer2,
    popupType: PopupType,
    solutionSpace: SolutionSpace,
    popupData: IPopupData | ISimplePopupData
  ): string {
    const popup: IPopup = PopupFactory(popupType, solutionSpace).create(renderer, popupData);
    return popup.HTML.outerHTML;
  }
}
