<polytech-ui-svg-button
  *ngIf="!alwaysOpen && !headLess"
  icon="calendar"
  [backing]="false"
  size="small"
  [willBodyClick]="true"
  (buttonClick)="togglePickerWindow()"
  [propagateClick]="false"
  componentId="calendarButton"
>
</polytech-ui-svg-button>
<label
  class="label"
  (click)="togglePickerWindow(); $event.stopPropagation()"
  role="presentation"
  [ngClass]="{ headless: headLess }"
>
  {{ startDateStr }} - {{ endDateStr }}
</label>
<polytech-ui-modal-card
  class="month__section"
  (animationend)="stopShake()"
  [className]="popupPosition"
  [ngClass]="{ open: isOpen, headless: headLess }"
  #wrapperRef
>
  <article (click)="$event.stopPropagation()">
    <div class="choicelist">
      <dl>
        <ng-content></ng-content>
      </dl>
    </div>

    <polytech-ui-date-month
      #startMonthRef
      (currentSelection)="setStartDate($event)"
      [latest]="_endTimestamp"
    ></polytech-ui-date-month>
    <polytech-ui-date-month
      #endMonthRef
      (currentSelection)="setEndDate($event)"
      [earliest]="_startTimestamp"
    ></polytech-ui-date-month>

    <footer class="buttons">
      <span class="select-date">{{ _startDateStr }} - {{ _endDateStr }}</span>
      <span>
        <polytech-ui-button (buttonAction)="cancelDate($event)" buttonType="text" [secondary]="true">
          {{ cancelText }}
        </polytech-ui-button>
        <polytech-ui-button [disabled]="disableConfirm" (buttonAction)="submitDate($event)" buttonType="text">
          {{ confirmText }}
        </polytech-ui-button>
      </span>
    </footer>
  </article>
</polytech-ui-modal-card>
