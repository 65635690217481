/* eslint-disable @typescript-eslint/no-for-in-array */
import { IPosition } from './map-view/map-view.component';
import { BehaviorSubject } from 'rxjs';
import * as mapboxgl from 'mapbox-gl';
import { IPin } from '../shared/map-view/map-view.component';

export class ClusterFormatter {
  public clusters = new BehaviorSubject<GeoJSON.FeatureCollection>({ type: 'FeatureCollection', features: [] });

  init(mapData: Array<IPosition>): void {}

  run(): void {
    // this.clusters.next({ properties: this., assignments: this._trimmedAssignments });
  }

  format(positionData: Array<IPin>) {
    let id = 0;
    const features: GeoJSON.FeatureCollection = { type: 'FeatureCollection', features: [] };

    for (const element of positionData) {
      const formatter: GeoJSON.Feature = {
        type: 'Feature',
        properties: {
          status: element.statusInt === undefined ? Infinity : element.statusInt,
          id: id++,
          icon: element.status,
          lat: element.lat,
          lng: element.lng,
        },
        geometry: {
          type: 'Point',
          coordinates: [element.lng, element.lat],
        },
      };
      features.features.push(formatter);
    }

    this.clusters.next(features);
  }
}
