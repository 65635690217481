<mat-form-field appearance="outline">
  <mat-select [formControl]="formControl" id="select-type" (selectionChange)="notify()">
    <mat-option *ngIf="enableCreation" (click)="createNewClicked()">
      <a id="option-component-type-create" style="color: #ff6400; text-decoration: none">Create component type</a>
    </mat-option>

    <mat-option *ngFor="let option of optionArray; trackBy: identify" id="option-{{ option.name }}"
      value="{{ option.id }}" [ngStyle]="{
        'padding-left': '35px'
      }">
      <span class="sl-componenttype-name">
        {{ option.name }}
      </span>
      <button id="btn-component-type-edit" *ngIf="isEditable" mat-button class="component-edit-button"
        style="float: right; border: none; background-color: transparent; color: #4d93db"
        (click)="editClicked($event, option.id)">
        Edit
      </button>
      <span *ngIf="!isHumidity()" class="sl-radar-distance">
        ({{ option.distanceRangeLow }}-{{ option.distanceRangeHigh }}mm)
      </span>
    </mat-option>
  </mat-select>
</mat-form-field>