<ng-container *ngIf="solutionSpace$ | async as solutionSpace">
  <app-component-detail
    *ngIf="component$ | async as component"
    [solutionSpace]="solutionSpace"
    [component]="component"
    [trackingPoints$]="trackingPoints$"
    [componentActivities$]="componentActivities$"
    [utilizationStatistics$]="utilizationStatistics$"
    [measurements$]="measurements$"
    (updateDateRangeTrackingPoints)="updateTackingPoints($event)"
    (updateDateRangeMeasurements)="updateMeasurements($event)"
  ></app-component-detail>
</ng-container>
