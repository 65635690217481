<h2 mat-dialog-title>
  <svg-icon src="/assets/icons/icon-40-attention.svg"></svg-icon>
  Save changes to component
</h2>

<h3>
  You have made some changes to this component, do you wish to save these
  changes?
</h3>
<p>If you don't save your changes will be lost.</p>
<mat-dialog-actions>
  <button mat-flat-button [ngClass]="'btn-grey'" [mat-dialog-close]="'discard'">
    Discard changes
  </button>
  <button mat-flat-button mat-dialog-close>Cancel</button>
  <button
    mat-flat-button
    [mat-dialog-close]="'save'"
    mat-primary
    color="primary"
  >
    Save
  </button>
</mat-dialog-actions>
