import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Type } from 'class-transformer';
import { ChartColor, ChartDataSets, ChartOptions, ChartPoint } from 'chart.js';
import 'chartjs-plugin-zoom';
import { BaseChartDirective } from 'ng2-charts';
import { formatDate } from '../utils';
export class MetData {
  @Type(() => Date)
  date: Date;
  humidity: number;
  temperature: number;
  dewPoint: number;
  threshold: number;
  schock?: number;
  shockThreshold?: number;
}

@Component({
  selector: 'app-acceleration-chart',
  templateUrl: './acceleration-chart.component.html',
  styleUrls: ['./acceleration-chart.component.scss'],
})
export class accelerationChartComponent implements OnInit, OnDestroy {
  private _data: MetData[] = [];
  @ViewChild(BaseChartDirective, { static: false }) scattedChart: BaseChartDirective;

  accelerationChartLabels: string[] = [];
  accelerationChartData: ChartDataSets[];
  pointBackgroundColor: Array<string> = [];
  pointBorderBackgroundColor: Array<string> = [];

  options: ChartOptions;
  isZoomHidden = true;
  gradient: string | ChartColor = '#4682B4';

  @Input()
  isMobile: boolean;
  @Input() set data(value: MetData[]) {
    if (value) {
      this._data = value.reverse();
      this.pointBackgroundColor = [];
      this.pointBorderBackgroundColor = [];
      this.setLabels();
      this.setDataSet();
      this.setPointBackgroundColor();
    }
  }

  constructor() {}

  setPointBackgroundColor() {
    this._data.forEach((metaData: MetData) => {
      if (metaData.schock! >= metaData.shockThreshold!) {
        this.pointBackgroundColor.push('#DC2D0A');
        this.pointBorderBackgroundColor.push('rgb(220,45,10,0.5)');
      } else {
        this.pointBackgroundColor.push('#4682B4');
        this.pointBorderBackgroundColor.push('rgba(70, 130, 180, 0.5)');
      }
    });
    if (this.scattedChart) this.scattedChart.update();
  }

  setLabels(): void {
    this.accelerationChartLabels = this._data.map((d) => d.date.toISOString());
  }
  setDataSet() {
    const accelerationData: ChartPoint[] = this._data.map((d) => ({ x: d.date, y: d.schock }));
    const accelerationThresholdData: ChartPoint[] =
      this._data.length > 1
        ? this._data.map((d) => ({ x: d.date, y: d.shockThreshold }))
        : this._data.length === 1
        ? [
            { x: this._data[0].date, y: this._data[0].shockThreshold },
            { x: new Date(), y: this._data[0].shockThreshold },
          ]
        : [{ x: new Date() }, { x: new Date() }];
    this.accelerationChartData = [
      {
        fill: false,
        data: accelerationData,
        pointRadius: 6,
        pointHoverRadius: 8,
        hoverBorderColor: 'rgba(70, 130, 180, 0.5)',
        backgroundColor: this.pointBackgroundColor,
        pointHoverBackgroundColor: this.pointBackgroundColor,
        pointBorderColor: this.pointBorderBackgroundColor,

        pointBorderWidth: 10,
        borderColor: 'rgba(70, 130, 180, 1)',
        pointHoverBorderColor: this.pointBackgroundColor,
        pointHoverBorderWidth: 2,
        pointBackgroundColor: this.pointBackgroundColor,
      },

      {
        type: 'line',
        data: accelerationThresholdData,
        label: 'accelerationThreshold',
        showLine: true,
        fill: false,
        //lineTension: 0.1,
        backgroundColor: '#4682B4',
        borderColor: '#4682B4',

        borderDash: [1, 1],
        borderDashOffset: 0.0,
        // pointBorderColor needs to be the same as the borders of the above threshold points
        pointBorderColor: 'rgb(220,45,10,0.5',
        pointBorderWidth: 1,
        pointHoverRadius: 3,
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        // yAxisID: 'yacceleration',
      },
    ];
  }

  ngOnInit(): void {
    this.setOptions();
  }

  resetZoom(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.scattedChart.chart['resetZoom']();
    this.isZoomHidden = true;
  }

  setOptions() {
    this.options = {
      plugins: {
        zoom: {
          zoom: {
            mode: 'xy',
            enabled: true,
            onZoom: () => {
              this.isZoomHidden = false;
            },

            drag: {
              enabled: true,
              backgroundColor: 'rgba(173, 216, 230, 0.2)',
              borderColor: 'rgba(173, 216, 230, 1)',
              borderWidth: 1,
            },
          },
        },
      },

      legend: {
        display: false,
      },
      layout: {
        padding: { top: 12, right: 12, bottom: 12 },
      },
      tooltips: {
        position: 'nearest',
        displayColors: false,
        callbacks: {
          label: (tooltipItem, data) => {
            const index = tooltipItem.datasetIndex;
            if (index !== undefined && data.labels) {
              const datePortion = formatDate(data.labels[tooltipItem.index!] as Date);
              //const date = dataPortion.label[0];
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              return [`${datePortion}`, `NSE: ${tooltipItem.value}`];
            }
            return '';
          },
        },
        custom: function (tooltip) {
          if (tooltip.labelColors && tooltip.labelColors.length > 0)
            tooltip.backgroundColor = tooltip.labelColors[0].borderColor as string;
          tooltip.borderColor = 'white';
          tooltip.borderWidth = 2;
        },
      },
      responsive: true,
      maintainAspectRatio: false,

      scales: {
        xAxes: [
          {
            type: 'time',
            bounds: 'ticks',

            ticks: {
              fontColor: '#000000',
              fontSize: 12,
              maxTicksLimit: 7,
              minRotation: 0,
              maxRotation: 0,
              source: 'auto',
            },
            time: {
              tooltipFormat: 'MMM DD HH:mm:ss',
              displayFormats: {
                millisecond: 'MMM DD HH:mm:ss',
                second: 'MMM DD HH:mm:ss',
                minute: 'MMM DD HH:mm',
                hour: 'MMM DD',
                day: 'MMM DD',
                week: 'MMM DD',
                month: 'MMM',
                quarter: 'MMM',
              },
            },
          },
        ],
        yAxes: [
          {
            id: 'yacceleration',
            scaleLabel: {
              display: true,
              labelString: 'NSE index',
              fontColor: 'black',
              fontSize: 15,
            },
            position: 'left',
            display: true,
            gridLines: { display: false },

            ticks: {
              beginAtZero: true,
              stepSize: 2,
              padding: 20,
              //max: 10,
              fontColor: 'black',
            },
          },
        ],
      },
    };
  }

  ngOnDestroy(): void {
    if (this.scattedChart) this.scattedChart.ngOnDestroy();
  }
}
