/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './services/authentication/authentication.service';
import { PolytechUiService } from './shared/polytech-ui/shared/services/polytech-ui.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Polytech-Spectrum-Frontend';
  constructor(
    private translate: TranslateService,
    private authService: AuthenticationService,
    private polytechUiService: PolytechUiService
  ) {
    this.translate.setDefaultLang('en');

    if (authService.hasValidAccessToken) {
      this.authService.decodeToken();
    }
  }
  ngOnInit(): void {
    this.polytechUiService.svgSprite = '/assets/images/polytech.svg';
  }
}
