import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SolutionSpace } from 'src/app/models/solution';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { ComponentTypesSortOrder, PageRequest } from 'src/app/models/page-request';
import { stripUndefined } from 'src/app/shared/utils';
import { PageResponse } from 'src/app/models/page-response';
import { ComponentType } from 'src/app/models/component-type';

@Injectable({
  providedIn: 'root',
})
export class ComponentTypeService {
  constructor(private http: HttpClient, private userService: UserService) {}

  private get componentTypeUrl(): string {
    return environment.api + `/api/${this.userService.getOrganisationId()}/componenttype`;
  }

  getComponentTypes(
    solutionSpace: SolutionSpace,
    pageRequest?: PageRequest<ComponentTypesSortOrder>,
    isPositioned?: boolean
  ): Observable<PageResponse<ComponentType>> {
    return this.http.get<PageResponse<ComponentType>>(`${this.componentTypeUrl}`, {
      params: stripUndefined({
        ...{ solutionSpace: solutionSpace },
        ...pageRequest,
        ...{ isPositioned: isPositioned },
      }),
    });
  }

  updateComponentType(
    componentTypeId: string,
    options: {
      name: string;
      mindistance?: number;
      maxdistance?: number;
      description?: string;
      humidityThreshold?: number;
      isHumidityWarningActive: boolean;
      shockThreshold?: number;
      isShockWarningActive: boolean;
    }
  ): Observable<null> {
    return this.http.put<null>(
      `${this.componentTypeUrl}?componentTypeId=${componentTypeId}`,
      stripUndefined({
        componentTypeName: options.name,
        distanceRangeLowInMillimeters: options.mindistance,
        distanceRangeHighInMillimeters: options.maxdistance,
        componentTypeDescription: options.description,
        humidityThreshold: options.humidityThreshold,
        isHumidityWarningActive: options.isHumidityWarningActive,
        shockThreshold: options.shockThreshold,
        isShockWarningActive: options.isShockWarningActive,
      })
    );
  }

  deleteComponentType(componentTypeId: string): Observable<null> {
    return this.http.delete<null>(`${this.componentTypeUrl}?componentTypeId=${componentTypeId}`);
  }

  createComponentType(options: {
    solutionSpace: SolutionSpace;
    name: string;
    mindistance?: number;
    maxdistance?: number;
    description?: string;
    humidityThreshold?: number;
    isHumidityWarningActive: boolean;
    shockThreshold?: number;
    isShockWarningActive: boolean;
  }): Observable<null> {
    return this.http.post<null>(
      `${this.componentTypeUrl}`,
      stripUndefined({
        solutionSpace: options.solutionSpace === SolutionSpace.Utilization ? 0 : 1,
        componentTypeName: options.name,
        distanceRangeLowInMillimeters: options.mindistance,
        distanceRangeHighInMillimeters: options.maxdistance,
        componentTypeDescription: options.description,
        humidityThreshold: options.humidityThreshold,
        isHumidityWarningActive: options.isHumidityWarningActive,
        shockThreshold: options.shockThreshold,
        isShockWarningActive: options.isShockWarningActive,
      })
    );
  }
}
