import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PolytechUiDatePickerComponent } from './date-picker/date-picker.component';
import { PolytechUiDateMonthComponent } from './date-picker/date-month/date-month.component';
import { PolytechUiDateDayComponent } from './date-picker/date-day/date-day.component';
import { PolytechUiCardComponent } from './card/card.component';

import { PolytechUiTabBarComponent } from './tab-bar/tab-bar.component';
import { PolytechUiTabLinkComponent } from './tab-bar/link/link.component';

import { PolytechUiLeftBarComponent } from './left-bar/left-bar.component';
import { PolytechUiLiteModule } from './polytech-ui-lite.module';

@NgModule({
  declarations: [
    PolytechUiDatePickerComponent,
    PolytechUiDateMonthComponent,
    PolytechUiDateDayComponent,
    PolytechUiCardComponent,
    PolytechUiTabBarComponent,
    PolytechUiTabLinkComponent,
    PolytechUiLeftBarComponent,
  ],
  imports: [CommonModule, RouterModule, PolytechUiLiteModule],
  exports: [
    PolytechUiDatePickerComponent,
    PolytechUiLiteModule,
    PolytechUiCardComponent,
    PolytechUiLeftBarComponent,
    PolytechUiTabLinkComponent,
    PolytechUiTabBarComponent,
  ],
})
export class PolytechUiModule {}
