<div *ngIf="!project" class="spinner-wrapper">
  <mat-spinner></mat-spinner>
</div>

<div fxLayout="column" fxLayoutGap="16px" class="content">
  <div fxLayout="row" fxLayoutGap="16px" class="page-content">
    <app-info-card
      *ngIf="!!project"
      fxFlex="50%"
      [title]="project.name"
      description="{{ project.description }}"
      [infoMap]="infoMap"
      [columns]="this.solution === 'humidity' ? 2 : 4"
      [solution]="this.solution"
    ></app-info-card>
  </div>
  <div class="project-card info-card">
    <mat-sidenav-container class="full-height br-8 sidenav-container" autosize>
      <mat-sidenav
        mode="side"
        [ngClass]="!isMobile() ? 'sidenav-drawer' : ''"
        #drawer
        opened="true"
        mode="side"
        disableClose="true"
      >
        <div style="padding: 24px" class="flex-col" *ngIf="!isMobile()">
          <span class="auto-margin-top-bottom title">Components</span>

          <div class="form-container" style="display: flex">
            <div *ngIf="solution === 'utilization'" class="flex-child-50">
              <button mat-button [matMenuTriggerFor]="menu">
                <div>
                  <svg-icon class="pr-xm" [src]="displayedCompFilters.icon"></svg-icon>
                  {{ displayedCompFilters.text }}
                  <mat-icon style="padding-left: 12px">keyboard_arrow_down</mat-icon>
                </div>
              </button>
              <mat-menu #menu="matMenu">
                <ng-container *ngFor="let measurement of measurements">
                  <button
                    class="filter-submenu"
                    mat-menu-item
                    [value]="measurement.key"
                    (click)="filterByUsage(measurement)"
                  >
                    <div>
                      <svg-icon class="pr-xm" [src]="measurement.icon"></svg-icon>
                      {{ measurement.value }}
                    </div>
                  </button>
                </ng-container>
              </mat-menu>
            </div>
          </div>
          <ng-container *ngIf="isComponentLoaded">
            <app-table
              [tableCols]="displayedColumns"
              (rowClicked)="onRowClicked($event)"
              [dataSource]="dataSource"
            ></app-table>
            <div style="text-align: center; padding-top: 64px" *ngIf="dataSource.length === 0">
              <h2>No data available</h2>
              <!--TODO fix this section after more inputs-->
              <ng-container *ngIf="false">
                <h4 class="text-empty-label mb-m">Why not check data collected in ...</h4>
                <button mat-flat-button style="background-color: #4682b4; color: white">Last 3 Months</button>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="!isComponentLoaded">
            <h2 class="text-empty-label mt-l">Loading components ...</h2>
          </ng-container>
        </div>
      </mat-sidenav>
      <mat-sidenav-content style="position: relative">
        <ng-template [ngIf]="markerData">
          <app-map-view
            mapId="project-detail"
            [markerData]="markerData"
            [hasRouting]="false"
            [clusterPoints]="clusterPoints"
            [solution]="solution"
          ></app-map-view>
        </ng-template>
        <div class="btn-toggle" *ngIf="!isMobile()" (click)="toggle(drawer)">
          <svg-icon *ngIf="isDrawerOpen" src="/assets/icons/icon-40-collapse.svg" [class]="'icon-mobile'"></svg-icon>
          <svg-icon *ngIf="!isDrawerOpen" src="/assets/icons/icon-40-expand.svg" [class]="'icon-mobile'"></svg-icon>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
