import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectableOption } from '../filter.component';

@Component({
  selector: 'app-filter-project',
  templateUrl: './filter-project.component.html',
  styleUrls: ['./filter-project.component.scss'],
})
export class FilterProjectComponent implements OnInit {
  @Input() projects: SelectableOption[] = [];
  @Output() apply = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() optionChange: EventEmitter<SelectableOption[]> = new EventEmitter<SelectableOption[]>();

  currentSelectedProjects: SelectableOption[] = [];
  filteredProjects: SelectableOption[] = [];

  ngOnInit(): void {
    this.filteredProjects = this.projects;
    this.projects.forEach((project) => {
      if (project.isSelected) {
        this.currentSelectedProjects.push(project);
      }
    });
  }

  filterProjectName(subname: string) {
    if (subname === '' || !subname) this.filteredProjects = this.projects;
    else {
      this.filteredProjects = this.projects.filter((project) => {
        return project.label.toLocaleLowerCase().includes(subname.toLocaleLowerCase());
      });
    }
  }

  onApply() {
    this.apply.emit();
  }

  onBack() {
    this.back.emit();
  }

  onSelectProjects(selectedOptions: SelectableOption[]) {
    this.currentSelectedProjects.forEach((project) => {
      if (!this.filteredProjects.some((filteredProject) => filteredProject.label === project.label)) {
        selectedOptions.push(project);
      }
    });

    this.currentSelectedProjects = selectedOptions;
    this.optionChange.emit(selectedOptions);
  }
}
