<div [style.opacity]="enabled ? 1.0 : 0.5" [style.pointer-events]="enabled ? 'auto' : 'none'">
  <form [formGroup]="preferencesGroup">
    <header>
      <svg-icon src="/assets/images/icon_shockMeasurement.svg" [class]="'icon'"></svg-icon>
      <h3>Measurements</h3>
    </header>
    <mat-slide-toggle
      id="toggle-shock-measurement"
      formControlName="shockMeasurementActive"
      style="font-weight: 600"
      (change)="onShockMeasureChange()"
    >
      <svg-icon src="/assets/images/icon-16-shock.svg"></svg-icon> Shock
    </mat-slide-toggle>
    <div
      [style.opacity]="shockMeasurementActive ? 1.0 : 0.5"
      [style.pointer-events]="shockMeasurementActive ? 'auto' : 'none'"
    >
      <p>Enable shock measurements from the device</p>
      <p class="grey">Changes will be sent to the device next time it communicates</p>
    </div>
    <div class="separator"></div>
    <header>
      <svg-icon src="/assets/images/icon_incidents.svg" [class]="'icon'"></svg-icon>

      <h3>Incidents</h3>
    </header>
    <ng-container *ngIf="showHumidity()">
      <mat-slide-toggle id="toggle-humidity-warning" formControlName="humidityWarning" style="font-weight: 600">
        <svg-icon src="/assets/images/icon-16-humidity.svg"></svg-icon> Humidity
      </mat-slide-toggle>
      <p>Highlight components when humidity is above set threshold</p>
      <div class="form-container">
        <span class="form-label">Humidity</span>
        <div class="form-icon-container">
          <mat-form-field class="select-field inverted" appearance="outline">
            <mat-select formControlName="humidityWarningValue">
              <mat-option value="10">10%</mat-option>
              <mat-option value="20">20%</mat-option>
              <mat-option value="30">30%</mat-option>
              <mat-option value="40">40%</mat-option>
              <mat-option value="50">50%</mat-option>
              <mat-option value="60">60%</mat-option>
              <mat-option value="70">70%</mat-option>
              <mat-option value="80">80%</mat-option>
              <mat-option value="90">90%</mat-option>
            </mat-select>
          </mat-form-field>
          <svg-icon
            src="/assets/images/icon-16-help.svg"
            [class]="'icon-tool-tip'"
            matTooltip="Tooltip for humidity"
          ></svg-icon>
        </div>
      </div>
    </ng-container>
    <div class="separator short" *ngIf="showHumidity()"></div>
    <div>
      <mat-slide-toggle
        id="toggle-shock-warning"
        formControlName="shockWarning"
        style="font-weight: 600"
      >
        <svg-icon src="/assets/images/icon-16-shock.svg"></svg-icon> Shock
      </mat-slide-toggle>
      <p>Highlight components when exposed to a shock above threshold</p>
      <div class="form-container">
        <span class="form-label">G-force</span>
        <div class="form-icon-container">
          <mat-form-field class="select-field inverted" appearance="outline">
            <mat-select formControlName="shockWarningValue" (selectionChange)="onShockValueChange()">
              <mat-option *ngFor="let shockValue of shockValues" value="{{ shockValue }}">{{
                shockValue.toString() + " g"
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <svg-icon
            src="/assets/images/icon-16-help.svg"
            [class]="'icon-tool-tip'"
            matTooltip="Tooltip for shock"
          ></svg-icon>
        </div>
      </div>
    </div>
    <div class="separator"></div>
    <!-- <header>
      <svg-icon
        src="/assets/images/map-pin-alert.svg"
        [class]="'icon'"
      ></svg-icon>
      <h3 [ngClass]="'header'">Alerts</h3>
    </header>
    <mat-slide-toggle formControlName="batteryAlert" style="font-weight: 600">
      <svg-icon
        src="/assets/images/icon-16-battery-black.svg"
        [class]="'icon'"
      ></svg-icon>
      Battery low
    </mat-slide-toggle>
    <p>
      {{ "component.DETAILS.PREFERENCES.BATTERY.SUB-HEADER" | translate }}
    </p>
    <div class="form-container">
      <span [ngClass]="'form-label'">
        {{ "component.DETAILS.PREFERENCES.BATTERY.SLIDER-TEXT" | translate }}
      </span>
      <div class="form-icon-container">
        <mat-form-field appearance="outline">
          <mat-select formControlName="batteryAlertValue">
            <mat-option value="5">5 %</mat-option>
            <mat-option value="10">10 %</mat-option>
            <mat-option value="20">20 %</mat-option>
            <mat-option value="30">30 %</mat-option>
            <mat-option value="40">40 %</mat-option>
            <mat-option value="50">50 %</mat-option>
          </mat-select>
        </mat-form-field>
        <svg-icon
          src="/assets/images/icon-16-help.svg"
          [class]="'icon-tool-tip'"
          matTooltip="Tooltip for battery"
        ></svg-icon>
      </div>
    </div>
    <div class="separator short"></div>
    <div>
      <mat-slide-toggle formControlName="noCommAlert" style="font-weight: 600">
        <svg-icon src="/assets/images/icon-16-no-comm.svg"></svg-icon
        >{{
          "component.DETAILS.PREFERENCES.COMMUNICATION.SLIDER-TEXT" | translate
        }}
      </mat-slide-toggle>
      <p>
        {{
          "component.DETAILS.PREFERENCES.COMMUNICATION.SUB-HEADER" | translate
        }}
      </p>
    </div> -->
  </form>
</div>
