/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { NgClass, NgIf, AsyncPipe } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  standalone: true,
  imports: [
    MatSidenavModule,
    NgClass,
    ExtendedModule,
    RouterLink,
    RouterLinkActive,
    MatTooltipModule,
    NgIf,
    AngularSvgIconModule,
    AsyncPipe,
    TranslateModule,
  ],
})
export class SideMenuComponent implements OnInit {
  canAccessComponent$: Observable<boolean>;
  canAccessProject$: Observable<boolean>;
  canAccessSetting$: Observable<boolean>;

  constructor(private authService: AuthenticationService) {}

  ngOnInit(): void {
    this.canAccessComponent$ = this.authService.getUserAccessPermissions().pipe(
      map((canAccess) => {
        return (
          !!this.authService.hasValidAccessToken && canAccess.includes('web_app') && canAccess.includes('components')
        );
      })
    );
    this.canAccessProject$ = this.authService.getUserAccessPermissions().pipe(
      map((canAccess) => {
        return (
          !!this.authService.hasValidAccessToken && canAccess.includes('web_app') && canAccess.includes('projects')
        );
      })
    );
    this.canAccessSetting$ = this.authService.getUserAccessPermissions().pipe(
      map((canAccess) => {
        return (
          !!this.authService.hasValidAccessToken && canAccess.includes('web_app') && canAccess.includes('settings')
        );
      })
    );
  }

  logout(): void {
    this.authService.logoutCognitoDirect();
  }
}
