import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarTemplateComponent } from '../shared/snack-bar-template/snack-bar-template.component';
import { ResponseObj } from 'src/app/models/errorHandling';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  openSnackBar(
    responseObject: ResponseObj | undefined,
    type: 'ARCHIVE' | 'CREATE' | 'UPDATE' | 'ERROR' | 'ACKNOWLEGDE' | 'LOADING' = 'ERROR',
    duration = 5000,
    Xposition: 'start' | 'center' | 'end' | 'left' | 'right' = 'left',
    Yposition: 'top' | 'bottom' = 'bottom'
  ): void | MatSnackBarRef<any> {
    if (type === 'LOADING') {
      return this.snackBar.openFromComponent(SnackBarTemplateComponent, {
        duration: duration,
        horizontalPosition: Xposition,
        verticalPosition: Yposition,
        panelClass: ['snackbar-background', 'snackbar-size'],
        data: { responseObject, type },
      });
    }
    this.snackBar.openFromComponent(SnackBarTemplateComponent, {
      duration: duration,
      horizontalPosition: Xposition,
      verticalPosition: Yposition,
      panelClass: ['snackbar-background', 'snackbar-size'],
      data: { responseObject, type },
    });
  }

  changeTitle(title: string, snackBarInstance: MatSnackBarRef<any>): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    snackBarInstance.instance.data.responseObject.title = title;
  }
}
