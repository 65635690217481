import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { formatDate } from '../../shared/utils';

export enum ActivityType {
  Created = 1,
  Added = 2,
  Available = 3,
  InUse = 4,
  PreferencesUpdated = 5,
  SettingsUpdated = 6,
  Battery = 7,
  Shock = 8,
  Incidents = 9,
  Acknowledge = 10,
  Reopened = 11,
  archived = 12,
  inService = 13,
  inReturn = 14,
  default,
}

export class ComponentActivity {
  text: string;
  timestamp: Date;
  activity: ActivityType;
}

@Component({
  selector: 'app-recent-history',
  templateUrl: './recent-history.component.html',
  styleUrls: ['./recent-history.component.scss'],
})
export class RecentHistoryComponent implements OnInit {
  private _timeline: ComponentActivity[] | undefined = [];
  @Input() set timeline(time: ComponentActivity[] | undefined) {
    this._timeline = time;
    this.initalizePagination();
  }

  get timeline() {
    return this._timeline;
  }

  displayedTimeLine: ComponentActivity[] | undefined = [];

  @Input() title: string;
  @Input() isHistoryLoaded = false;
  pageSize = 0;

  activityLength = 0;

  constructor() {}

  ngOnInit(): void {
    this.pageSize = 8;
  }

  initalizePagination() {
    this.displayedTimeLine = this._timeline?.slice(0, this.pageSize);
  }
  triggerPageChange(event: PageEvent): void {
    // FE pagination for activity
    const start = this.pageSize * event.pageIndex;
    const end = this.pageSize * (event.pageIndex + 1);
    this.displayedTimeLine = this._timeline?.slice(start, end);
  }
  // "repeater" to make it available in template
  formatDate(date: Date): string {
    return formatDate(date);
  }

  getActivityIcon(activity: ActivityType): string {
    switch (activity) {
      case ActivityType.Added:
      case ActivityType.Created:
        return '/assets/icons/icon-40-activity-log-add.svg';
      case ActivityType.Available:
        return '/assets/icons/icon-40-activity-log-available.svg';
      case ActivityType.InUse:
        return '/assets/icons/icon-40-activity-log-in-use.svg';
      case ActivityType.PreferencesUpdated:
        return '/assets/icons/icon-40-activity-log-preferences-update.svg';
      case ActivityType.SettingsUpdated:
        return '/assets/icons/icon-40-activity-log-settings-update.svg';
      case ActivityType.Battery:
        return '/assets/icons/icon-40-activity-log-low-battery.svg';
      case ActivityType.Shock:
        return '/assets/icons/icon-40-activity-log-shock.svg';
      case ActivityType.Incidents:
        return '/assets/icons/icon-40-activity-log-base.svg';
      case ActivityType.Acknowledge:
        return '/assets/icons/icon-40-activity-log-acknowledged.svg';
      case ActivityType.Reopened:
        return '/assets/icons/icon-40-activity-log-reopened.svg';
      // new caaes
      case ActivityType.archived:
        return '/assets/icons/icon-40-activity-log-archived.svg';
      case ActivityType.inReturn:
        return '/assets/icons/icon-40-activity-log-return.svg';
      case ActivityType.inService:
        return '/assets/icons/icon-40-activity-log-in-service.svg';
      case ActivityType.default:
        return '/assets/icons/icon-40-activity-log-reopened.svg';
    }
  }
}
