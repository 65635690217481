import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, debounceTime, distinctUntilChanged, of, startWith, switchMap } from 'rxjs';
import { LocationInfo, LocationType } from 'src/app/models/locations';
import { ComponentsService } from 'src/app/services/api/components.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-filter-location',
  templateUrl: './filter-location.component.html',
  styleUrls: ['./filter-location.component.scss'],
})
export class FilterLocationComponent implements OnInit {
  @Input() selectedLocations: LocationInfo[] = [];

  @Output() apply = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() optionChange: EventEmitter<LocationInfo[]> = new EventEmitter<LocationInfo[]>();

  locationCtrl = new FormControl('');
  selectableLocations: Observable<LocationInfo[]>;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  typeToIcon = {
    [LocationType.COUNTRY]: 'language',
    [LocationType.REGION]: 'location_on',
    [LocationType.PLACE]: 'location_city',
    [LocationType.POSTCODE]: 'mail_outline',
  };
  typeToStyleClass = {
    [LocationType.COUNTRY]: '',
    [LocationType.REGION]: 'pl-l',
    [LocationType.PLACE]: 'pl-xl',
    [LocationType.POSTCODE]: 'pl-2xl',
  };

  constructor(private componentsService: ComponentsService) {}

  ngOnInit(): void {
    this.selectableLocations = this.locationCtrl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      startWith(null),
      switchMap((locationFilter: string | null) => {
        if (!locationFilter || locationFilter === '') {
          return of([]);
        }
        return this.componentsService.getLocations(locationFilter);
      })
    );
  }

  onApply() {
    this.apply.emit();
  }

  onBack() {
    this.back.emit();
  }

  onOptionChange(selectedLocation: LocationInfo) {
    const isLocationAlreadySelected: boolean = this.selectedLocations.some(
      (locationInfo) => locationInfo.id === selectedLocation.id
    );

    if (!isLocationAlreadySelected) {
      this.selectedLocations.push(selectedLocation);
      this.optionChange.emit(this.selectedLocations);
    }

    this.locationCtrl.setValue('');
  }

  onRemoveLocation(index: number) {
    this.selectedLocations.splice(index, 1);
    this.optionChange.emit(this.selectedLocations);
  }
}
