import { Type } from 'class-transformer';
import { ZXComponent } from './component';

export class zxProjectModel {
  id: string;
  name: string;
  description?: string;
  @Type(() => Date) created: Date;
  archived: boolean;
  componentCount: number;
  availableCount: number;
  inUseCount: number;
  inReturnCount: number;
  inServiceCount: number;
  activeHumidityIncidentCount: number;
  groupName: string;
  groupId: string;
  components: ZXComponent[];

  public constructor(init?: Partial<zxProjectModel>) {
    Object.assign(this, init);
  }
}
