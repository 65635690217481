/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Injectable, OnDestroy } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';

import { UserQuery } from '../api/state/user.query';
import { AuthenticationService } from '../authentication/authentication.service';
import { ResponsiveService } from '../responsive.service';
import { AuthStore } from '../authentication/state/auth.store';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GuardService implements CanActivate, CanActivateChild, OnDestroy {
  private canAccess: string[] = [];
  private userQueryOrgSub: Subscription;

  constructor(
    public router: Router,
    private authStore: AuthStore,
    private userQuery: UserQuery,
    private authService: AuthenticationService,
    private responsiveService: ResponsiveService
  ) {
    this.userQueryOrgSub = this.userQuery.organisation$.subscribe((organisation) => {
      this.canAccess = organisation?.canAccess || [];
    });
  }

  ngOnDestroy() {
    if (this.userQueryOrgSub) {
      this.userQueryOrgSub.unsubscribe();
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    return this.evaluateRoute(route);
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return this.evaluateRoute(route);
  }

  evaluateRoute(route: ActivatedRouteSnapshot): boolean {
    const validAccessToken = this.authService.hasValidAccessToken;
    if (!validAccessToken) {
      const redirectPath = window.location.pathname + window.location.search;

      // NOTE: Do not want to redirect back to callback page, since that will result
      // in infinite redirects as that page is what is doing the redirecting.
      if (window.location.pathname !== '/callback') {
        this.authStore.update(() => ({
          redirectPath: redirectPath,
        }));
      }

      this.authService.loadDiscoveryDocumentAndLogin();
      return false;
    }
    if (
      this.responsiveService.isMobile() &&
      (route.url.join('/') === 'utilization' || route.url.join('/') === 'humidity')
    ) {
      void this.router.navigateByUrl('components');
    }

    if (route.url.join('/') === 'projects' && this.canAccess.includes('web_app')) {
      if (this.canAccess.includes('utilization')) {
        void this.router.navigateByUrl('projects/utilization');
      } else {
        void this.router.navigateByUrl('projects/humidity');
      }
    }

    if (route.url.join('/') === 'components' && this.canAccess.includes('web_app')) {
      if (this.canAccess.includes('utilization')) {
        void this.router.navigateByUrl('components/utilization');
      } else {
        void this.router.navigateByUrl('components/humidity');
      }
    }

    const requiredAccess = String(route.data.requiredAccess).toLowerCase();
    if (this.canAccess?.includes(requiredAccess)) {
      return !!this.authService.accessToken;
    }

    return true;
  }
}
