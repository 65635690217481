import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-incident-acknowledge-multiple',
  templateUrl: './incident-acknowledge-multiple.component.html',
  styleUrls: ['./incident-acknowledge-multiple.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IncidentAcknowledgeMultipleComponent implements OnInit {
  count: number;
  commentControl: UntypedFormControl = new UntypedFormControl();
  commentLength = 0;
  get maxCommentLength(): number {
    return 128;
  }
  dialogState: {
    comment: string;
  } = {
    comment: '',
  };
  constructor(
    private dialogRef: MatDialogRef<IncidentAcknowledgeMultipleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { counts: number }
  ) {}

  ngOnInit(): void {
    if (this.data.counts) {
      this.count = this.data.counts;
    }
  }

  onCommentChange(): void {
    const comment = this.commentControl.value as string;
    if (comment.length > this.maxCommentLength) {
      const clippedComment = comment.substring(0, this.maxCommentLength);
      this.commentControl.setValue(clippedComment);
      this.commentLength = clippedComment.length;
      this.dialogState.comment = clippedComment;
    } else {
      this.commentLength = comment.length;
      this.dialogState.comment = comment;
    }
  }
}
