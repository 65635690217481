<mat-card>
  <h2>Component preferences</h2>
  <mat-slide-toggle
    [formControl]="projectPreferencesControl"
    ngDefaultControl
    style="font-weight: 600"
  >
    <p>
      {{
        "component.DETAILS.PREFERENCES.PROJECT-PREFERENCES.SLIDER-TEXT"
          | translate
      }}
    </p>
  </mat-slide-toggle>
  <div style="font-weight: 500">
    {{
      "component.DETAILS.PREFERENCES.PROJECT-PREFERENCES.SUB-HEADER" | translate
    }}
  </div>
</mat-card>

<!-- <app-zx-component-preferences [enabled]="projectPreferencesControl.value ? false : true"></app-zx-component-preferences> -->
<mat-card>
  <app-preferences
    [enabled]="projectPreferencesControl.value ? false : true"
    [solution]="productType"
  ></app-preferences>
</mat-card>
