/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, Observable, Subject, Subscription, switchMap, take, tap } from 'rxjs';
import { ComponentState, ZXComponent } from 'src/app/models/component';
import { ComponentType } from 'src/app/models/component-type';
import {
  BackendCreateComponent,
  BackendUpdateComponent,
  ComponentsService,
} from 'src/app/services/api/components.service';
import { Group, UserService } from 'src/app/services/api/user.service';
import { SolutionSpace } from '../../models/solution';
import { DevicesService } from '../../services/api/devices.service';
import { ArchiveDialogComponent } from './archive-dialog/archive-dialog.component';
import { CreateDialogComponent } from './create-dialog/create-dialog.component';
import { LeaveDialogComponent } from './leave-dialog/leave-dialog.component';
import { ComponentTypeDiaglog, TypeDialogComponent } from './type-dialog/type-dialog.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ResponseHandlerService } from 'src/app/services/responseHandler.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseObj } from 'src/app/models/errorHandling';
import { UserQuery } from 'src/app/services/api/state/user.query';
import { DevicesSortOrder, SortDirection } from 'src/app/models/page-request';
import { ComponentTypeService } from 'src/app/services/api/componentType.service';

class ComponentForm {
  public solution: string | undefined;
  public solutionChange = new EventEmitter<string>();
  updateSolution(solutionControl: UntypedFormControl): void {
    if (typeof solutionControl.value == 'string') {
      this.solution = solutionControl.value;
      this.solutionChange.emit(this.solution);
    } else {
      this.solution = undefined;
    }
  }
  get solutionValue(): SolutionSpace {
    return this.solution === 'humidity' ? SolutionSpace.Humidity : SolutionSpace.Utilization;
  }

  public type: ComponentType | undefined;
  updateType(newType: ComponentType): void {
    this.type = newType;
  }
  generateMacAdress(adress: string): string {
    return adress.toUpperCase().replace(/:/g, '-');
  }

  public name: string;
  updateName(newName: string): void {
    this.name = newName;
  }

  public group: string | undefined;
  updateGroup(groupControl: UntypedFormControl): void {
    this.group = typeof groupControl.value == 'string' ? groupControl.value : undefined;
  }

  public devices: { name: string; dbId: string }[] = [];
  deviceAdded(device: { name: string; dbId: string }): boolean {
    return !!this.devices.find((d) => d.dbId === device.dbId);
  }

  reset(): void {
    this.type = undefined;
    this.group = undefined;
    this.devices = [];
  }

  valid(): boolean {
    return !!(this.solution && this.type && this.name && this.group && this.devices.length > 0);
  }

  get values(): {
    name: string;
    groupId: string | undefined;
    type: ComponentType | undefined;
  } {
    return {
      name: this.name,
      groupId: this.group,
      type: this.type,
    };
  }

  asBackendCreate(): BackendCreateComponent {
    const backendComponent = {
      solution: this.solution,
      group: this.group,
      name: this.name,
      type: this.type?.id,
      devices: this.devices.map((d) => ({
        id: '00000000-0000-0000-0000-000000000000',
        identifier: this.generateMacAdress(d.name),
      })),
    };
    return backendComponent;
  }

  asBackendUpdate(): BackendUpdateComponent {
    return {
      name: this.name,
      type: this.type?.id,
    };
  }
}

@Component({
  selector: 'app-zx-component-form',
  templateUrl: './zx-component-form.component.html',
  styleUrls: ['./zx-component-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ZxComponentFormComponent implements OnInit, OnDestroy {
  @Input()
  component: ZXComponent | undefined;

  ComponentStateEnum: typeof ComponentState = ComponentState;

  form: ComponentForm = new ComponentForm();
  origin: string;
  doubleSolutionAccess = false;
  solution: SolutionSpace;
  searchPlaceHolder = 'Enter ID found on logistics hub label';
  types: ComponentType[] = [];
  groups: Group[] = [];
  devices: { name: string; dbId: string }[] = [];
  creatingComponent = false;

  private paramSubscription: Subscription;
  archivingComponent = false;
  waitingForNameTypeUpdate = false;
  waitingForDeviceSubstitute = false;
  initialDevice: { name: string; dbId: string };
  deviceShoosenFromUrl = false;
  initialName: string;
  initialType: string;
  updatingComponent = false;
  search: string;
  initialGroup: string;

  private orgSubscription: Subscription;

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private userService: UserService,
    private userQuery: UserQuery,
    private componentsService: ComponentsService,
    private devicesService: DevicesService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private location: Location,
    private snackbarService: SnackbarService,
    private responseHandler: ResponseHandlerService,
    private componentTypeService: ComponentTypeService
  ) {
    this.matIconRegistry.addSvgIcon(
      `poly-chevron-right`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-24-chevron-in.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `poly-chevron-left`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/icon-24-chevron-collapse.svg')
    );
    this.form.solutionChange.subscribe(this.onSolutionControlChange.bind(this));
  }

  ngOnInit(): void {
    let canAccess: string[] = [];

    this.orgSubscription = this.userQuery.organisation$.subscribe((organisation) => {
      canAccess = organisation?.canAccess || [];
      // check for double solution humid+util:
      if (
        this.component === undefined && // not edit
        canAccess.includes('utilization') &&
        canAccess.includes('humidity')
      ) {
        this.doubleSolutionAccess = true;
      }
    });

    this.paramSubscription = this.route.queryParams.subscribe((el) => {
      this.origin = typeof el.origin == 'string' ? el.origin : '';
      let deviceId = typeof el.id == 'string' ? el.id : '';

      if (typeof el.deviceId == 'string') {
        // alternative query-parameter used specifically for the labels
        // on the boxes with through a QR code.
        const id = el.deviceId;
        this.deviceShoosenFromUrl = true;
        const mac: string[] = [];
        let idx = 0;
        while (idx < id.length) {
          const sub = id.substring(idx, idx + 2);
          mac.push(sub);
          idx += 2;
        }

        deviceId = mac.join(':');
      }

      if (Object.prototype.hasOwnProperty.call(el, 'solution')) {
        this.solution = el.solution === 'humidity' ? SolutionSpace.Humidity : SolutionSpace.Utilization;
      } else {
        if (this.doubleSolutionAccess) {
          // set the utlization solution bydefault
          this.solution = SolutionSpace.Utilization;
        } else {
          if (canAccess?.includes('utilization')) {
            this.solution = SolutionSpace.Utilization;
          } else {
            this.solution = SolutionSpace.Humidity;
          }
        }
      }

      this.form.solution = this.solution;
      this.fetchGroups();
      this.fetchTypes(this.solution);

      if (this.component) {
        this.form.name = this.component.name;
        this.form.type = this.component.type;
        this.form.group = this.component.groupId;
        this.form.devices = [
          {
            name: this.component.deviceIdentifier,
            dbId: this.component.deviceId,
            // batteryLevel: this.component.batteryLevel!,
          },
        ];

        this.initialDevice = Object.assign({}, this.form.devices[0]);
        this.initialGroup = this.form.group!;
        this.initialName = this.form.name;
        //this.initialType = this.component.type; //
        this.initialType = this.form.type.id;
      } else {
        if (this.deviceShoosenFromUrl) {
          // set the search on the deviceId
          this.initialDevice = { name: deviceId, dbId: '00000000-0000-0000-0000-000000000000' };
          this.form.devices.push(this.initialDevice);
          this.devices.push(this.initialDevice);
          this.searchPlaceHolder = deviceId;
        } else {
          const filteredGroupIds = this.groups.map((gp) => gp.id);
          this.devicesService
            .getAllDevices(
              {
                groupIds: filteredGroupIds,
                solutionSpace:
                  this.form.solution === SolutionSpace.Humidity ? SolutionSpace.Humidity : SolutionSpace.Utilization,
                isAttachedToComponent: false,
                search: this.search,
              },
              {
                pageNumber: 1,
                pageSize: 5,
                sortDirection: SortDirection.DESC,
                sortOrder: DevicesSortOrder.LAST_COMMUNICATED,
              }
            )
            .subscribe((devices) => {
              if (deviceId.length > 0) {
                this.form.devices = devices.items
                  .map((d) => ({
                    dbId: d.id,
                    name: this.mapBackendDevice(d.identifier),
                    // batteryLevel: d.batteryLevel,
                  }))
                  .filter((d) => d.name === deviceId);
                this.initialDevice = Object.assign({}, this.form.devices[0]);
              }
            });
        }
      }
    });
    //this.openCreateDialog();
  }

  ngOnDestroy(): void {
    this.orgSubscription && this.orgSubscription.unsubscribe();
    this.paramSubscription && this.paramSubscription.unsubscribe();
  }

  get groupValues(): { value: string; label: string }[] {
    return this.groups.map((g) => ({ value: g.id, label: g.name }));
  }

  get defaultGroup(): string | undefined {
    return this.form.group;
  }

  // used for create component flow:
  formValid(): boolean {
    return this.form.valid();
  }

  // pristinity when editing component:
  devicePristine(): boolean {
    return this.form.devices.length > 0 && this.initialDevice.dbId === this.form.devices[0].dbId;
  }

  namePristine(): boolean {
    return this.initialName === this.form.name || this.form.name.length === 0;
  }

  typePristine(): boolean {
    return this.initialType === this.form.type?.id;
  }

  groupPristine(): boolean {
    return this.initialGroup === this.form.group;
  }

  saveButtonDisabled(): boolean {
    return (this.namePristine() && this.devicePristine() && this.typePristine()) || this.form.devices.length === 0;
  }

  updateComponent(compnentId: string): void {
    // getComponent
    this.componentsService.getComponent(this.solution, compnentId).subscribe(
      (response) => {
        this.component = response;
      },
      (error: HttpErrorResponse) => {
        this.responseHandler.error(error);
      }
    );
  }
  clickNav(): void {
    if (!this.namePristine() || !this.devicePristine() || !this.typePristine()) {
      this.openLeaveDialog();
    } else {
      this.location.back();
      // void this.router.navigate(['/components']);
    }
  }

  resetCreationPath(): void {
    void this.router.navigate(['/components/create']).then(() => {
      window.location.reload();
    });
  }

  mapBackendDevice(deviceName: string): string {
    return deviceName.toUpperCase().replace(/-/g, ':');
  }

  updateDeviceSearch(searchText?: string): void {
    searchText = searchText || this.search;
    if (!searchText) return;

    this.search = searchText.toUpperCase().replace(/:/g, '-');
    let augmentedSearch = '';
    let index = 0;
    for (const c of this.search) {
      if (index === 2 || (index > 2 && augmentedSearch[augmentedSearch.length - 3] === '-')) {
        if (c >= '0' && c <= 'F') augmentedSearch += '-';
      }
      augmentedSearch += c;
      index += 1;
    }

    if (this.search.length > 0) {
      const solution = this.solutionValue;
      const filteredGroupIds = this.groups.map((gp) => gp.id);
      this.devicesService
        .getAllDevices(
          {
            groupIds: filteredGroupIds,
            solutionSpace: solution === SolutionSpace.Humidity ? SolutionSpace.Humidity : SolutionSpace.Utilization,
            isAttachedToComponent: false,
            search: this.search,
          },
          {
            pageNumber: 1,
            pageSize: 5,
            sortDirection: SortDirection.DESC,
            sortOrder: DevicesSortOrder.LAST_COMMUNICATED,
          }
        )
        .subscribe((result) => {
          this.devices = result.items.map((d) => ({
            dbId: d.id,
            name: this.mapBackendDevice(d.identifier),
            // batteryLevel: d.batteryLevel,
          }));
        });
    } else {
      this.devices = [];
    }
  }

  onSolutionControlChange(value: string): void {
    //const value = this.formGroup.controls['solution'].value;
    const solutionSpace = value === 'humidity' ? SolutionSpace.Humidity : SolutionSpace.Utilization;
    this.form.reset();
    this.fetchTypes(solutionSpace);
    this.fetchGroups();
    this.devices = [];
  }

  fetchGroups(): void {
    this.userService.getGroupsForComponentCreation().subscribe((groups) => {
      this.groups = groups;
      if (this.groups.length === 1) {
        this.form.group = groups[0].id;
      }
    });
  }

  fetchTypes(solution: SolutionSpace): void {
    this.componentTypeService.getComponentTypes(solution, { pageSize: 10000 }).subscribe({
      next: (types) => {
        this.types = types.items;
        this.form.type = this.types.find((t) => t.id === this.component?.type.id);
      },
    });
  }

  addDevice(event: MouseEvent): void {
    const parent = (<HTMLElement>event.target).parentElement;
    let deviceId = parent?.getAttribute('data-id');
    if (deviceId === null) {
      deviceId = (<HTMLElement>event.target).getAttribute('data-id');
    }
    if (deviceId !== null) {
      const device = this.devices.find((d) => d.dbId === deviceId);
      if (device) {
        this.form.devices.push(device);
      }
    }
  }

  removeDevice(event: MouseEvent): void {
    const parent = (<HTMLElement>event.target).parentElement;
    let deviceId = parent?.getAttribute('data-id');
    if (deviceId === null) {
      deviceId = (<HTMLElement>event.target).getAttribute('data-id');
    }
    if (deviceId !== null) {
      this.form.devices = this.form.devices.filter((d) => d.dbId !== deviceId);
    }
  }

  addedDevices(): { name: string; dbId: string }[] {
    return this.form.devices;
  }

  onSubmit(): void {
    this.creatingComponent = true;
    this.componentsService.createComponent(this.form.asBackendCreate()).subscribe(
      (res) => {
        this.creatingComponent = false;
        this.component = new ZXComponent({
          id: res.id,
          name: this.form.name,
          type: this.form.type,
          groupId: this.form.group,
        });
        this.openCreateDialog();
        const obj: ResponseObj = {
          title: 'Component created',
          message: `${this.component.name} can be found on the component list`,
        };
        this.snackbarService.openSnackBar(obj, 'CREATE');
        //}
      },
      (error: HttpErrorResponse) => {
        this.responseHandler.error(error);
        this.creatingComponent = false;
      }
    );
  }

  onUpdate(): void {
    if (this.updatingComponent) return;
    this.updatingComponent = true;
    if (!this.namePristine() || !this.typePristine()) {
      this.waitingForNameTypeUpdate = true;
      this.componentsService.updateComponent(this.component!.id, this.form.asBackendUpdate()).subscribe(
        () => {
          this.waitingForNameTypeUpdate = false;
          this.onUpdateComplete();
          const obj: ResponseObj = {
            title: 'Component updated',
            message: `${this.component!.name} has successfully being updated`,
          };
          this.snackbarService.openSnackBar(obj, 'UPDATE');
          this.updateComponent(this.component!.id);
        },
        (error: HttpErrorResponse) => this.responseHandler.error(error)
      );
    }
    if (this.form.devices.length && this.form.devices[0].dbId !== this.component!.deviceId) {
      this.waitingForDeviceSubstitute = true;
      this.componentsService.substituteDevice(this.component!.id, this.form.devices[0].dbId).subscribe(
        () => {
          this.waitingForDeviceSubstitute = false;
          this.onUpdateComplete();
        },
        (error: HttpErrorResponse) => this.responseHandler.error(error)
      );
    }
  }

  onUpdateComplete(): void {
    if (!this.waitingForNameTypeUpdate && !this.waitingForNameTypeUpdate) {
      window.location.reload();
    }
  }

  get solutionValue(): SolutionSpace {
    if (this.doubleSolutionAccess && this.form.solution) {
      return this.form.solutionValue;
    } else {
      return this.solution;
    }
  }

  get isHumidity(): boolean {
    return this.solutionValue === SolutionSpace.Humidity;
  }

  onCancel(): void {
    void this.router.navigate([this.origin]);
  }

  openCreateDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    // dialogConfig.minHeight = '18vh';
    dialogConfig.minWidth = '500px';
    dialogConfig.maxWidth = '216px';

    dialogConfig.data = {
      componentName: this.component?.name,
    };
    const dialogRef = this.dialog.open(CreateDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((buttonPressed: string) => {
      const origin = this.origin.split('/')[1];
      const url = `/components/edit?solution=${this.solution}&id=${this.component!.id}&origin=${origin}`;
      switch (buttonPressed) {
        case 'done':
          {
            const obj: ResponseObj = {
              title: ' Component created',
              message: `${this.form.name} can be found on the component list`,
            };
            this.snackbarService.openSnackBar(obj, 'CREATE');
            void this.router.navigate([this.origin]);
          }

          break;
        case 'another':
          this.reload();
          break;
        case 'configure':
          void this.router.navigateByUrl(url);
          break;
      }
    });
  }
  reload(): void {
    window.location.reload();
  }
  openArchiveDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '400px';
    dialogConfig.maxWidth = '216px';
    const archiveSubject: Subject<string> = new Subject();
    dialogConfig.data = {
      componentName: this.component?.name,
      archiveSubject,
    };
    archiveSubject.subscribe(() => {
      this.archivingComponent = true;
      this.componentsService.archiveComponent(this.component!.id).subscribe(
        () => {
          this.archivingComponent = false;
          const obj: ResponseObj = {
            title: 'Component archived',
            message: `Component ${this.component!.name} is now archived`,
          };
          this.snackbarService.openSnackBar(obj, 'ARCHIVE');
          this.dialog.closeAll();
          void this.router.navigate(['/components']);
        },
        (error: HttpErrorResponse) => this.responseHandler.error(error)
      );
      archiveSubject.unsubscribe();
    });
    this.dialog.open(ArchiveDialogComponent, dialogConfig);
  }

  openLeaveDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    // dialogConfig.minHeight = '18vh';
    dialogConfig.minWidth = '400px';
    dialogConfig.maxWidth = '216px';
    const dialogRef = this.dialog.open(LeaveDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((buttonPressed: string) => {
      if (buttonPressed === 'leave') {
        void this.router.navigate([this.origin]);
      }
    });
  }

  editComponentType(componentTypeId: string): void {
    const dialogConfig = new MatDialogConfig<ComponentTypeDiaglog>();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '45vw';
    dialogConfig.maxHeight = '85vh';
    const type = this.types.find((t) => t.id == componentTypeId);
    dialogConfig.data = {
      solution: this.solutionValue,
      isEdit: true,
      name: type!.name,
      distanceRangeLow: type!.distanceRangeLow,
      distanceRangeHigh: type!.distanceRangeHigh,
      description: type!.description,
      humidityThreshold: type!.humidityThreshold < 0 ? undefined : type!.humidityThreshold,
      isHumidityWarningActive: type!.isHumidityWarningActive,
      shockThreshold: type!.shockThreshold < 0 ? undefined : type!.shockThreshold,
      isShockWarningActive: type!.isShockWarningActive,
    };

    const afterClosed$ = this.dialog.open(TypeDialogComponent, dialogConfig).afterClosed();

    const updateComponentType$ = afterClosed$.pipe(
      filter((cmd) => cmd === 'update'),
      switchMap(() =>
        this.componentTypeService.updateComponentType(componentTypeId, {
          name: dialogConfig.data!.name!,
          mindistance: dialogConfig.data!.distanceRangeLow,
          maxdistance: dialogConfig.data!.distanceRangeHigh,
          description: dialogConfig.data!.description,
          humidityThreshold: dialogConfig.data!.humidityThreshold,
          isHumidityWarningActive: dialogConfig.data!.isHumidityWarningActive,
          shockThreshold: dialogConfig.data!.shockThreshold,
          isShockWarningActive: dialogConfig.data!.isShockWarningActive,
        })
      ),
      take(1)
    );

    const deleteComponentType$ = afterClosed$.pipe(
      filter((cmd) => cmd === 'delete'),
      switchMap((_) => this.componentTypeService.deleteComponentType(componentTypeId)),
      take(1)
    );

    updateComponentType$.subscribe({
      next: () => this.fetchTypes(this.solutionValue),
      error: (error: HttpErrorResponse) => this.responseHandler.error(error),
    });

    deleteComponentType$.subscribe({
      next: () => this.fetchTypes(this.solutionValue),
      error: (error: HttpErrorResponse) => this.responseHandler.error(error),
    });
  }

  createComponentType(): void {
    const dialogConfig = new MatDialogConfig<ComponentTypeDiaglog>();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '45vw';
    dialogConfig.maxWidth = '85vh';
    dialogConfig.data = {
      solution: this.solutionValue,
      isEdit: false,
      name: undefined,
      distanceRangeLow: undefined,
      distanceRangeHigh: undefined,
      description: undefined,
      humidityThreshold: undefined,
      isHumidityWarningActive: false,
      shockThreshold: undefined,
      isShockWarningActive: false,
    };

    this.dialog
      .open(TypeDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        filter((cmd) => cmd === 'create'),
        switchMap((_) =>
          this.componentTypeService.createComponentType({
            solutionSpace: this.solutionValue,
            name: dialogConfig.data!.name!,
            mindistance: dialogConfig.data!.distanceRangeLow,
            maxdistance: dialogConfig.data!.distanceRangeHigh,
            description: dialogConfig.data!.description,
            humidityThreshold: dialogConfig.data!.humidityThreshold,
            isHumidityWarningActive: dialogConfig.data!.isHumidityWarningActive,
            shockThreshold: dialogConfig.data!.shockThreshold,
            isShockWarningActive: dialogConfig.data!.isShockWarningActive,
          })
        ),
        take(1)
      )
      .subscribe({
        next: () => {
          const obj: ResponseObj = { title: 'Component type created', message: 'Component type create successfully' };
          this.snackbarService.openSnackBar(obj, 'CREATE');
          this.dialog.closeAll();
          this.fetchTypes(this.solutionValue);
        },
        error: (error: HttpErrorResponse) => {
          this.responseHandler.error(error);
          this.dialog.closeAll();
        },
      });
  }
}
