import { Component, ElementRef, ViewChild, Input } from '@angular/core';

@Component({
  selector: 'app-ui-click-ripple',
  templateUrl: './click-ripple.component.html',
  styleUrls: ['./click-ripple.component.scss'],
})
export class PolytechUiClickRippleComponent {
  @ViewChild('rippleRef', { read: ElementRef }) rippleRef: ElementRef;
  @Input() color = 'light';

  private _button: HTMLElement;
  public animateClass = '';

  constructor() {}

  animateRipple(event: AnimationEvent) {
    this.animateClass = '';
    this._button?.classList.remove('click');
  }

  createRipple(event: MouseEvent) {
    const button: HTMLElement = <HTMLElement>event.currentTarget;
    const circle: HTMLElement = <HTMLElement>this.rippleRef.nativeElement;
    const diameter = Math.min(100, Math.max(button.clientWidth, button.clientHeight));
    const radius = diameter / 2;

    const eleRect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    const targetRect = (event.target as HTMLElement).getBoundingClientRect();

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.offsetX - radius + targetRect.left - eleRect.left}px`;
    circle.style.top = `${event.offsetY - radius + targetRect.top - eleRect.top}px`;

    this.animateClass = 'animate';

    this._button = button;
    this._button.classList.add('click');
  }
}
