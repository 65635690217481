import { Store, StoreConfig } from '@datorama/akita';
import { Organisation } from 'src/app/models/organisation';

export interface UserState {
  organisation?: Organisation;
}

export function createInitialState(): UserState {
  return {
    organisation: undefined,
  };
}

@StoreConfig({ name: 'user' })
export class UserStore extends Store<UserState> {
  constructor() {
    super(createInitialState());
  }
}
