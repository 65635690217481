/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { SolutionSpace } from 'src/app/models/solution';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-type-dialog',
  templateUrl: './type-dialog.component.html',
  styleUrls: ['./type-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TypeDialogComponent implements OnInit {
  @Input() isEdit = false;
  createClicked = false;
  deleteClicked = false;
  formGroup = new UntypedFormGroup({
    name: new UntypedFormControl(),
    description: new UntypedFormControl(),
    mindistance: new UntypedFormControl(),
    maxdistance: new UntypedFormControl(),
  });
  min = 130;
  max = 800;
  interval = 10;
  constructor(
    public responsiveService: ResponsiveService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      solution: SolutionSpace;
      isEdit: boolean;
      name: string;
      description?: string;
      mindistance: number;
      maxdistance: number;
      doneSubject: Subject<string>;
    }
  ) {}

  get minValues(): number[] {
    const v = [];
    for (let i = this.min; i <= this.max - this.interval; i += this.interval) {
      v.push(i);
    }
    return v;
  }

  get maxValues(): number[] {
    const v = [];
    for (let i = this.min + this.interval; i <= this.max; i += this.interval) {
      v.push(i);
    }
    return v;
  }

  isMobile(): boolean {
    return this.responsiveService.isMobile();
  }

  ngOnInit(): void {
    this.formGroup.get('name')?.setValue(this.data.name);
    if (this.data.mindistance) {
      this.formGroup.get('mindistance')?.setValue(this.data.mindistance.toString());
    }
    if (this.data.maxdistance) {
      this.formGroup.get('maxdistance')?.setValue(this.data.maxdistance.toString());
    }
    if (this.data.description) {
      this.formGroup.get('description')?.setValue(this.data.description.toString());
    }
  }

  invalidForm(): boolean {
    //return this.formGroup.invalid;
    // check the validity for the name input and maxdistance , mindistance : required attributes
    let invalid = this.formGroup.controls['name'].invalid;
    if (!this.isHumidity) {
      invalid =
        invalid || this.formGroup.controls['maxdistance'].invalid || this.formGroup.controls['mindistance'].invalid;
    }
    return invalid;
  }

  get minVal(): number {
    return parseInt(this.formGroup.get('mindistance')?.value as string, 10);
  }

  get maxVal(): number {
    return parseInt(this.formGroup.get('maxdistance')?.value as string, 10);
  }

  minChange(): void {
    const minIndex = this.minValues.indexOf(this.minVal);
    const maxIndex = this.maxValues.indexOf(this.maxVal);
    if (isNaN(this.maxVal) || minIndex > maxIndex) {
      this.formGroup.get('maxdistance')?.setValue(this.maxValues[minIndex].toString());
    }
  }

  maxChange(): void {
    const minIndex = this.minValues.indexOf(this.minVal);
    const maxIndex = this.maxValues.indexOf(this.maxVal);
    if (isNaN(this.minVal) || minIndex > maxIndex) {
      this.formGroup.get('mindistance')?.setValue(this.minValues[maxIndex].toString());
    }
  }

  onClick(): void {
    if (this.createClicked) return;
    this.createClicked = true;
    this.data.name = this.formGroup.get('name')?.value as string;
    this.data.mindistance = this.minVal;
    this.data.maxdistance = this.maxVal;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    if (this.formGroup.get('description')?.value !== '') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      this.data.description = this.formGroup.get('description')?.value as string;
    }
    // send a message to the subcribed component
    // TODO reimplement the subcription mechanism when refactoring component edit/create LOGI-1182
    this.data.doneSubject.next('pressed');
  }

  deleteDisabled(): boolean {
    return false;
  }

  get isHumidity(): boolean {
    return this.data.solution === SolutionSpace.Humidity;
  }
}
