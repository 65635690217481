import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SelectableOption } from '../filter.component';
import { PolytechUiDatePickerComponent } from 'src/app/shared/polytech-ui/date-picker/date-picker.component';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-filter-last-communicated',
  templateUrl: './filter-last-communicated.component.html',
})
export class FilterLastCommunicatedComponent {
  @ViewChild('datePickerRef') datePickerRef: PolytechUiDatePickerComponent;

  @Input() utilizationOptions: SelectableOption[] = [];

  @Output() apply = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() optionChange: EventEmitter<{ start: number; end: number }> = new EventEmitter<{
    start: number;
    end: number;
  }>();

  onApply() {
    this.apply.emit();
  }

  onBack() {
    this.back.emit();
  }

  onOptionChange(event: { start: number; end: number }) {
    this.optionChange.emit(event);
  }

  selectAllData(): void {
    const startDate: DateTime = DateTime.fromISO('2020-01-01', { setZone: true, zone: 'Africa/Abidjan' });

    this.datePickerRef.setStartDate(startDate.toJSDate());
    this.datePickerRef.resetCalendars();
  }
}
