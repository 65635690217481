<div *ngIf="showSum" class="result-count-label">{{ "SHARED.TABLE.RESULTS" | translate }}: {{ totalItems }}</div>

<table
  style="width: 100% !important"
  mat-table
  [dataSource]="_dataSource"
  class="table"
  matSort
  matSortDisableClear
  (matSortChange)="announceSortChange($event)"
>
  <ng-container *ngIf="bulkEnabled" matColumnDef="select" [sticky]="true">
    <th mat-header-cell *matHeaderCellDef class="checkbox">
      <mat-checkbox
        id="check-box-all"
        (click)="$event ? masterToggle() : null"
        [checked]="isAllSelected"
        [indeterminate]="!isAllSelected && isAnySelected"
      >
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row; let i = index" class="checkbox">
      <mat-checkbox
        id="{{ row['componentId'] ? 'check-box-' + row.componentId.value : null }}"
        (click)="$event ? toggle(i) : null"
        [checked]="this.selectedItems.has(i)"
      >
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container *ngFor="let col of tableColumns; let i = index" [matColumnDef]="col.key" [sticky]="col.isSticky">
    <th
      mat-header-cell
      [mat-sort-header]="col.isSortable ? col.key : ''"
      [disabled]="!col.isSortable"
      *matHeaderCellDef
      [ngClass]="col.key"
    >
      {{ col.name }}
    </th>
    <td
      [attr.data-column]="col.key"
      mat-cell
      [ngClass]="{ 'cell-min-width': !col.indicatorIcon }"
      *matCellDef="let element; let j = index"
      (click)="cellClicked(element, j)"
    >
      <div
        class="cell-container"
        [ngClass]="element[col.key]?.linkCallback || element[col.key]?.menus ? 'float-right' : ''"
      >
        <svg-icon
          src="{{ element[col.key]?.iconLeading }}"
          [svgStyle]="{ 'width.px': 14, 'height.px': 14 }"
          [class.icon-leading]="true"
          *ngIf="element[col.key]?.iconLeading"
          [matTooltip]="element[col.key]?.tooltipText !== undefined ? element[col.key]?.tooltipText : null"
        ></svg-icon>
        <ng-container *ngIf="!element[col.key]?.routerLink; else anchor">
          <span
            class="span-styling"
            *ngIf="!element[col.key]?.menus"
            [matTooltip]="element[col.key]?.value | formatCell"
          >
            <ng-container *ngIf="!element[col.key]?.linkCallback; else callback">
              <ng-container *ngIf="!element[col.key]?.value2; else greycell">
                {{ element[col.key]?.value | formatCell }}
              </ng-container>
              <ng-template #greycell>
                {{ element[col.key]?.value | formatCell }}
                <span class="concrete-grey span-styling">{{ element[col.key]?.value2 | formatCell }}</span>
              </ng-template>
            </ng-container>
            <ng-template #callback>
              <a
                href="#"
                target="_blank"
                (click)="_linkCallback($event, element)"
                [matTooltip]="element[col.key]?.value | formatCell"
              >
                {{ element[col.key]?.value | formatCell }}
              </a>
            </ng-template>
          </span>
        </ng-container>
        <ng-template #anchor>
          <a
            href="{{ element[col.key]?.routerLink }}"
            rel="noopener"
            target="_blank"
            [matTooltip]="element[col.key]?.value | formatCell"
          >
            {{ element[col.key]?.value | formatCell }}
          </a>
        </ng-template>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columnKeys; sticky: true" class="column-header"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: columnKeys; let k = index"
    [ngClass]="{ selected: selectedIndex === k, border: row.$isHighlighted }"
  ></tr>
</table>
<ng-template [ngIf]="totalItems !== undefined">
  <div class="pagination-wrapper">
    <mat-paginator
      class="mat-paginator-sticky"
      [pageIndex]="selectedPageIndex - 1"
      [length]="totalItems"
      [pageSize]="pageSize"
      (page)="handlePageEvent($event)"
      showFirstLastButtons
      [pageSizeOptions]="pageSizeOptions"
    >
    </mat-paginator>
  </div>
</ng-template>
