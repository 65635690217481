import { Component, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, combineLatest, debounceTime, map, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SolutionSpace } from 'src/app/models/solution';
import { DateInterval } from 'src/app/models/date.model';
import { endOfDay } from 'src/app/shared/utils';
import { ProjectsService } from 'src/app/services/api/projects.service';

@Component({
  selector: 'app-zx-project-component-datalog',
  templateUrl: './zx-project-component-datalog.component.html',
  styleUrls: ['./zx-project-component-datalog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ZxProjectComponentDatalogComponent {
  solutionSpace$ = this.route.params.pipe(
    map((el) => (el.utilorhumid === 'humidity' ? SolutionSpace.Humidity : SolutionSpace.Utilization))
  );

  projectId$ = this.route.params.pipe(map((el) => el.id as string));

  componentId$ = this.route.params.pipe(map((el) => el.componentId as string));

  datalogDateInterval$ = new BehaviorSubject({
    start: new Date(Date.now() - 12096e5),
    end: new Date(),
  });

  datalogs$ = combineLatest([this.projectId$, this.componentId$, this.datalogDateInterval$]).pipe(
    debounceTime(500),
    switchMap(([projectId, componentId, datalogDateInterval]) =>
      this.projectService.getComponentDatalog(
        projectId,
        componentId,
        datalogDateInterval.start,
        endOfDay(datalogDateInterval.end)
      )
    )
  );

  constructor(private route: ActivatedRoute, private projectService: ProjectsService) {}

  updateDatalog(newDateRange: DateInterval) {
    this.datalogDateInterval$.next({ start: newDateRange.start, end: endOfDay(newDateRange.end) });
  }
}
