<h2 mat-dialog-title>
  <svg-icon src="/assets/icons/icon-40-attention.svg"></svg-icon>
  Unsaved changes
</h2>

<h3>Leaving this page will discard all unsaved changes.</h3>
<p>Do you you wish to continue?</p>
<mat-dialog-actions>
  <button id="btn-stay" mat-flat-button [mat-dialog-close]="'stay'">Stay on page</button>
  <button id="btn-leave" mat-flat-button [mat-dialog-close]="'leave'" mat-primary color="primary">Leave page</button>
</mat-dialog-actions>
