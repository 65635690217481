<mat-radio-group
  color="primary"
  class="mat-radio-group"
  (click)="$event.stopPropagation()"
  [(ngModel)]="currentSelectedInterval"
>
  <div *ngFor="let option of intervaleOptions">
    <mat-radio-button class="mat-radio-button" (change)="onTypeUpdated(option.value)" [value]="option.value">
      {{ option.label | translate }}
    </mat-radio-button>

    <div *ngIf="currentSelectedInterval === 'LTE' && option.value === 'LTE'">
      <app-input-field
        type="number"
        cssClass="numeric-field pb-l"
        [defaultValue]="LTEValue"
        (click)="$event.stopPropagation()"
        (valueChanged)="onLteUpdated($event)"
      >
      </app-input-field>
      <span> {{ unit }}</span>
    </div>

    <div *ngIf="currentSelectedInterval === 'GTE' && option.value === 'GTE'">
      <app-input-field
        type="number"
        cssClass="numeric-field pb-l"
        [defaultValue]="GTEValue"
        (click)="$event.stopPropagation()"
        (valueChanged)="onGteUpdated($event)"
      >
      </app-input-field>
      <span> {{ unit }}</span>
    </div>

    <ng-container *ngIf="currentSelectedInterval === 'INTERVAL' && option.value === 'INTERVAL'">
      <div>
        <app-input-field
          type="number"
          cssClass="numeric-field pb-l"
          [defaultValue]="GTEValue"
          (click)="$event.stopPropagation()"
          (valueChanged)="onGteUpdated($event)"
        >
        </app-input-field>
        <span> {{ unit }}</span>
      </div>
      <p class="mt-xs mb-xs">And</p>
      <div>
        <app-input-field
          type="number"
          cssClass="numeric-field pb-l"
          [defaultValue]="LTEValue"
          (click)="$event.stopPropagation()"
          (valueChanged)="onLteUpdated($event)"
        >
        </app-input-field>
        <span> {{ unit }}</span>
      </div>
    </ng-container>
  </div>
</mat-radio-group>
