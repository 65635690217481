<fieldset #inputRef>
	<label for="{{inputName}}" class="fieldLabel">{{label}}</label>
	<input 	(change)="updateValue($event)" 
			class="inputField" 
			[ngClass]="[color, error]" 
			placeholder={{placeholder}} 
			type={{type}} 
			id={{inputName}} 
			autocomplete={{autocomplete}}
			tabindex=0 
			(focus)="onFocus()"
			(blur)="updateValue($event)"
	/>
	<div class="error__placeholder">
		<em *ngIf="error">
            {{errorMessage}}
	    </em>
	</div>
</fieldset>
