import { IPopup, IPopupValue } from './Interfaces';
import { Renderer2 } from '@angular/core';

export class Popup implements IPopup {
  public HTML: HTMLElement;

  private _renderer: Renderer2;
  private _content: HTMLElement;
  private _title: HTMLElement;

  constructor() {}

  public build(renderer: Renderer2): IPopup {
    this._renderer = renderer;
    this.HTML = <HTMLElement>renderer.createElement('div');
    this._renderer.setProperty(this.HTML, 'className', 'popup');

    this._content = <HTMLElement>renderer.createElement('div');
    this._title = <HTMLElement>renderer.createElement('div');
    this._renderer.setProperty(this._title, 'className', 'title');
    this._renderer.setProperty(this._content, 'className', 'content');

    this._renderer.appendChild(this.HTML, this._title);
    this._renderer.appendChild(this.HTML, this._content);
    return this;
  }

  public addTitle(title: string, url?: string, icon?: string): IPopup {
    let titleElement: HTMLElement;
    if (url === undefined) {
      titleElement = <HTMLElement>this._renderer.createElement('div');
    } else {
      titleElement = <HTMLElement>this._renderer.createElement('a');
      this._renderer.setProperty(titleElement, 'href', url);
      this._renderer.setProperty(titleElement, 'target', '_blank');
    }

    if (icon !== undefined) {
      const iconImage: HTMLElement = <HTMLElement>this._renderer.createElement('img');
      this._renderer.setProperty(iconImage, 'src', icon);
      this._renderer.appendChild(this._title, iconImage);
    }

    const titleText: HTMLElement = <HTMLElement>this._renderer.createText(title);
    this._renderer.appendChild(titleElement, titleText);
    this._renderer.appendChild(this._title, titleElement);

    return this;
  }

  public addContent(label: string, popupValues: IPopupValue[], icon?: string): IPopup {
    const contentElement: HTMLElement = <HTMLElement>this._renderer.createElement('div');
    const labelElement: HTMLElement = <HTMLElement>this._renderer.createElement('span');
    const valueElement: HTMLElement = <HTMLElement>this._renderer.createElement('span');

    this._renderer.setProperty(labelElement, 'className', 'label');
    this._renderer.setProperty(valueElement, 'className', 'value');
    this._renderer.setProperty(contentElement, 'className', 'content__entity');

    if (icon !== undefined) {
      const iconImage: HTMLElement = <HTMLElement>this._renderer.createElement('img');
      this._renderer.setProperty(iconImage, 'src', icon);
      this._renderer.appendChild(valueElement, iconImage);
    }

    popupValues.forEach((popupValue) => {
      const valueText: HTMLElement = <HTMLElement>this._renderer.createElement('span');
      if (popupValue.className) {
        valueText.className = popupValue.className;
      }
      this._renderer.setProperty(valueText, 'innerText', popupValue.value);
      this._renderer.appendChild(valueElement, valueText);
    });

    labelElement.innerText = label;

    this._renderer.appendChild(contentElement, labelElement);
    this._renderer.appendChild(contentElement, valueElement);
    this._renderer.appendChild(this._content, contentElement);

    return this;
  }
}
