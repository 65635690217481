export interface PageRequest<TSortOrder> {
  pageNumber?: number;
  pageSize?: number;
  sortOrder?: TSortOrder;
  sortDirection?: SortDirection;
}

export enum IncidentsSortOrder {
  CREATED,
  MEASUREMENT_TIME,
}

export enum SortDirection {
  ASC,
  DESC,
}

export enum ProjectsSortOrder {
  PROJECT_NAME,
  CREATED,
}

export enum ComponentTypesSortOrder {
  COMPONENTTYPE_NAME,
  COMPONENTTYPE_DESCRIPTION,
  CREATED,
  CREATEDBY_FULLNAME,
  UPDATED,
}

export enum ComponentsSortOrder {
  COMPONENT_NAME,
  LAST_COMMUNICATED_TIME,
  STATE,
  HUMIDITY,
  TEMPERATURE,
}

export enum DevicesSortOrder {
  IDENTIFIER,
  LAST_COMMUNICATED,
  FIRMWARE,
}
