/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, Input, OnInit } from '@angular/core';
import { ChartType, Chart, ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { HistoricalUtilStatistic } from 'src/app/models/historicalUtilStatistic';
import { ResponsiveService } from 'src/app/services/responsive.service';

enum availUse {
  AVAILABLE = 'available',
  INUSE = 'In use',
}
@Component({
  selector: 'app-utilization-statistics',
  templateUrl: './utilization-statistics.component.html',
  styleUrls: ['./utilization-statistics.component.scss'],
})
export class UtilizationStatisticsComponent implements OnInit {
  @Input()
  data: HistoricalUtilStatistic = { daysAvailable: 0, daysInUse: 0, daysInService: 0, daysInReturn: 0, cycles: 0 };

  datasets: ChartDataSets[] = [];
  options: ChartOptions;

  labels: Label[] = ['Available', 'In use', 'Return', 'Service'];

  @Input()
  isMobile: boolean;

  doughnutChartType: ChartType = 'doughnut';
  doughnutChartLabelsInUse: string[] = ['In use', 'Available'];
  doughnutChartLabelsAvailable: string[] = ['Available', 'In use'];

  dataSetInUse: Chart.ChartDataSets[];
  dataSetAvailable: Chart.ChartDataSets[];
  totalDays: number;
  maxYvalue: number;
  pourcentageDaysInUse: number;
  pourcentageDaysAvailable: number;
  availableColors: Color[] = [
    {
      backgroundColor: ['#028767', '#F7F7F7'],
    },
  ];

  inUseColors: Color[] = [
    {
      backgroundColor: ['#54585A', '#F7F7F7'],
    },
  ];

  InUseoptions = {
    cutoutPercentage: 80,
    title: { text: availUse.INUSE },
    responsive: true,
    aspectRatio: 1,
    legend: {
      display: false,
    },
  };

  availablityOptions = {
    cutoutPercentage: 80,
    aspectRatio: 1,
    title: { text: availUse.AVAILABLE },
    responsive: true,

    legend: {
      display: false,
    },
  };
  constructor(private responsiveService: ResponsiveService) {}

  ngOnInit(): void {
    this.datasets = [
      {
        data: [this.data.daysAvailable, this.data.daysInUse, this.data.daysInReturn, this.data.daysInService],
        backgroundColor: ['#028767', '#54585A', '#FF6400', '#4682B4'],
        hoverBackgroundColor: ['#028767', '#54585A', '#ff6c00', '#4682B4'],
      },
    ];
    this.maxYvalue = Math.max(...(this.datasets[0].data as number[]));
    this.isMobile = this.responsiveService.isMobile();
    this.setChartOptions();
    /* this.addLabelsInsideChart(this.data);
    this.totalDays = this.data.daysInUse + this.data.daysAvailable;
    this.pourcentageDaysInUse = Math.round(this.getPourcentage(this.totalDays, this.data.daysInUse));
    this.pourcentageDaysAvailable = Math.round(this.getPourcentage(this.totalDays, this.data.daysAvailable));
    this.dataSetAvailable = [{ data: [this.pourcentageDaysAvailable, this.pourcentageDaysInUse], borderWidth: [0, 0] }];
    this.dataSetInUse = [
      {
        data: [this.pourcentageDaysInUse, this.pourcentageDaysAvailable],
        borderWidth: [0, 0],
      },
    ];*/
  }

  setChartOptions() {
    this.options = {
      layout: {
        padding: {
          bottom: -10,
        },
      },

      legend: {
        display: false,
      },

      tooltips: {
        displayColors: false,
      },

      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            id: 'utilization',

            ticks: {
              fontSize: 16,
              fontStyle: !this.isMobile ? 'bold' : 'normal',
              fontColor: 'black',
            },
            gridLines: {
              lineWidth: 0,
            },
          },
        ],
        yAxes: [
          {
            id: 'yHumidity',

            gridLines: { lineWidth: 0 },
            ticks: {
              beginAtZero: true,
              stepSize: Math.round(this.maxYvalue / 5),
              max: this.maxYvalue,
              fontColor: '#918E85',
            },
          },
        ],
      },
    };
  }

  getPourcentage(sum: number, element: number): number {
    if (sum !== 0) {
      return (element / sum) * 100;
    }
    return 0;
  }

  addLabelsInsideChart(data: HistoricalUtilStatistic): void {
    let displayedText = '';
    function getPourcentage(sum: number, element: number): number {
      if (sum !== 0) {
        return (element / sum) * 100;
      }
      return 0;
    }
    let daysCounter = '';
    Chart.pluginService.register({
      beforeDraw: function (chart) {
        const totalDays = data.daysAvailable + data.daysInUse;
        if (chart.config.options?.title?.text === availUse.INUSE) {
          displayedText = Math.round(getPourcentage(totalDays, data.daysInUse)).toString();
          daysCounter = data.daysInUse.toString() + ' Days';
        } else {
          displayedText = Math.round(getPourcentage(totalDays, data.daysAvailable)).toString();
          daysCounter = data.daysAvailable.toString() + ' Days';
        }
        const width = chart.width;
        const height = chart.height;
        const ctx = chart.ctx;
        if (ctx) {
          ctx.restore();
          if (window.matchMedia('(max-width: 1299px)').matches) {
            ctx.font = '400 10px Roboto';
          } else {
            ctx.font = 'bold 36px Roboto';
          }
          ctx.font = 'bold 36px Roboto';
          const text = displayedText,
            textX = Math.round((width! - ctx.measureText(text).width) / 2),
            textY = height! / 2;
          ctx.fillStyle = '#000';
          ctx.fillText(text, textX, textY);
          // pourcentage drawing
          if (window.matchMedia('(max-width: 1299px) ').matches) {
            ctx.font = '600 8px Roboto';
          } else {
            ctx.font = '700 16px Roboto';
          }

          ctx.fillStyle = '#666';
          ctx.fillText('%', Math.round(width! / 2) + 5 + ctx.measureText(text).width, textY - 10);

          // set up the days number text and style for the chart inside label
          if (window.matchMedia('(max-width: 1299px)').matches) {
            ctx.font = '400 7px Roboto';
          } else {
            ctx.font = '500 15px Roboto';
          }
          ctx.fillStyle = '#666';

          const dayCountX = Math.round((width! - ctx.measureText(daysCounter).width) / 2);
          ctx.fillText(daysCounter, dayCountX, textY + 30);
          ctx.save();
        }
      },
    });
  }
}
