import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SolutionSpace } from '../../models/solution';
import { map, switchMap, BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, shareReplay } from 'rxjs';
import { ComponentsService } from 'src/app/services/api/components.service';
import { MeasurementsType } from 'src/app/models/measurement';
import { DateInterval } from 'src/app/models/date.model';
import { endOfDay } from 'src/app/shared/utils';

@Component({
  selector: 'app-zx-component-detail',
  templateUrl: './zx-component-detail.component.html',
  styleUrls: ['./zx-component-detail.component.scss'],
})
export class ZxComponentDetailComponent {
  measurementDateInterval$ = new BehaviorSubject({
    start: new Date(Date.now() - 12096e5),
    end: new Date(),
  });

  trackingPointsDateInterval$ = new BehaviorSubject({
    start: new Date(Date.now() - 12096e5),
    end: new Date(),
  });

  componentId$ = this.route.params.pipe(map((el) => el.id as string));
  solutionSpace$ = this.route.params.pipe(
    map((el) => (el.utilorhumid === 'humidity' ? SolutionSpace.Humidity : SolutionSpace.Utilization))
  );
  component$ = combineLatest([this.solutionSpace$, this.componentId$]).pipe(
    switchMap(([solutionSpace, componentId]) => this.componentService.getComponent(solutionSpace, componentId))
  );

  componentActivities$ = this.componentId$.pipe(
    switchMap((id) => this.componentService.getComponentActivity(id, 100, undefined))
  );
  utilizationStatistics$ = this.componentId$.pipe(
    distinctUntilChanged(),
    switchMap((id) => this.componentService.getUtilStatistics(id)),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    })
  );

  trackingPoints$ = combineLatest([this.solutionSpace$, this.componentId$, this.trackingPointsDateInterval$]).pipe(
    switchMap(([solutionSpace, id, dateRange]) =>
      this.componentService.getComponentDatalog(solutionSpace, id, dateRange.start, endOfDay(dateRange.end))
    )
  );

  measurements$ = combineLatest([this.componentId$, this.measurementDateInterval$]).pipe(
    debounceTime(500),
    switchMap(([id, dateRange]) =>
      this.componentService.getMeasurementsData(
        id,
        [MeasurementsType.Humidity, MeasurementsType.Temperature],
        dateRange.start,
        endOfDay(dateRange.end)
      )
    )
  );

  constructor(private route: ActivatedRoute, private componentService: ComponentsService) {}

  updateTackingPoints(newDateRange: DateInterval) {
    this.trackingPointsDateInterval$.next(newDateRange);
  }

  updateMeasurements(newDateRange: DateInterval) {
    this.measurementDateInterval$.next(newDateRange);
  }
}
