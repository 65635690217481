import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { TranslatePipe } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

export type Breadcrumb = { link: string; label: string };

@Component({
  selector: 'app-info-page',
  templateUrl: './info-page.component.html',
  styleUrls: ['./info-page.component.scss'],
  providers: [TranslatePipe],
})
export class InfoPageComponent implements OnInit {
  @Input() set title(data: string) {
    if (data) {
      this._title = data;
      if (this.activeLink) {
        const prefixTabTitle = this.activeLink.charAt(0).toUpperCase() + this.activeLink.slice(1);
        this.titleService.setTitle(
          `${this.title ? this.title : ''} - ${prefixTabTitle} - ${
            this.isComponentOrProject === 'PROJECT' ? 'Project' : 'Component'
          }`
        );
      }
    }
  }

  get title(): string {
    return this._title;
  }
  _title = '';
  @Input() isComponentOrProject?: 'PROJECT' | 'COMPONENT';
  @Input() links: { id: string; label: string; path: string }[] = [];
  @Input() showNav = true;
  @Input() hasBorderBottom = false;

  @Input() breadcrumbs: Breadcrumb[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activeLink: string | undefined;

  constructor(private router: Router, private titleService: Title) {
    router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map((navigationEnd) => navigationEnd.urlAfterRedirects)
      )
      .subscribe((url: string) => {
        this.activeLink = url.split('?')[0].split('/').pop();
      });
  }

  setTabTitle(id: string): void {
    this.activeLink = id;
    const prefixTabTitle = this.activeLink.charAt(0).toUpperCase() + this.activeLink.slice(1);
    this.titleService.setTitle(
      `${this.title ? this.title : ''} - ${prefixTabTitle} - ${
        this.isComponentOrProject === 'PROJECT' ? 'Project' : 'Component'
      }`
    );
  }

  clickNav(): void {
    void this.router.navigate(['/components']);
  }
  ngOnInit(): void {}
}
