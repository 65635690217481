import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ProjectsService } from 'src/app/services/api/projects.service';
import { SolutionSpace } from 'src/app/models/solution';
import { zxProjectModel } from 'src/app/models/zxProject.model';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Breadcrumb } from 'src/app/shared/info-page/info-page.component';

@Component({
  selector: 'app-zx-project-details',
  templateUrl: './zx-project-details.component.html',
  styleUrls: ['./zx-project-details.component.scss'],
})
export class ZxProjectDetailsComponent implements OnInit, OnDestroy {
  pages = [
    {
      id: 'overview',
      label: 'project.projectOverviewTab',
      path: 'overview',
    },

    {
      id: 'incidents',
      label: 'project.projectIncidentsTab',
      path: 'incidents',
    },
    {
      id: 'components',
      label: 'project.projectComponentsTab',
      path: 'components',
    },
  ];

  private routeSub: Subscription | undefined;
  solutionSpace: SolutionSpace;
  projectId = '';
  project: zxProjectModel;
  origin: string;
  activeLink: string | undefined;
  constructor(private projectsService: ProjectsService, private route: ActivatedRoute, private router: Router) {
    router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        map((navigationEnd) => navigationEnd.urlAfterRedirects)
      )
      .subscribe((url: string) => {
        this.activeLink = url.split('?')[0].split('/').pop();
      });

    this.origin = document.location.pathname;
  }

  /* getOrigin(): string {
    return document.location.pathname;
  } */
  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe((el) => {
      if (el.utilorhumid === 'humidity') {
        this.solutionSpace = SolutionSpace.Humidity;
      } else {
        this.solutionSpace = SolutionSpace.Utilization;
      }
      this.projectId = String(el['id']);

      this.projectsService.getProject(this.solutionSpace, this.projectId).subscribe((project) => {
        this.project = project;
      });
    });
  }

  ngOnDestroy(): void {
    this.routeSub?.unsubscribe();
  }

  breadcrumbs(): Breadcrumb[] {
    const crumbs = [
      { link: '../..', label: 'Projects' },
      { link: './overview', label: this.project?.name },
    ];

    switch (this.activeLink) {
      case 'datalog':
        crumbs.push({ link: './datalog', label: 'Datalog' });
        break;
      case 'incidents':
        crumbs.push({ link: './incidents', label: 'Incidents' });
        break;
      case 'components':
        crumbs.push({ link: './components', label: 'Components' });
        break;
    }

    return crumbs;
  }
}
