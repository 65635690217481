export enum FilterTypes {
  ALL = 'all',
  AVAILABLE = 'available',
  INUSE = 'inUse',
  HUMIDITY = 'humidity',
  IN_SERVICE = 'service',
  IN_RETURN = 'return',
  SHOCK = 'shock',
}

export enum utilFilter {
  inUse = 'IN_USE',
  available = 'AVAILABLE',
  all = 'ALL',
  shock = 'SHOCK',
  inService = 'IN_SERVICE',
  inReturn = 'IN_RETURN',
}

export enum humFilter {
  all = 'ALL',
  humid = 'HUMIDITY',
  shock = 'SHOCK',
}
