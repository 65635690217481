/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PreferencesComponent } from '../../shared/preferences/preferences.component';
import { ProjectsService } from 'src/app/services/api/projects.service';
import { SolutionSpace } from 'src/app/models/solution';

@Component({
  selector: 'app-zx-project-details-preferences',
  templateUrl: './zx-project-details-preferences.component.html',
  styleUrls: ['./zx-project-details-preferences.component.scss'],
})
export class ZxProjectDetailsPreferencesComponent implements OnInit {
  @ViewChild(PreferencesComponent, { static: true })
  preferencesComponent: PreferencesComponent;
  enforceSettingsForComponentsControl: UntypedFormControl = new UntypedFormControl();
  preferencesGroup: UntypedFormGroup;
  constructor(private projectsService: ProjectsService) {}

  ngOnInit(): void {
    this.enforceSettingsForComponentsControl.setValue(true);
    this.preferencesGroup = this.preferencesComponent.createGroup();
  }

  onUpdate(setting: string): void {
    let updateObject;
    switch (setting) {
      case 'humidity':
        updateObject = {
          humidityWarning: this.preferencesGroup.controls['humidityWarning'].value,
          humidityWarningValue: this.preferencesGroup.controls['humidityWarningValue'].value,
        };
        break;
      case 'shock':
        updateObject = {
          shockWarning: this.preferencesGroup.controls['shockWarning'].value,
          shockWarningValue: this.preferencesGroup.controls['shockWarningValue'].value,
        };
        break;
      case 'battery':
        updateObject = {
          batteryAlert: this.preferencesGroup.controls['batteryAlert'].value,
          batteryAlertValue: this.preferencesGroup.controls['batteryAlertValue'].value,
        };
        break;
      case 'noComm':
        updateObject = {
          noCommAlert: this.preferencesGroup.controls['noCommAlert'].value,
        };
        break;
    }
    updateObject && this.projectsService.patchPreference(updateObject, SolutionSpace.Humidity);
  }
}
