<div class="info-wrapper">
  <div class="header" [ngClass]="{ 'no-toolbar': title === '' }">
    <mat-toolbar class="page-top" *ngIf="title !== ''">
      <mat-toolbar-row style="font-size: 14px; color: #53575a; font-weight: 700; margin-left: -10px">
        <div *ngFor="let breadcrumb of breadcrumbs; let last = last" style="height: 30px">
          <a
            [id]="'link-nav-' + breadcrumb.label"
            [routerLink]="breadcrumb.link"
            style="padding: 0px 10px; vertical-align: top"
            >{{ breadcrumb.label }}</a
          >
          <svg-icon
            *ngIf="!last"
            src="/assets/images/icon-24-chevron-in2.svg"
            [svgStyle]="{ 'padding-top': '3px' }"
          ></svg-icon>
        </div>
      </mat-toolbar-row>

      <mat-toolbar-row>
        <div class="mat-display-1">
          {{ title | translate }}
        </div>

        <span class="example-spacer"></span>
        <ng-content select="buttons"></ng-content>
      </mat-toolbar-row>
    </mat-toolbar>
    <nav *ngIf="showNav" mat-tab-nav-bar [ngClass]="{ 'info-page-tabs': hasBorderBottom, 'grey-bg': true }">
      <a
        mat-tab-link
        [id]="'link-tab-' + link.id"
        [routerLink]="link.path"
        *ngFor="let link of links"
        (click)="setTabTitle(link.id)"
        [active]="activeLink === link.id"
      >
        {{ link.label | translate }}
      </a>
    </nav>
  </div>

  <div class="content-container">
    <router-outlet></router-outlet>
    <ng-content></ng-content>
  </div>
</div>
