import { Component, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, combineLatest, debounceTime, map, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SolutionSpace } from 'src/app/models/solution';
import { ComponentsService } from 'src/app/services/api/components.service';
import { DateInterval } from 'src/app/models/date.model';
import { endOfDay } from 'src/app/shared/utils';

@Component({
  selector: 'app-zx-component-datalog',
  templateUrl: './zx-component-datalog.component.html',
  styleUrls: ['./zx-component-datalog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ZxComponentDatalogComponent {
  solutionSpace$ = this.route.params.pipe(
    map((el) => (el.utilorhumid === 'humidity' ? SolutionSpace.Humidity : SolutionSpace.Utilization))
  );

  componentId$ = this.route.params.pipe(map((el) => el.id as string));

  datalogDateInterval$ = new BehaviorSubject({
    start: new Date(Date.now() - 12096e5),
    end: new Date(),
  });

  datalogs$ = combineLatest([this.solutionSpace$, this.componentId$, this.datalogDateInterval$]).pipe(
    debounceTime(500),
    switchMap(([solutionSpace, componentId, datalogDateInterval]) =>
      this.componentsService.getComponentDatalog(
        solutionSpace,
        componentId,
        datalogDateInterval.start,
        endOfDay(datalogDateInterval.end)
      )
    )
  );

  constructor(private route: ActivatedRoute, private componentsService: ComponentsService) {}

  updateDatalog(newDateRange: DateInterval) {
    this.datalogDateInterval$.next({ start: newDateRange.start, end: endOfDay(newDateRange.end) });
  }
}
