/* eslint-disable @typescript-eslint/unbound-method */
import { Component } from '@angular/core';

@Component({
  selector: 'app-zx-component-create',
  templateUrl: './zx-component-create.component.html',
  styleUrls: ['./zx-component-create.component.scss'],
})
export class ZxComponentCreateComponent {
  constructor() {}
}
