/* eslint-disable @angular-eslint/no-output-on-prefix */

import {
  Component,
  HostListener,
  ViewChild,
  ElementRef,
  OnInit,
  AfterViewInit,
  Output,
  Input,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { DateConversion } from '../shared/helpers/static/date-conversion';
import { PolytechUiDateMonthComponent } from './date-month/date-month.component';
import { PolytechUiService } from '../shared/services/polytech-ui.service';

const dateFormat = (date: Date, zone = 'Europe/Berlin'): string => {
  const monStr: string = date.toLocaleString('en-gb', {
    month: 'short',
    timeZone: zone,
  });
  const dayStr: string = date.toLocaleString('en-gb', {
    day: '2-digit',
    timeZone: zone,
  });
  const yearStr: string = date.toLocaleString('en-gb', {
    year: 'numeric',
    timeZone: zone,
  });
  return `${monStr} ${dayStr} ${yearStr}`;
};

@Component({
  selector: 'polytech-ui-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PolytechUiDatePickerComponent implements OnInit, AfterViewInit {
  constructor(private polytechUiService: PolytechUiService) {}

  @Input() canAlwaysConfirm = false;
  @Input() confirmText = 'Confirm';
  @Input() cancelText = 'Cancel';

  private _alwaysOpen = false;
  @Input() set alwaysOpen(alwaysOpen: boolean) {
    this._alwaysOpen = alwaysOpen;
    if (alwaysOpen) {
      this._isOpen = true;
    }
  }

  private _headLess = false;
  @Input() set headLess(headLess: boolean) {
    this._headLess = headLess;
    if (headLess) {
      this._isOpen = true;
    }
  }

  get headLess() {
    return this._headLess;
  }

  @Input() set initialTimeRange(rangeDays: string | number) {
    this.polytechUiService.numberOfInitialDays = <number>rangeDays;
  }

  @Input() initialStartDate: number | undefined = undefined;
  @Input() initialEndDate: number | undefined = undefined;

  @Input() popupPosition = 'left';
  private _timeZone = 'Europe/Berlin';
  @Input() set timeZone(zone: string) {
    this._timeZone = zone;
    localStorage.setItem('timezone', zone);
  }
  @Output() dateChanged: EventEmitter<any> = new EventEmitter();

  @Output() onClose: EventEmitter<any> = new EventEmitter();

  @ViewChild('wrapperRef', { read: ElementRef }) wrapperRef: ElementRef;
  @ViewChild('startMonthRef') startMonthRef: PolytechUiDateMonthComponent;
  @ViewChild('endMonthRef') endMonthRef: PolytechUiDateMonthComponent;

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.isOpen) {
      return;
    }

    const didClickedOutside = !(this.wrapperRef.nativeElement as HTMLElement).contains(event.target as HTMLElement);

    if (didClickedOutside) {
      if (this._isDirty && this.polytechUiService.blockDirtyUi) {
        (this.wrapperRef.nativeElement as HTMLElement).classList.add('shake');
        return;
      }
      this.closePickerWindow();
    }
  }

  public _startTimestamp = 0;
  public _endTimestamp = 0;
  public startTimestamp = 0;
  public endTimestamp = 0;

  private _isDirty = false;

  public set isDirty(dirty: boolean) {
    this._isDirty = dirty;

    if (dirty) {
      this.polytechUiService.popupState(true, <HTMLElement>this.wrapperRef?.nativeElement);
    } else {
      this.polytechUiService.popupState(false);
      /*this.appMachineService.send(
                new Confirm()  
            );*/
    }
  }

  public get isDirty(): boolean {
    return this._isDirty;
  }

  public get disableConfirm(): boolean {
    return !this.isDirty && !this.canAlwaysConfirm;
  }

  ngOnInit() {}

  public _startDateStr = '';
  public _endDateStr = '';
  public startDateStr = '';
  public endDateStr = '';

  ngAfterViewInit() {
    requestAnimationFrame(() => {
      if (typeof this.initialStartDate !== 'number' && typeof this.initialEndDate !== 'number') {
        const today = new Date();
        this.setEndDate(today);

        const timeRangeAgo = today.setDate(today.getDate() - this.polytechUiService.numberOfInitialDays);

        today.setTime(timeRangeAgo);

        this.setStartDate(today);
      } else {
        this.setStartDate(DateConversion.getDateFromEpoch(<number>this.initialStartDate));
        this.setEndDate(DateConversion.getDateFromEpoch(<number>this.initialEndDate));
      }

      this.submitDate();
      this.resetCalendars();
    });
  }

  private _isOpen = false;

  public get isOpen(): boolean {
    return this._isOpen || this._alwaysOpen || this._headLess;
  }

  public set isOpen(value: boolean) {
    this._isOpen = value;
  }

  togglePickerWindow() {
    this.isOpen = !this.isOpen || this.isDirty;
    if (this.isOpen) {
      this.resetCalendars();
    }
  }

  openPickerWindow() {
    this.isOpen = true;
  }

  cacheSelectedDates() {
    if (this._submittedStart !== undefined) {
      this.setStartDate(DateConversion.getDateFromEpoch(this._submittedStart), false);
    }
    if (this._submittedEnd !== undefined) {
      this.setEndDate(DateConversion.getDateFromEpoch(this._submittedEnd), false);
    }
  }

  closePickerWindow() {
    this.cacheSelectedDates();
    if (!this._headLess && !this._alwaysOpen) {
      this.isOpen = false;
    }
  }

  stopShake(): void {
    (this.wrapperRef.nativeElement as HTMLElement).classList.remove('shake');
  }

  setDefinedRange(timeRange: string) {
    const today: Date = new Date();
    const regex = /([0-9]+)([A-Za-z])/i;
    const ranges: Array<any> | null = timeRange.match(regex);

    const num: number = parseInt(<string>ranges?.[1]);
    const type: string = <string>ranges?.[2];

    this.setEndDate(today);

    const start: Date = new Date();
    switch (type) {
      case 'd':
        start.setDate(today.getDate() - num);
        break;
      case 'm':
        start.setMonth(today.getMonth() - num);
        break;
      case 'y':
        start.setFullYear(today.getFullYear() - num);
        break;
    }
    this.setStartDate(start);

    this.resetCalendars();
  }

  resetCalendars() {
    this.startMonthRef.currentDate = this._startTimestamp;
    this.endMonthRef.currentDate = this._endTimestamp;
  }

  setStartDate(date: Date, setDirty = true): void {
    this.isDirty = setDirty;
    this._startDateStr = dateFormat(new Date(date), this._timeZone);
    this._startTimestamp = DateConversion.dayStartFromDate(date);
  }

  setEndDate(date: Date, setDirty = true): void {
    this.isDirty = setDirty;
    this._endDateStr = dateFormat(new Date(date), this._timeZone);
    this._endTimestamp = DateConversion.dayStartFromDate(date);
  }

  updateDate() {
    requestAnimationFrame(() => {
      this.startDateStr = this._startDateStr;
      this.endDateStr = this._endDateStr;
    });
    this.isDirty = false;
    this.startTimestamp = DateConversion.getTime(new Date(this.endDateStr));
    this.endTimestamp = DateConversion.getTime(new Date(this.endDateStr));
  }

  private _submittedStart: number | undefined;
  private _submittedEnd: number | undefined;
  submitDate(event?: any): void {
    this.updateDate();
    this._submittedStart = this._startTimestamp;
    this._submittedEnd = this._endTimestamp;

    this.closePickerWindow();
    this.dateChanged.emit({ start: this._startTimestamp, end: this._endTimestamp });
  }

  cancelDate(event: any): void {
    this.isDirty = false;
    this._startDateStr = dateFormat(new Date(this.startDateStr), this._timeZone);
    this._endDateStr = dateFormat(new Date(this.endDateStr), this._timeZone);

    this._startTimestamp = new Date(this._startDateStr).getTime();
    this._endTimestamp = new Date(this._endDateStr).getTime();
    if (this._isOpen) {
      this.onClose.emit();
    }
    this.closePickerWindow();
  }
}
