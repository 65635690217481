import { Type } from 'class-transformer';
import { IncidentType } from '../services/api/incidents.service';

export class Incident {
  id: string;
  @Type(() => Date)
  measurementTime: Date;
  @Type(() => Date)
  created: Date;
  incidentType: IncidentType;
  comment: string;
  measurementValue: number;
  isAcknowledged: boolean;
  componentName: string;

  public constructor(init?: Partial<Incident>) {
    Object.assign(this, init);
  }
}

export enum IncidentUpdateState {
  Acknowledge,
  /* Shelf, */
  Open,
}
