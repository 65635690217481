<h2 mat-dialog-title>
  {{ title }}
</h2>

<div class="forms-container" style="flex-direction: column">
  <div style="display: none" class="form-field-container">
    <p class="form-field-header">Set state</p>
    <app-select-field
      class="select-field"
      [optionArray]="[
        { value: 'Acknowledged', label: 'Resolved' },
        { value: 'Shelf', label: 'Acknowledge and Shelf incident' },
        { value: 'Open', label: 'Resolve' }
      ]"
      [defaultChoice]="incidentView.isAcknowledged ? 'Resolved' : 'Resolve'"
      (selectionChange)="onActionChange($event)"
    ></app-select-field>
  </div>

  <div class="form-field-container comment" style="margin-top: 13px">
    <p class="form-field-header">
      <span>{{ "component.INCIDENTS.DIALOG.COMMENT" | translate }}</span
      ><span style="float: right">{{ commentLength }}/{{ maxCommentLength }}</span>
    </p>
    <mat-form-field style="width: 100%; height: 95px">
      <textarea
        matInput
        name="commment"
        [formControl]="commentControl"
        placeholder="Comment"
        value="incidentView.comment"
        (input)="onCommentChange()"
      ></textarea>
    </mat-form-field>
  </div>
</div>

<mat-dialog-actions style="float: right; padding: 0px 0px 24px 0px">
  <button mat-flat-button [mat-dialog-close]="null">{{ "SHARED.CANCEL" | translate }}</button>
  <button style="padding: 0px 34px" mat-flat-button [mat-dialog-close]="dialogState" mat-primary color="primary">
    {{ "SHARED.DONE" | translate }}
  </button>
</mat-dialog-actions>
<div style="clear: both"></div>
