import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UserState, UserStore } from './user.store';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState> {
  allState$ = this.select();
  organisation$ = this.select('organisation');

  canAccess$ = this.organisation$.pipe(map((org) => org?.canAccess || []));

  constructor(protected store: UserStore) {
    super(store);
  }
}
