import { IPopup, IPopupData, ISimplePopupData } from './Interfaces';
import { Renderer2 } from '@angular/core';
import { Popup } from './Popup';
import { UtilizationPopupTitleIcons } from 'src/app/models/map/utilizationType';
import { HumidityPopuptitleIcons } from 'src/app/models/map/humidityType';
import {
  getUtilizationIconandTitle,
  getHumidityIcon,
  parseComponentHealthToIcon,
} from 'src/app/models/component-health';
import { formatZonedDateTime } from '../../../utils';
import { SolutionSpace } from '../../../../models/solution';

export enum PopupType {
  Detailed,
  Simple,
  Project,
}

abstract class PopupEntity {
  public abstract creator(renderer: Renderer2): IPopup;
  public abstract render(popup: IPopup, data: IPopupData | ISimplePopupData): void;

  create(renderer: Renderer2, data: IPopupData | ISimplePopupData): IPopup {
    const popup: IPopup = this.creator(renderer);

    popup.build(renderer);

    this.render(popup, data);

    return popup;
  }
}

class UtilizationPopup extends PopupEntity {
  public creator(): IPopup {
    return new Popup();
  }

  public render(popup: IPopup, data: IPopupData) {
    popup.addTitle(data.name, `components/utilization/${data.id}/overview`, UtilizationPopupTitleIcons[data.state]);

    const componentStatus = getUtilizationIconandTitle(data.componentHealth);

    popup.addContent('Status', [{ value: componentStatus.text }], componentStatus.icon);
    popup.addContent(
      'Component type',
      typeof data.type === 'string' ? [{ value: data.type }] : [{ value: data.type.name }]
    );
    const date = new Date(data.componentHealth.lastCommunication.toString());
    popup.addContent('Last comm. (UTC)', [{ value: formatZonedDateTime(date.getTime(), true) }]);
    popup.addContent('Project', [{ value: data.projectName }]);
  }
}

class HumidityPopup extends PopupEntity {
  public creator(): IPopup {
    return new Popup();
  }

  public render(popup: IPopup, data: IPopupData) {
    const statusIcon: string = getHumidityIcon(data.componentHealth);
    popup.addTitle(data.name, `components/humidity/${data.id}/overview`, statusIcon);

    const subStatusIcon: string = parseComponentHealthToIcon(data.componentHealth, SolutionSpace.Humidity);
    popup.addContent(
      'Humidity',
      [
        { value: `${Math.round(data.componentHealth.activeHumidityIncidentValue)}% ` },
        { value: `/ ${data.componentHealth.latestHumidityThreshold || 0}%`, className: 'threshold' },
      ],
      subStatusIcon
    );
    popup.addContent(
      'Component type',
      typeof data.type === 'string' ? [{ value: data.type }] : [{ value: data.type.name }]
    );
    const date = new Date(data.componentHealth.lastCommunication.toString());
    popup.addContent('Last communicated (UTC)', [{ value: formatZonedDateTime(date.getTime(), true) }]);
    popup.addContent('Project', [{ value: data.projectName }]);
  }
}

class ProjectUtilizationPopup extends PopupEntity {
  public creator(): IPopup {
    return new Popup();
  }

  public render(popup: IPopup, data: IPopupData) {
    popup.addTitle(
      data.name,
      `projects/utilization/${data.projectId}/components/${data.id}/overview`,
      UtilizationPopupTitleIcons[data.state]
    );

    const componentStatus = getUtilizationIconandTitle(data.componentHealth);

    popup.addContent('Status', [{ value: componentStatus.text }], componentStatus.icon);
    popup.addContent(
      'Component type',
      typeof data.type === 'string' ? [{ value: data.type }] : [{ value: data.type.name }]
    );
    const date = new Date(data.componentHealth.lastCommunication.toString());
    popup.addContent('Last comm. (UTC)', [{ value: formatZonedDateTime(date.getTime(), true) }]);
    popup.addContent('Project', [{ value: data.projectName }]);
  }
}

class ProjectHumidityPopup extends PopupEntity {
  public creator(): IPopup {
    return new Popup();
  }

  public render(popup: IPopup, data: IPopupData) {
    const statusIcon: string = getHumidityIcon(data.componentHealth);
    popup.addTitle(data.name, `projects/humidity/${data.projectId}/components/${data.id}/overview`, statusIcon);

    const subStatusIcon: string = parseComponentHealthToIcon(data.componentHealth, SolutionSpace.Humidity);
    popup.addContent(
      'Humidity',
      [
        { value: `${Math.round(data.componentHealth.activeHumidityIncidentValue)}% ` },
        { value: `/ ${data.componentHealth.latestHumidityThreshold || 0}%`, className: 'threshold' },
      ],
      subStatusIcon
    );
    popup.addContent(
      'Component type',
      typeof data.type === 'string' ? [{ value: data.type }] : [{ value: data.type.name }]
    );
    const date = new Date(data.componentHealth.lastCommunication.toString());
    popup.addContent('Last communicated (UTC)', [{ value: formatZonedDateTime(date.getTime(), true) }]);
    popup.addContent('Project', [{ value: data.projectName }]);
  }
}

class SimpleUtilizationPopup extends PopupEntity {
  public creator(): IPopup {
    return new Popup();
  }

  public render(popup: IPopup, data: ISimplePopupData) {
    popup.addTitle(
      data.utilization.value,
      `components/utilization/${data.id}/overview`,
      UtilizationPopupTitleIcons[data.state]
    );
    popup.addContent('Longitude / Latitude', [
      { value: `${data.position.lng.toString()} / ${data.position.lat.toString()}` },
    ]);
  }
}

class SimpleHumidityPopup extends PopupEntity {
  public creator(): IPopup {
    return new Popup();
  }

  public render(popup: IPopup, data: ISimplePopupData) {
    popup.addTitle('OK', `components/humidity/${data.id}/overview`, HumidityPopuptitleIcons['0']);
    popup.addContent('Humidity', [
      { value: data.humidity?.value || '', className: 'value1' },
      { value: `${data.humidity?.value2 || ''}`, className: 'value2' },
    ]);
    popup.addContent('Longitude / Latitude', [
      { value: `${data.position.lng.toString()} / ${data.position.lat.toString()}` },
    ]);
  }
}

export function PopupFactory(type: PopupType, solutionSpace: SolutionSpace): PopupEntity {
  if (type === PopupType.Detailed && solutionSpace === SolutionSpace.Utilization) return new UtilizationPopup();
  if (type === PopupType.Detailed && solutionSpace === SolutionSpace.Humidity) return new HumidityPopup();

  if (type === PopupType.Simple && solutionSpace === SolutionSpace.Utilization) return new SimpleUtilizationPopup();
  if (type === PopupType.Simple && solutionSpace === SolutionSpace.Humidity) return new SimpleHumidityPopup();

  if (type === PopupType.Project && solutionSpace === SolutionSpace.Utilization) return new ProjectUtilizationPopup();
  if (type === PopupType.Project && solutionSpace === SolutionSpace.Humidity) return new ProjectHumidityPopup();

  return new UtilizationPopup();
}
