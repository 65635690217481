<mat-sidenav-container class="app-container">
  <mat-sidenav mode="side" opened="true" disableClose="true" [ngClass]="'sidenav'">
    <div class="sidenav-container">
      <a routerLink="/callback" routerLinkActive="active">
        <img
          src="/assets/images/logo.svg"
          class="polytech-logo"
          style="width: 70px; margin: auto"
          matTooltip="{{ 'SIDE_MENU.TOOLTIP.HOME' | translate }}"
          [matTooltipPosition]="'right'"
        />
      </a>

      <div class="icons-container">
        <div class="upper-icons-container">
          <a
            routerLink="/components"
            routerLinkActive="active"
            id="link-components"
            class="icon-route"
            *ngIf="canAccessComponent$ | async"
            matTooltip="{{ 'SIDE_MENU.TOOLTIP.COMPONENTS' | translate }}"
            [matTooltipPosition]="'right'"
          >
            <svg-icon
              src="/assets/images/icon-48-components.svg"
              [svgStyle]="{ 'width.px': 35 }"
              [class]="'icon'"
            ></svg-icon>
          </a>
          <a
            routerLink="/projects"
            routerLinkActive="active"
            class="icon-route"
            *ngIf="canAccessProject$ | async"
            matTooltip="{{ 'SIDE_MENU.TOOLTIP.PROJECTS' | translate }}"
            [matTooltipPosition]="'right'"
          >
            <svg-icon
              src="/assets/images/icon-48-projects.svg"
              [svgStyle]="{ 'width.px': 35 }"
              [class]="'icon'"
            ></svg-icon>
          </a>
        </div>

        <div class="lower-icons-container">
          <a
            [href]="infoUrl"
            rel="noopener"
            target="_blank"
            class="icon-route"
            matTooltip="User manual"
            [matTooltipPosition]="'right'"
          >
            <svg-icon src="/assets/images/help.svg" [svgStyle]="{ 'width.px': 35 }" [class]="'icon'"></svg-icon>
          </a>
          <a
            routerLink="/admin/devices"
            routerLinkActive="active"
            *ngIf="canAccessSetting$ | async"
            class="icon-route"
            matTooltip="{{ 'SIDE_MENU.TOOLTIP.ADMIN' | translate }}"
            [matTooltipPosition]="'right'"
          >
            <svg-icon
              src="/assets/images/icon-48-settings.svg"
              [svgStyle]="{ 'width.px': 35 }"
              [class]="'icon'"
            ></svg-icon>
          </a>
          <a
            id="link-out"
            routerLinkActive="active"
            class="icon-route"
            matTooltip="{{ 'SIDE_MENU.TOOLTIP.LOG_OUT' | translate }}"
            [matTooltipPosition]="'right'"
            (click)="logout()"
          >
            <svg-icon
              src="/assets/images/icon-48-log-out.svg"
              [svgStyle]="{ 'width.px': 35 }"
              [class]="'icon'"
            ></svg-icon>
          </a>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content style="background-color: #ffffff">
    <div class="full-height">
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
