import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { contextEnum, ResponseObj } from '../models/errorHandling';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class ResponseHandlerService {
  //Record<number, string>  can replace the Map use
  static ERROR_MAPPING = new Map<number, ResponseObj>([
    [
      401,
      {
        title: 'You are not authorized',
        message: 'Please login and try again or contact your system admin.',
        context: contextEnum.NOT_AUTHORIZED,
      },
    ],
    [
      403,
      {
        title: 'No access to this page',
        message: 'Please login and try again or contact your system admin',
        context: contextEnum.NO_ACCESS,
      },
    ],
    [
      404,
      {
        title: 'Page not found',
        message: 'The request could not be found. Please try to refresh your browser or contasct support [FE]',
        context: contextEnum.NOT_FOUND,
      },
    ],
    [
      502,
      {
        title: 'Bad gateway',
        message: 'Please refrech and try again or contact your system admin',
        context: contextEnum.BAD_GATEWAY,
      },
    ],
  ]);

  constructor(private snackService: SnackbarService) {}

  error(error: HttpErrorResponse | string, notificationDuration?: number): void {
    let obj: ResponseObj | undefined = {
      title: 'An error occured',
      message: typeof error === 'string' ? error : 'ERROR.GENERIC_MESSAGE',
      context: contextEnum.FE_ERROR,
    };

    if (error instanceof HttpErrorResponse) {
      if (ResponseHandlerService.ERROR_MAPPING.has(error.status)) {
        obj = ResponseHandlerService.ERROR_MAPPING.get(error.status);
      } else if (error.status?.toString().charAt(0) === '5') {
        obj.context = contextEnum.BE_ERROR;
      }
    }

    this.snackService.openSnackBar(obj, undefined, notificationDuration);
  }
}
