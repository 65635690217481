<app-info-page
  [title]="project ? project.name : ''"
  [isComponentOrProject]="'PROJECT'"
  [links]="pages"
  [breadcrumbs]="breadcrumbs()"
>
  <button
    style="margin-left: 8px"
    mat-flat-button
    class="btn-grey"
    [routerLink]="['/projects/edit']"
    [queryParams]="{
      solution: solutionSpace,
      id: projectId,
      origin: origin
    }"
    ngProjectAs="buttons"
  >
    <mat-icon>settings</mat-icon>
    Edit project
  </button>
</app-info-page>
