export class Preferences {
  protected static Defaults = (() => {
    Preferences.prototype.shockMeasurementActive = true;
    Preferences.prototype.shockMeasurementValue = 5;
    Preferences.prototype.humidityWarning = true;
    Preferences.prototype.humidityWarningValue = 60;
    Preferences.prototype.shockWarning = true;
    Preferences.prototype.shockWarningValue = 5;
    //Preferences.prototype.batteryAlert = true;
    //Preferences.prototype.batteryAlertValue = 20;
    //Preferences.prototype.noCommAlert = false;
  })();
  shockMeasurementActive: boolean;
  shockMeasurementValue: number;
  humidityWarning: boolean;
  humidityWarningValue: number;
  shockWarning: boolean;
  shockWarningValue: number;
  //batteryAlert: boolean;
  //batteryAlertValue: number;
  //noCommAlert: boolean;

  public constructor(init?: Partial<Preferences>) {
    if (init !== undefined) {
      Object.assign(this, init);
    }
  }
}
