<app-filter-option
  icon="/assets/icons/temperature.svg"
  [title]="'FILTER.TEMPERATURE.TITLE' | translate"
  (apply)="onApply()"
  (back)="onBack()"
>
  <div class="pr-m pl-m pt-m">
    <app-number-range-input
      unit="°C"
      [selectedIntervalOption]="selectedIntervalOption"
      (UpdateNumberRange)="onOptionChange($event)"
    ></app-number-range-input>
  </div>
</app-filter-option>
