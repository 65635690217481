<div>
  <canvas
    id="accelerationChart"
    #lineChart
    baseChart
    [datasets]="accelerationChartData"
    [labels]="accelerationChartLabels"
    [options]="options"
    chartType="scatter"
  >
  </canvas>
</div>
<button *ngIf="!isZoomHidden" class="flex float-right reset-button" (click)="resetZoom()" mat-mini-fab color="primary">
  <mat-icon>zoom_out</mat-icon>
</button>
