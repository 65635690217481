import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterIntervalOption, SelectableRangeOption } from '../filter.component';

export type DaysInStateFilterState = {
  selectedUtilizationState: string;
  selectedIntervalOption?: FilterIntervalOption;
};

@Component({
  selector: 'app-filter-days-in-state',
  templateUrl: './filter-days-in-state.component.html',
  styleUrls: ['./filter-days-in-state.component.scss'],
})
export class FilterDaysInStateComponent implements OnInit {
  @Input() utilizationOptions: SelectableRangeOption[];
  @Input() selectedState: DaysInStateFilterState;

  @Output() apply = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() optionChange: EventEmitter<DaysInStateFilterState> = new EventEmitter<DaysInStateFilterState>();

  selectedUtilizationState = '';
  currentFilterIntervalOption: FilterIntervalOption | undefined;

  ngOnInit(): void {
    if (this.selectedState) {
      this.selectedUtilizationState = this.selectedState.selectedUtilizationState;
      this.currentFilterIntervalOption = this.selectedState.selectedIntervalOption;
    }
  }

  onApply() {
    this.apply.emit();
  }

  onBack() {
    this.back.emit();
  }

  onOptionChange(filterIntervalOption: FilterIntervalOption) {
    this.currentFilterIntervalOption = filterIntervalOption;
    this.optionChange.emit({
      selectedUtilizationState: this.selectedUtilizationState,
      selectedIntervalOption: filterIntervalOption,
    });
  }

  onUtilizationStateChange() {
    this.optionChange.emit({
      selectedUtilizationState: this.selectedUtilizationState,
      selectedIntervalOption: this.currentFilterIntervalOption,
    });
  }
}
