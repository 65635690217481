import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Device } from 'src/app/models/device';
import { SolutionSpace } from 'src/app/models/solution';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { DevicesSortOrder, PageRequest } from 'src/app/models/page-request';
import { stripUndefined } from 'src/app/shared/utils';
import { plainToClass } from 'class-transformer';

import { PageResponse } from 'src/app/models/page-response';

@Injectable({
  providedIn: 'root',
})
export class DevicesService {
  constructor(private http: HttpClient, private userService: UserService) {}

  private get devicesUrl(): string {
    return environment.api + `/api/${this.userService.getOrganisationId()}/devices/`;
  }

  getAllDevices(
    filters: {
      groupIds: Array<string>;
      solutionSpace?: SolutionSpace;
      isAttachedToComponent?: boolean;
      search?: string;
    },
    pageRequest?: PageRequest<DevicesSortOrder>
  ): Observable<PageResponse<Device>> {
    return this.http
      .get<PageResponse<Device>>(`${this.devicesUrl}devices`, {
        params: { ...stripUndefined(filters), ...pageRequest },
      })
      .pipe(map((paged) => ({ ...paged, items: paged.items.map((dto) => plainToClass(Device, dto)) })));
  }
}
