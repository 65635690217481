import {
  Component,
  OnDestroy,
  AfterViewInit,
  Input,
  Renderer2,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from '@angular/core';
import { BezierSpline } from '../bezier-spline';
import * as mapboxgl from 'mapbox-gl';

import { SolutionSpace } from 'src/app/models/solution';
import { RectEntityFactory } from './factories/FactoryClusterEntity';
import { GroupFactory } from './factories/FactoryClusterGroup';
import { IGroup, ILayer } from './factories/Interfaces';
import { Area } from 'src/app/models/map/map.model';

export interface IPosition {
  accuracy?: number;
  lat: number;
  lng: number;
}

export interface IPin extends IPosition {
  status?: string;
  statusInt?: number;
}

export interface IMapData {
  image: string;
  position: Array<IPin>;
  animate?: boolean;
  clustered?: boolean;
  iconOffset?: { x: number; y: number };
  size?: number;
  popupFunction?: (renderer: Renderer2, id: number) => string;
}

export interface IClickBounds {
  minLat: number;
  maxLat: number;
  minLng: number;
  maxLng: number;
}

enum MapLayers {
  SelectedClusterMarkerLayerIndex = 0,
  SelectedMarkerLayerIndex = 2,
}

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MapViewComponent implements AfterViewInit, OnDestroy {
  private _cachedRouteData: Array<GeoJSON.Position> | undefined = undefined;

  @Input() set clusterPoints(points: GeoJSON.FeatureCollection | null) {
    if (!points) {
      return;
    }
    this._clusterPoints = points;

    if (this.clusterMapSrc) {
      this.clusterMapSrc.setData(points);
    }
  }
  @Input() set routeData(data: Array<GeoJSON.Position>) {
    if (!data) {
      return;
    }

    if (data.length === 0) {
      this._cachedRouteData = [];
    }

    if (this.hasRouting) {
      this._cachedRouteData = [...data];
      if (this._map) {
        this.drawRoute(data);
      }
    }
  }
  @Input() set markerData(dataLayers: Array<IMapData> | null) {
    if (!dataLayers) {
      return;
    }
    this.destroyPopup();
    let isNew = false;
    const updateLayers: Array<number> = [];
    const animateLayers: Array<number> = [];
    let numPoints = 0;

    if (dataLayers.length === 0) {
      this._markerData = [];
    }

    dataLayers.forEach((layerData, layerIndex) => {
      const locationsWithPosition = layerData.position.filter((locations) => locations !== null);

      if (this.areThereNewLocations(this.currentLocations[layerIndex], locationsWithPosition)) {
        if (layerData.animate === true) {
          animateLayers.push(layerIndex);
        } else if (layerData.clustered) {
          this._clusterLayerId = layerIndex;
        } else {
          updateLayers.push(layerIndex);
        }
        isNew = true;
        this.currentLocations[layerIndex] = locationsWithPosition;
      }
      numPoints += layerData.position.length;
    });

    if (isNew) {
      this._markerData = dataLayers;
    }

    const isClustered = dataLayers[0].clustered;
    const isClusteredAndSinglePoint = numPoints == 1 && isClustered;

    if (isNew && (!isClustered || isClusteredAndSinglePoint)) {
      this.shouldRenderCluster = false;

      this.clusterMarkersOnScreen.forEach((marker) => marker.remove());
      this.clusterMarkersOnScreen = [];

      if (this._isLoaded) {
        this._animationStep = 0;
        this.addMarkers(dataLayers, updateLayers);
        this.animateMarkers(dataLayers, animateLayers);
      }
    } else {
      this.shouldRenderCluster = true;
    }
  }
  @Input() mapId = 'detail-map';
  @Input() hasRouting = true;
  @Input() solution: SolutionSpace;

  private _clusterPoints: GeoJSON.FeatureCollection;
  private _markerData: Array<IMapData> = [];
  private _map: mapboxgl.Map;
  private _isLoaded = false;
  private _isStyleLoaded = false;
  private animationBezier: BezierSpline = new BezierSpline([
    { x: 0, y: 0 },
    { x: 0.3, y: 1 },
    { x: 0.6, y: 0.1 },
    { x: 1, y: 1 },
  ]);
  private currentLocations: IPin[][] = [];

  private isHoveringOverMarker = false;
  private hasPopupBeenClosed = false;

  private shouldRenderCluster = false;
  private isDraggingMap = false;
  private clusterMarkersOnScreen: Array<mapboxgl.Marker> = [];
  private clusterMapSrc: mapboxgl.GeoJSONSource;

  private maxZoomThreshold = 0.00227; // approximately 5 kilometers

  private _resizeObserver: ResizeObserver;
  private _clusterLayerId = 0;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this._map = new mapboxgl.Map({
      accessToken: 'pk.eyJ1IjoicG9seXRlY2gtYXMiLCJhIjoiY2xkbjJibGI5MGZjODQxbGVxMTkycXc4eiJ9.f18gKihsto23-bkZW3uixA',
      container: this.mapId,
      //style: 'mapbox://styles/polytech-as/cllqbdf06006j01qyhpffbkkv', // Black & White
      style: 'mapbox://styles/polytech-as/cllqf3f2f007b01pbcsgjaus3', // Satelite
      //sprite: "mapbox://sprites/mapbox/streets-v12",
      projection: <mapboxgl.Projection>{
        name: 'mercator',
        //parallels: [30, 30]
      },
      center: [8.666420098499142, 55.46996482176314],

      maxZoom: 19,
      minZoom: 1.25,
    });

    this._map.addControl(new mapboxgl.NavigationControl());
    this._map.dragRotate.disable();
    this._map.touchZoomRotate.disableRotation();
    this._map.on('load', () => {
      this.createMarkerLayers();
      this.createRouteSource();

      this.setUpListeners();
      this._isLoaded = true;
    });

    this._map.on('style.load', () => {
      this._isStyleLoaded = true;

      this.createClusterLayer(this._clusterLayerId);
      if (this._clusterPoints) {
        this.renderClusters();
      }
    });

    try {
      this._resizeObserver = new ResizeObserver(() => {
        this._map.resize();
      });

      this._resizeObserver.observe(<HTMLElement>document.getElementById(this.mapId));
    } catch (e) {
      /* stub */
    }
  }

  private currentFeatures: mapboxgl.MapboxGeoJSONFeature[] = [];

  private haveFeaturesChanged(newFeatures: mapboxgl.MapboxGeoJSONFeature[]) {
    const addedFeatures = newFeatures.filter(
      (newFeature) => !this.currentFeatures.some((currentFeature) => currentFeature.id === newFeature.id)
    );

    if (addedFeatures.length > 0) {
      return true;
    }

    const removedFeatures = this.currentFeatures.filter(
      (currentFeature) => !newFeatures.some((newFeature) => newFeature.id === currentFeature.id)
    );
    if (removedFeatures.length > 0) {
      return true;
    }

    const modifiedFeatures = newFeatures.filter((newFeature) => {
      const correspondingCurrentFeature = this.currentFeatures.find(
        (currentFeature) => currentFeature.id === newFeature.id
      );
      return correspondingCurrentFeature && !this.areFeaturesEqual(correspondingCurrentFeature, newFeature);
    });

    if (modifiedFeatures.length > 0) {
      return true;
    }

    return false;
  }

  private areFeaturesEqual(
    correspondingCurrentFeature: mapboxgl.MapboxGeoJSONFeature,
    newFeature: mapboxgl.MapboxGeoJSONFeature
  ): boolean {
    if (correspondingCurrentFeature === newFeature) {
      return true;
    }

    // It it sufficient enough to only check on the coordinates for assuming whenever the features are the same.
    // Whenever a point is getting moved out or put into a feature, its position on the map will change.
    if (
      correspondingCurrentFeature.properties?.maxLat == newFeature.properties?.maxLat &&
      correspondingCurrentFeature.properties?.maxLng == newFeature.properties?.maxLng &&
      correspondingCurrentFeature.properties?.minLat == newFeature.properties?.minLat &&
      correspondingCurrentFeature.properties?.minLng == newFeature.properties?.minLng
    ) {
      return true;
    }

    return false;
  }

  ngOnDestroy(): void {
    this._resizeObserver.unobserve(<HTMLElement>document.getElementById(this.mapId));
    this._map.remove();
  }

  private isUtilizationAndStatusAvailable = ['==', ['get', 'status'], 0];
  private isUtilizationAndStatusInUse = ['==', ['get', 'status'], 1];
  private isUtilizationAndStatusReturn = ['==', ['get', 'status'], 2];
  private isUtilizationAndStatusService = ['==', ['get', 'status'], 3];

  private isHumidityAndStatusOK = ['>=', ['get', 'status'], 0.5];
  private isHumidityAndStatusWarning = ['<', ['get', 'status'], 0.5];

  private minLat = ['number', ['get', 'lat']];
  private maxLat = ['number', ['get', 'lat']];
  private minLng = ['number', ['get', 'lng']];
  private maxLng = ['number', ['get', 'lng']];

  createClusterLayer(dataIndex?: number) {
    if (this._map === undefined) {
      return;
    }

    if (this._map.getSource('clusters') === undefined) {
      this._map.addSource('clusters', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
        cluster: true,
        clusterRadius: 200,
        clusterMaxZoom: 7,
        clusterProperties: {
          // keep separate counts for each magnitude category in a cluster
          stat1: ['+', ['case', this.isUtilizationAndStatusAvailable, 1, 0]],
          stat2: ['+', ['case', this.isUtilizationAndStatusInUse, 1, 0]],
          stat3: ['+', ['case', this.isUtilizationAndStatusReturn, 1, 0]],
          stat4: ['+', ['case', this.isUtilizationAndStatusService, 1, 0]],
          stat5: ['+', ['case', this.isHumidityAndStatusOK, 1, 0]],
          stat6: ['+', ['case', this.isHumidityAndStatusWarning, 1, 0]],
          minLat: ['min', this.minLat],
          maxLat: ['max', this.maxLat],
          minLng: ['min', this.minLng],
          maxLng: ['max', this.maxLng],
        },
      });

      this.clusterMapSrc = <mapboxgl.GeoJSONSource>this._map.getSource('clusters');
      if (this._clusterPoints) this.clusterMapSrc.setData(this._clusterPoints);
    }

    if (dataIndex !== undefined) {
      this.createLayer(dataIndex, 'clusters', 'clusters');
    }
  }

  renderClusters(): void {
    if (!this._isStyleLoaded || !this._clusterPoints) {
      return;
    }

    const newClusterMarkers: Array<mapboxgl.Marker> = [];

    for (const feature of this.currentFeatures) {
      const coords: GeoJSON.Position = (feature.geometry as GeoJSON.Point).coordinates;
      const props: GeoJSON.GeoJsonProperties = feature.properties;
      if (!props?.cluster) continue;
      const el = this.createClusterChart(props, this.renderer);

      el.onclick = () => {
        const { minLat, maxLat, minLng, maxLng }: Record<string, number> = props;

        this._map.fitBounds(
          [
            [minLng - this.maxZoomThreshold, maxLat + this.maxZoomThreshold],
            [maxLng + this.maxZoomThreshold, minLat - this.maxZoomThreshold],
          ],
          { padding: 30, duration: 200 }
        );
      };

      newClusterMarkers.push(new mapboxgl.Marker({ element: el }).setLngLat(<[number, number]>coords));
    }

    this.clusterMarkersOnScreen.forEach((marker) => marker.remove());
    this.clusterMarkersOnScreen = [];
    newClusterMarkers.forEach((marker) => marker.addTo(this._map));
    this.clusterMarkersOnScreen = newClusterMarkers;
  }

  createClusterChart(props: GeoJSON.GeoJsonProperties, renderer: Renderer2): HTMLElement {
    const box: IGroup | null = GroupFactory('Rectangle')?.create(renderer) || null;
    if (box !== null) {
      if (this.solution === SolutionSpace.Utilization) {
        this.utilizationClusters(box, props, renderer);
      } else {
        this.humidityClusters(box, props, renderer);
      }
    }
    return box?.HTML || document.createElement('div');
  }

  humidityClusters(box: IGroup, props: GeoJSON.GeoJsonProperties, renderer: Renderer2): void {
    const box1: ILayer = RectEntityFactory('HumidityOk').create(renderer);
    const box2: ILayer = RectEntityFactory('HumidityWarning').create(renderer);

    box2.addTo(box).value = <number>props?.stat5;
    box1.addTo(box).value = <number>props?.stat6;
  }

  utilizationClusters(box: IGroup, props: GeoJSON.GeoJsonProperties, renderer: Renderer2): void {
    const box1: ILayer = RectEntityFactory('Available').create(renderer);
    const box2: ILayer = RectEntityFactory('Use').create(renderer);
    const box3: ILayer = RectEntityFactory('Return').create(renderer);
    const box4: ILayer = RectEntityFactory('Service').create(renderer);

    box1.addTo(box).value = <number>props?.stat1;
    box2.addTo(box).value = <number>props?.stat2;
    box3.addTo(box).value = <number>props?.stat3;
    box4.addTo(box).value = <number>props?.stat4;
  }

  private _animationStep = 0;
  animateMarkers(positionData: Array<IMapData>, updateLayers: Array<number>) {
    let numMarkers = 0;
    let flyTo: mapboxgl.LngLatLike = [Infinity, Infinity];

    let jj = 0;
    for (; jj < positionData.length; ++jj) {
      if (updateLayers.indexOf(jj) === -1) {
        continue;
      }
      if (positionData[jj].position.length > 0) {
        const offset: number = this.animationBezier.calculate(this._animationStep).y;
        if (this._map.getLayer(`markers-${jj}`) !== undefined) {
          this._map.setLayoutProperty(`markers-${jj}`, 'icon-offset', [0, offset * -10 - 30]);
          this._map.setLayoutProperty(`markers-${jj}`, 'icon-size', offset);
        }
        numMarkers += positionData[jj].position.length;
        if (positionData[jj].position[0] !== null && positionData[jj].position[0] !== undefined) {
          flyTo = [positionData[jj].position[0].lng, positionData[jj].position[0].lat];
        }
      }
    }
    if (updateLayers.length > 0) {
      this.addMarkers(positionData, updateLayers, false);
    }

    let animate = false;
    if (numMarkers > 0 && flyTo[0] !== Infinity) {
      if (this._animationStep === 0) {
        this._map.flyTo({
          center: flyTo,
          essential: false,
          speed: 2.5,
          curve: 4,
        });
      }
      this._animationStep += 0.07;
      if (this._animationStep < 1) {
        animate = true;
      } else if (this._animationStep !== 1) {
        this._animationStep = 1;
        animate = true;
      }

      if (animate) {
        requestAnimationFrame(() => {
          this.animateMarkers(positionData, updateLayers);
        });
      }
    }
  }

  createMarkerLayers() {
    let kk = 0;

    for (kk; kk < this._markerData.length; ++kk) {
      const clusterData: { clusterMax: number; clusterRadius: number } | undefined = this._clusterPoints
        ? { clusterMax: 15, clusterRadius: 50 }
        : undefined;

      this.createSource(`markerSource-${kk}`, 'Point', clusterData);

      if (kk == MapLayers.SelectedMarkerLayerIndex && !this._clusterPoints) {
        this._map.addLayer({
          id: 'circles',
          type: 'circle',
          source: `markerSource-${kk}`,
          paint: {
            'circle-radius': ['interpolate', ['exponential', 2], ['zoom'], 0, 0, 20, ['get', 'circleRadius']],
            'circle-color': ['rgba', 27, 27, 27, 0.4],
          },
        });
      }

      this.createLayer(kk);
    }
  }

  private _popup: mapboxgl.Popup | undefined = undefined;

  createLayer(index: number, name?: string, source?: string) {
    const iconSize = this._markerData[index].size || 0.75;

    const iconOffset: Array<number> = [
      this._markerData[index].iconOffset?.x || 0,
      this._markerData[index].iconOffset?.y || 0,
    ];

    const layerId = name || `markers-${index}`;
    const layerSource = source || `markerSource-${index}`;

    this._map.addLayer({
      id: layerId,
      type: 'symbol',
      source: layerSource,
      layout: {
        'icon-image': ['get', 'icon'], //`custom-marker-${index}`,
        'icon-size': iconSize,
        'icon-offset': iconOffset,
        'icon-allow-overlap': true,
      },
    });

    if (typeof this._markerData[index].popupFunction === 'function') {
      this._map.on(
        'click',
        layerId,
        (
          e: mapboxgl.MapMouseEvent & {
            features?: Array<GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>> | undefined;
          }
        ) => {
          const point: GeoJSON.Geometry | undefined = e.features?.[0].geometry;

          if (point?.type !== 'Point') {
            return;
          }
          const coordinates: Array<number> = point?.coordinates.slice();
          const elementId: number = <number>e.features?.[0]?.properties?.id;
          const description: string | undefined = this._markerData[index]?.popupFunction?.apply(undefined, [
            this.renderer,
            elementId,
          ]);

          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          const currentPopupLoc = this._popup?.getLngLat();

          const isSamePopUp =
            currentPopupLoc && currentPopupLoc.lng == coordinates[0] && currentPopupLoc.lat == coordinates[1];

          if (this.isHoveringOverMarker && isSamePopUp && !this.hasPopupBeenClosed) {
            this._popup?.remove();
            this.hasPopupBeenClosed = true;
          } else {
            const popupOffset = 10;

            this._popup = new mapboxgl.Popup({
              offset: {
                top: [0, popupOffset],
                'top-left': [popupOffset, popupOffset],
                'top-right': [-popupOffset, popupOffset],
                bottom: [0, -popupOffset],
                'bottom-left': [popupOffset, -popupOffset],
                'bottom-right': [-popupOffset, -popupOffset],
                left: [popupOffset, 0],
                right: [-popupOffset, 0],
              },
            })
              .setLngLat(<mapboxgl.LngLatLike>coordinates)
              .setHTML(description || '')
              .addTo(this._map);

            this.hasPopupBeenClosed = false;

            this._popup.on('close', () => {
              if (!this.isHoveringOverMarker) {
                this.hasPopupBeenClosed = true;
              }
            });
          }
        }
      );
    }
  }

  destroyPopup() {
    if (this._popup !== undefined) {
      this._popup.remove();
    }
  }

  createSource(
    id: string,
    type: 'Point' | 'LineString',
    cluster?: { clusterMax: number; clusterRadius: number } | undefined
  ): void {
    const data: GeoJSON.FeatureCollection = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: type,
            coordinates: [],
          },
          properties: {},
        },
      ],
    };

    if (cluster !== undefined) {
      this._map.addSource(id, {
        type: 'geojson',
        //'cluster': true,
        //'clusterMaxZoom': cluster.clusterMax,
        //'clusterRadius': cluster.clusterRadius,
        data: data,
      });
    } else {
      this._map.addSource(id, {
        type: 'geojson',
        data: data,
      });
    }
  }

  createRouteSource() {
    this.createSource('routeSource', 'LineString');

    this._map.addLayer({
      id: 'route',
      type: 'line',
      source: 'routeSource',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#FFF',
        'line-width': 3,
      },
    });

    this._map.loadImage('assets/images/map/arrow.png', (error, image) => {
      if (error) throw error;
      this._map.addImage('arrow', <HTMLImageElement>image);
      this._map.addLayer({
        id: 'arrow-layer',
        type: 'symbol',
        source: 'routeSource',
        layout: {
          'symbol-placement': 'line',
          'symbol-spacing': 1,
          'icon-allow-overlap': true,
          'icon-image': 'arrow',
          'icon-size': 0.045,
          visibility: 'visible',
        },
      });

      if (this._markerData.length != 0 && this._isLoaded) {
        this.addMarkers(this._markerData);
      }
      if (this._cachedRouteData && this._isLoaded) {
        this.drawRoute(this._cachedRouteData);
      }
    });
  }

  drawRoute(coordinates: Array<GeoJSON.Position>) {
    const routeSource: mapboxgl.GeoJSONSource | undefined = this._map?.getSource(
      'routeSource'
    ) as mapboxgl.GeoJSONSource;

    if (routeSource !== undefined) {
      if (coordinates.length > 0) {
        routeSource.setData({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'LineString',
                coordinates: coordinates,
              },
            },
          ],
        });
      } else {
        routeSource.setData({
          type: 'FeatureCollection',
          features: [],
        });
        return;
      }
    }
  }

  getAreaCoords(): Area {
    const bounds = this._map.getBounds();
    return {
      minLat: bounds.getSouth(),
      maxLat: bounds.getNorth(),
      minLon: bounds.getWest(),
      maxLon: bounds.getEast(),
    };
  }

  addMarkers(positionData: Array<IMapData>, updateLayers?: Array<number>, fitBounds = true): void {
    let kk = 0,
      jj = 0;
    let lngMin = Infinity,
      lngMax = -Infinity,
      latMin = Infinity,
      latMax = -Infinity;

    for (; jj < positionData.length; ++jj) {
      const markerSource: mapboxgl.GeoJSONSource | undefined = this._map.getSource(
        `markerSource-${jj}`
      ) as mapboxgl.GeoJSONSource;

      if (this._clusterPoints !== undefined) {
        if (markerSource !== undefined) {
          markerSource.setData({
            type: 'FeatureCollection',
            features: [],
          });
        }
        continue;
      }

      if (updateLayers !== undefined && updateLayers.indexOf(jj) === -1) {
        continue;
      }

      kk = 0;
      const len = positionData[jj].position.length;
      const features: Array<GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>> = [];
      for (; kk < len; ++kk) {
        const markerData: IPin = positionData[jj].position[kk];

        if (markerData) {
          lngMin = Math.min(markerData.lng, lngMin - this.maxZoomThreshold);
          latMin = Math.min(markerData.lat, latMin - this.maxZoomThreshold);
          lngMax = Math.max(markerData.lng, lngMax + this.maxZoomThreshold);
          latMax = Math.max(markerData.lat, latMax + this.maxZoomThreshold);

          const icon = markerData.status || 'map-pin-generic';
          const circleRadius = markerData.accuracy
            ? markerData.accuracy / 0.075 / Math.cos((markerData.lat * Math.PI) / 180)
            : 0;

          features.push({
            type: 'Feature',
            properties: {
              id: kk,
              icon: icon,
              circleRadius: circleRadius,
            },
            geometry: {
              type: 'Point',
              coordinates: <GeoJSON.Position>[markerData.lng, markerData.lat],
            },
          });
        }
      }

      if (markerSource !== undefined) {
        markerSource.setData({
          type: 'FeatureCollection',
          features: features,
        });
      }
    }

    this.zoomToMarkerArea(lngMin, lngMax, latMax, latMin, fitBounds, positionData);
    this.destroyPopup();
  }

  private zoomToMarkerArea(
    lngMin: number,
    lngMax: number,
    latMax: number,
    latMin: number,
    fitBounds: boolean,
    positionData: IMapData[]
  ) {
    if (lngMin !== Infinity && lngMax !== -Infinity) {
      if (fitBounds) {
        if (positionData[MapLayers.SelectedMarkerLayerIndex]?.position.length == 1) {
          const lng: number = positionData[MapLayers.SelectedMarkerLayerIndex].position[0].lng;
          const lat: number = positionData[MapLayers.SelectedMarkerLayerIndex].position[0].lat;
          const accuracyMeters = positionData[MapLayers.SelectedMarkerLayerIndex].position[0].accuracy;

          if (accuracyMeters) {
            const distanceInKm: number = accuracyMeters / 1000;
            const lengthOfOneDegree: number = (40075 / 360) * Math.cos((lat * Math.PI) / 180);
            const zoomThreshold: number = distanceInKm / lengthOfOneDegree;

            this._map.fitBounds(
              [
                [lng - zoomThreshold, lat + zoomThreshold],
                [lng + zoomThreshold, lat - zoomThreshold],
              ],
              { duration: 200 }
            );
          } else {
            this._map.easeTo({ center: [lng, lat] });
          }
        } else {
          this._map.fitBounds(
            [
              [Math.max(lngMin, -90), Math.min(latMax, 90)],
              [Math.min(lngMax, 90), Math.max(latMin, -90)],
            ],
            { padding: 100, duration: 200 }
          );
        }
      } else {
        this._map.flyTo({
          center: [lngMin, latMin],
        });
      }
    } else if (positionData[MapLayers.SelectedClusterMarkerLayerIndex].position.length == 1) {
      this._map.easeTo({
        center: [
          positionData[MapLayers.SelectedClusterMarkerLayerIndex].position[0].lng,
          positionData[MapLayers.SelectedClusterMarkerLayerIndex].position[0].lat,
        ],
      });
    }
  }

  private areThereNewLocations(currenLocations: IPin[], newLocations: IPin[]) {
    if (currenLocations?.length != newLocations?.length) {
      return true;
    }

    return currenLocations.some((location, index) => {
      const newLocation = newLocations[index];

      return (
        location.lat !== newLocation.lat ||
        location.lng !== newLocation.lng ||
        location.status !== newLocation.status ||
        location.statusInt !== newLocation.statusInt
      );
    });
  }

  private setUpListeners(): void {
    this._map.on('render', () => {
      if (this._clusterPoints && !this.isDraggingMap && this.shouldRenderCluster) {
        const newFeatures = this._map.querySourceFeatures('clusters');

        if (this.haveFeaturesChanged(newFeatures)) {
          this.currentFeatures = newFeatures;
          this.renderClusters();
        }
      }
    });

    this._map.on('dragstart', () => {
      this.isDraggingMap = true;
    });

    this._map.on('dragend', () => {
      this.isDraggingMap = false;
    });

    if (this._markerData.length == 3) {
      this._map.on('mouseenter', 'markers-2', () => {
        this._map.getCanvas().style.cursor = 'pointer';
        this.isHoveringOverMarker = true;
      });

      this._map.on('mouseleave', 'markers-2', () => {
        this._map.getCanvas().style.cursor = '';
        this.isHoveringOverMarker = false;
      });
    } else {
      this._map.on('mouseenter', 'clusters', () => {
        this._map.getCanvas().style.cursor = 'pointer';
        this.isHoveringOverMarker = true;
      });

      this._map.on('mouseleave', 'clusters', () => {
        this._map.getCanvas().style.cursor = '';
        this.isHoveringOverMarker = false;
      });
    }
  }
}
