import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, debounceTime, map, switchMap } from 'rxjs';
import { MeasurementsType } from 'src/app/models/measurement';

import { SolutionSpace } from 'src/app/models/solution';
import { DateInterval } from 'src/app/models/date.model';
import { ProjectsService } from 'src/app/services/api/projects.service';

@Component({
  selector: 'app-zx-project-component-incidents',
  templateUrl: './zx-project-component-insights.html',
  styleUrls: ['./zx-project-component-insights.scss'],
})
export class ZxProjectComponentInsightsComponent {
  measurementHumidityDateInterval$ = new BehaviorSubject({
    start: new Date(Date.now() - 12096e5),
    end: new Date(),
  });

  measurementNSEDateInterval$ = new BehaviorSubject({
    start: new Date(Date.now() - 12096e5),
    end: new Date(),
  });

  solutionSpace$ = this.route.params.pipe(
    map((el) => (el.utilorhumid === 'humidity' ? SolutionSpace.Humidity : SolutionSpace.Utilization))
  );

  projectId$ = this.route.params.pipe(map((el) => el.id as string));

  componentId$ = this.route.params.pipe(map((el) => el.componentId as string));

  measurementsHumidiy$ = combineLatest([
    this.projectId$,
    this.componentId$,
    this.measurementHumidityDateInterval$,
  ]).pipe(
    debounceTime(500),
    switchMap(([projectId, componentId, dateInterval]) => {
      const measurementsDataPoints = [MeasurementsType.Humidity, MeasurementsType.Temperature];
      return this.projectService.getComponentMeasurementsData(
        projectId,
        componentId,
        measurementsDataPoints,
        dateInterval.start,
        dateInterval.end
      );
    })
  );

  measurementsNSE$ = combineLatest([this.projectId$, this.componentId$, this.measurementNSEDateInterval$]).pipe(
    debounceTime(500),
    switchMap(([projectId, componentId, dateInterval]) => {
      const measurementsDataPoints = [MeasurementsType.NormalizedShockEnergy];
      return this.projectService.getComponentMeasurementsData(
        projectId,
        componentId,
        measurementsDataPoints,
        dateInterval.start,
        dateInterval.end
      );
    })
  );

  constructor(private route: ActivatedRoute, private projectService: ProjectsService) {}

  updateDateRangeNSE(newDateRange: DateInterval) {
    this.measurementNSEDateInterval$.next({ start: newDateRange.start, end: newDateRange.end });
  }

  updateDateRangeHumidity(newDateRange: DateInterval) {
    this.measurementHumidityDateInterval$.next({ start: newDateRange.start, end: newDateRange.end });
  }
}
