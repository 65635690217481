import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ZxProjectsOverviewComponent } from './zx-projects/zx-projects-overview/zx-projects-overview.component';
import { CallbackComponent } from './callback/callback.component';
import { ZxComponentsOverviewComponent } from './zx-components/zx-components-overview/zx-components-overview.component';
import { ZxProjectDetailsOverviewComponent } from './zx-projects/zx-project-details-overview/zx-project-details-overview.component';
import { ZxProjectDetailsComponent } from './zx-projects/zx-project-details/zx-project-details.component';
import { ZxComponentDetailComponent } from './zx-components/zx-component-detail/zx-component-detail.component';
import { ZxComponentsComponent } from './zx-components/zx-components-list/zx-components.component';
import { ZxComponentDatalogComponent } from './zx-components/zx-component-datalog/zx-component-datalog.component';
import { ZxComponentIncidentsComponent } from './zx-components/zx-component-incidents/zx-component-incidents.component';
import { GuardService as Guard } from './services/guard/guard.service';
import { ZxProjectDetailsIncidentsComponent } from './zx-projects/zx-project-details-incidents/zx-project-details-incidents.component';
import { ZxProjectDetailsPreferencesComponent } from './zx-projects/zx-project-details-preferences/zx-project-details-preferences.component';
import { ZxComponentCreateComponent } from './zx-components/zx-component-create/zx-component-create.component';
import { ZxComponentDetailPreferencesComponent } from './zx-components/zx-component-detail-preferences/zx-component-detail-preferences.component';
import { ZxComponentEditComponent } from './zx-components/zx-component-edit/zx-component-edit.component';
import { ZxProjectCreateComponent } from './zx-projects/zx-project-create/zx-project-create.component';
import { ZxProjectEditComponent } from './zx-projects/zx-project-edit/zx-project-edit.component';
import { AdminPageComponent } from './admin/admin-page/admin-page.component';
import { DevicesComponent } from './admin/devices/devices.component';
import { UsersComponent } from './admin/users/users.component';
import { GroupsComponent } from './admin/groups/groups.component';
import { OrganizationsComponent } from './admin/organizations/organizations.component';
import { ZxComponentLifeCycleComponent } from './zx-components/zx-component-life-cycle/zx-component-life-cycle.component';
import { ZxComponentInsightsComponent } from './zx-components/zx-component-insights/zx-component-insights';

import { ErrorpageComponent } from './errorpage/errorpage.component';
import { ZxProjectComponentDetailComponent } from './zx-components/zx-project-component-detail/zx-project-component-detail.component';
import { ZxProjectComponentInsightsComponent } from './zx-components/zx-project-component-insights/zx-project-component-insights';
import { ZxProjectComponentDatalogComponent } from './zx-components/zx-project-component-datalog /zx-project-component-datalog.component';
import { ZxProjectComponentIncidentsComponent } from './zx-components/zx-project-component-incidents /zx-project-component-incidents.component';
import { ZxProjectComponentsOverviewComponent } from './zx-components/zx-project-components-overview /zx-project-components-overview.component';

const routes: Routes = [
  { path: 'callback', component: CallbackComponent },
  { path: 'forbidden', component: ErrorpageComponent, canActivate: [Guard] },
  {
    path: 'projects',
    component: ZxProjectsOverviewComponent,
    canActivate: [Guard],
    pathMatch: 'full',
    data: {
      requiredAccess: 'projects',
    },
  },
  {
    path: 'projects/create',
    component: ZxProjectCreateComponent,
  },
  {
    path: 'projects/edit',
    component: ZxProjectEditComponent,
  },
  {
    path: 'projects/:utilorhumid',
    children: [
      {
        path: '',
        component: ZxProjectsOverviewComponent,
      },
      {
        path: ':id/components/:componentId',
        component: ZxProjectComponentsOverviewComponent,
        children: [
          {
            path: 'overview',
            component: ZxProjectComponentDetailComponent,
          },
          {
            path: 'insights',
            component: ZxProjectComponentInsightsComponent,
          },
          {
            path: 'datalog',
            component: ZxProjectComponentDatalogComponent,
          },
          {
            path: 'incidents',
            component: ZxProjectComponentIncidentsComponent,
          },
        ],
      },
      {
        path: ':id',
        component: ZxProjectDetailsComponent,
        children: [
          {
            path: 'overview',
            component: ZxProjectDetailsOverviewComponent,
          },
          {
            path: 'incidents',
            component: ZxProjectDetailsIncidentsComponent,
          },
          {
            path: 'components',
            component: ZxComponentsComponent,
          },
          {
            path: 'preferences',
            component: ZxProjectDetailsPreferencesComponent,
          },
        ],
      },
    ],
  },

  {
    path: 'components/create',
    component: ZxComponentCreateComponent,
    canActivate: [Guard],
  },
  {
    path: 'components/edit',
    component: ZxComponentEditComponent,
    canActivate: [Guard],
  },

  {
    path: 'components',
    canActivate: [Guard],
    component: ZxComponentsComponent,
    data: {
      requiredAccess: 'components',
    },
  },
  {
    path: 'components/:utilorhumid',
    canActivate: [Guard],
    canActivateChild: [Guard],
    data: {
      requiredAccess: 'components',
    },
    children: [
      {
        path: '',
        component: ZxComponentsComponent,
      },
      {
        path: ':id',
        component: ZxComponentsOverviewComponent,
        children: [
          {
            path: 'overview',
            component: ZxComponentDetailComponent,
          },
          {
            path: 'lifeCycle',
            component: ZxComponentLifeCycleComponent,
          },
          {
            path: 'insights',
            component: ZxComponentInsightsComponent,
          },
          {
            path: 'datalog',
            component: ZxComponentDatalogComponent,
          },
          {
            path: 'incidents',
            component: ZxComponentIncidentsComponent,
          },
          {
            path: 'preferences',
            component: ZxComponentDetailPreferencesComponent,
          },
        ],
      },
    ],
  },

  {
    path: 'admin',
    canActivateChild: [Guard],
    data: {
      requiredAccess: 'settings',
    },
    component: AdminPageComponent,
    children: [
      {
        path: 'users',
        component: UsersComponent,
      },
      {
        path: 'devices',
        component: DevicesComponent,
      },
      {
        path: 'groups',
        component: GroupsComponent,
      },
      {
        path: 'organizations',
        component: OrganizationsComponent,
      },
    ],
  },
  { path: '**', redirectTo: '/callback', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
