<div id="container-prompt" class="flex-row space-between snackbar-size">
  <div class="ml-l margin-top-auto margin-bottom-auto">
    <svg-icon [src]="getIcon().icon" [class]="'search-icon'"></svg-icon>
  </div>

  <div class="ml-l mt-l mb-l flex-col">
    <span class="snackbar-title"> {{ data.responseObject.title | translate }}</span>

    <span class="snackbar-message mt-xxs">{{ data.responseObject.message | translate }}</span>
  </div>

  <div class="ml-l mr-xs margin-top-auto margin-bottom-auto">
    <mat-icon
      id="btn-prompt-close"
      (click)="dismiss()"
      class="margin-top-auto margin-bottom-auto cursor-pointer float-right grey"
    >
      close
    </mat-icon>
  </div>
</div>
