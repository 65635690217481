import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'polytech-ui-modal-card',
  templateUrl: './modal-card.component.html',
  styleUrls: ['./modal-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PolytechUiModalCardComponent {
  constructor() {}

  @Input() title: string;
  @Input() subTitle: string;
}
