export const environment = {
  production: false,
  api: 'https://v1.web.dev.logistics.cloud.polytech.com',
  issuer: 'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_vm7LewP3c',
  clientId: '7v4g4monb5slsbc9ioh16pqq1o',
  logoutUrl: 'https://polytech-spectrum-dev.auth.eu-central-1.amazoncognito.com/logout',
  message: 'BUILDCONFIG: polytech_dev',
  mapbox: {
    accessToken: 'pk.eyJ1IjoicG9seXRlY2gtYXMiLCJhIjoiY2xscTl3NWM2MGJzZzN0cHNxbHo5NG5vbSJ9.DfMqvp68CIRlXHb5YLqJ0Q',
  },
};
