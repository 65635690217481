<h2 mat-dialog-title class="overwrite-margin">{{ this.data.isEdit ? "Edit" : "Create" }} component type</h2>

<div class="form-container type-dialog">
  <form [formGroup]="componentTypeForm">
    <div class="form-field-container">
      <span class="form-field-header">COMPONENT TYPE NAME<span style="color: #de4020">*</span></span>
      <mat-form-field appearance="outline" class="name">
        <input id="input-component-type-name" matInput formControlName="name" placeholder="Component type name" />
      </mat-form-field>
    </div>
    <div class="form-field-container">
      <span class="form-field-header mt1">DESCRIPTION</span>
      <mat-form-field appearance="outline" class="description">
        <input
          id="input-component-type-description"
          matInput
          formControlName="description"
          placeholder="E.g. My Component Type"
        />
      </mat-form-field>
    </div>
    <ng-container *ngIf="!isHumidity">
      <h3 style="margin-top: 24px; margin-bottom: 3px">Available / In use configuration</h3>
      <p>Input the expected minimum and maximum distance to the object that are target for measurement.</p>
      <div class="grid">
        <div class="form-field-container space-between">
          <span class="form-field-header mt1">MIN DISTANCE (MM)<span style="color: #de4020">*</span></span>
          <mat-form-field appearance="outline" style="width: 150px">
            <mat-select
              id="select-min-distance"
              formControlName="mindistance"
              placeholder="Select distance"
              (selectionChange)="minChange()"
            >
              <mat-option id="option-min-{{ value }}" *ngFor="let value of minValues" value="{{ value }}">
                {{ value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-field-container ml mt1">
          <span class="form-field-header">MAX DISTANCE (MM)<span style="color: #de4020">*</span></span>
          <mat-form-field appearance="outline" style="width: 150px">
            <mat-select
              id="select-max-distance"
              formControlName="maxdistance"
              placeholder="Select distance"
              (selectionChange)="maxChange()"
            >
              <mat-option id="option-max-{{ value }}" *ngFor="let value of maxValues" value="{{ value }}">
                {{ value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </ng-container>

    <div class="separator"></div>
    <header>
      <svg-icon src="/assets/images/icon_incidents.svg" [class]="'icon'"></svg-icon>
      <h3>Incidents</h3>
    </header>
    <ng-container *ngIf="isHumidity">
      <mat-slide-toggle id="toggle-humidity-warning" formControlName="isHumidityWarningActive" style="font-weight: 600">
        <svg-icon src="/assets/images/icon-16-humidity.svg"></svg-icon> Humidity
      </mat-slide-toggle>
      <p>Highlight components when humidity is above set threshold</p>
      <span class="form-label mt1">Humidity</span>
      <div class="form-icon-container">
        <mat-form-field class="select-field inverted" appearance="outline">
          <mat-select formControlName="humidityThreshold">
            <mat-option value="10">10%</mat-option>
            <mat-option value="20">20%</mat-option>
            <mat-option value="30">30%</mat-option>
            <mat-option value="40">40%</mat-option>
            <mat-option value="50">50%</mat-option>
            <mat-option value="60">60%</mat-option>
            <mat-option value="70">70%</mat-option>
            <mat-option value="80">80%</mat-option>
            <mat-option value="90">90%</mat-option>
          </mat-select>
        </mat-form-field>
        <svg-icon
          src="/assets/images/icon-16-help.svg"
          [class]="'icon-tool-tip'"
          matTooltip="Humidity threshold for triggering humidity incidents"
        ></svg-icon>
      </div>
    </ng-container>
    <div class="separator short" *ngIf="isHumidity"></div>
    <mat-slide-toggle id="toggle-shock-warning" formControlName="isShockWarningActive" style="font-weight: 600">
      <svg-icon src="/assets/images/icon-16-shock.svg"></svg-icon> Shock
    </mat-slide-toggle>
    <p>Highlight components when exposed to a shock above threshold</p>
    <span class="form-label mt1">NSE</span>
    <div class="form-icon-container">
      <mat-form-field class="select-field inverted" appearance="outline">
        <mat-select formControlName="shockThreshold" (selectionChange)="onShockValueChange()">
          <mat-option *ngFor="let shockValue of shockValues" value="{{ shockValue }}"> {{ shockValue }}  </mat-option>
        </mat-select>
      </mat-form-field>
      <svg-icon
        src="/assets/images/icon-16-help.svg"
        [class]="'icon-tool-tip'"
        matTooltip="Normalized shock energy threshold for triggering shock incidents"
      ></svg-icon>
    </div>
    <div class="separator"></div>
  </form>
</div>
<mat-dialog-actions>
  <ng-container *ngIf="this.data.isEdit">
    <div style="width: 25%">
      <button
        mat-flat-button
        [disabled]="deleteDisabled()"
        [mat-dialog-close]="'delete'"
        [ngStyle]="{ 'background-color': deleteDisabled() ? '#777777' : '#f44336', color: 'white' }"
      >
        Delete
      </button>
    </div>
  </ng-container>
  <div [ngStyle]="{ width: this.data.isEdit ? '75%' : '100%', 'text-align': 'right' }">
    <button id="btn-component-type-cancel" mat-flat-button [disabled]="createClicked" [mat-dialog-close]="'cancel'">
      Cancel
    </button>
    <button
      *ngIf="this.data.isEdit"
      id="btn-component-type-update"
      mat-flat-button
      [disabled]="createClicked || invalidForm()"
      [class.spinner]="createClicked"
      (click)="onClick()"
      mat-primary
      color="primary"
      [mat-dialog-close]="'update'"
    >
      Update
    </button>

    <button
      *ngIf="!this.data.isEdit"
      id="btn-component-type-create"
      mat-flat-button
      [disabled]="createClicked || invalidForm()"
      [class.spinner]="createClicked"
      (click)="onClick()"
      mat-primary
      color="primary"
      [mat-dialog-close]="'create'"
    >
      Create
    </button>
  </div>
</mat-dialog-actions>
