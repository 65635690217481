<div class="flex-center">
  <svg-icon src="/assets/icons/noPermission/badge.svg"></svg-icon>

  <h1 class="title-proh">You do not have permission to view this page</h1>
  <p class="desc pb-l">Please contact your admin if you think this is wrong.</p>
  <span class="desc">
    For onboarding of devices, please download <strong>Polytech Logistics</strong> from your device's app store.
  </span>
  <div class="app-store">
    <svg-icon
      [svgStyle]="{ 'width.px': 230, 'height.px': 70 }"
      src="/assets/icons/noPermission/AppStoreBadge.svg"
    ></svg-icon>
    <img class="app-strore-img" src="/assets/icons/noPermission/googlePlayBadge.png" />
  </div>
</div>
