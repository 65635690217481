import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Incident, IncidentUpdateState } from 'src/app/models/incident';
import { incidentStatus } from 'src/app/models/transforms';

@Component({
  selector: 'app-reopen-dialog',
  templateUrl: './reopen-dialog.component.html',
  styleUrls: ['./reopen-dialog.component.scss'],
})
export class ReopenDialogComponent {
  dialogState: IncidentUpdateState = IncidentUpdateState.Open;
  incidentStatus: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: Incident) {
    this.incidentStatus = incidentStatus(this.data.incidentType, this.data.isAcknowledged).value;
  }
}
