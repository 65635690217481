import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectableOption } from '../components-menu/filter/filter.component';

@Component({
  selector: 'app-multi-checkbox',
  templateUrl: './multi-checkbox.component.html',
  styleUrls: ['./multi-checkbox.component.scss'],
})
export class MultiCheckboxComponent {
  @Input() optionArray: SelectableOption[];
  @Output() optionChange = new EventEmitter<SelectableOption[]>();

  onOptionChange(): void {
    const choosenOptions = this.optionArray.filter((option) => option.isSelected);
    this.optionChange.emit(choosenOptions);
  }
}
