<nav>
    <polytech-ui-svg-button 
        icon="logo"
        [backing]="false"
        color="orange"
        [disabled]="true"
    >
    </polytech-ui-svg-button>

    <div class="major-bar">
        <ng-content select="[options]"></ng-content>
    </div>
    <ng-content select="[help]"></ng-content>
    <ng-content select="[logout]"></ng-content>
</nav>
