<div class="datalog-component-wrapper pl-l pr-l pt-s pb-l">
  <div class="forms-container filter-datalog">
    <div class="form-field-container">
      <div class="form-container" style="margin-top: unset">
        <div class="form-field-container pr-s main-page flex-child-50">
          <polytech-ui-date-picker
            (dateChanged)="updateDateRange($event)"
            initialTimeRange="14"
            [canAlwaysConfirm]="true"
            timeZone="Africa/Abidjan"
            #datePickerRef
          >
            <dt (click)="datePickerRef.setDefinedRange('14d')">Last 14 days</dt>
            <dt (click)="datePickerRef.setDefinedRange('3m')">Last 3 months</dt>
            <dt (click)="datePickerRef.setDefinedRange('6m')">Last 6 months</dt>
            <dt (click)="selectAllData()">All Time</dt>
          </polytech-ui-date-picker>
        </div>
      </div>
    </div>
    <button mat-button class="btn-grey" ngProjectAs="buttons" (click)="openDialog()">
      <img src="/assets/icons/export.svg" />
      {{ "component.export" | translate }}
    </button>
  </div>
  <mat-card class="shadow-card">
    <div class="content">
      <ng-container *ngIf="isLoaded">
        <div style="text-align: center; padding-top: 64px" *ngIf="dataSource.length === 0">
          <h2>No data available</h2>
          <ng-container *ngIf="displayedDateRange !== 'All Time'">
            <h4 class="text-empty-label mb-m">Why not check data collected in ...</h4>
            <button
              mat-flat-button
              style="background-color: #4682b4; color: white"
              (click)="onDateRangeShortcutClicked(shortcutFilter)"
            >
              {{ shortcutFilter.label }}
            </button>
          </ng-container>
        </div>
      </ng-container>
      <app-table
        [hidden]="!isLoaded || dataSource.length === 0"
        [tableCols]="displayedColumns"
        [pageSize]="pageSize"
        [dataSource]="dataSource"
        [totalItems]="totalItems"
        (paginationChanged)="onPaginationChanged($event)"
      ></app-table>
      <mat-spinner class="spinner-position" *ngIf="!isLoaded"></mat-spinner>
    </div>
  </mat-card>
</div>
