export enum contextEnum {
  NOT_AUTHORIZED,
  NO_ACCESS,
  NOT_FOUND,
  BAD_GATEWAY,
  FE_ERROR,
  BE_ERROR,
}
export interface ResponseObj {
  title: string;
  message: string;
  context?: contextEnum;
}
