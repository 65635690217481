<div class="incident-component-wrapper">
  <div class="filter-container border-bottom-1 space-between pb-m">
    <div>
      <button
        class="hover-button margin-right-xs"
        *ngIf="isHumidity()"
        mat-button
        [matMenuTriggerFor]="typeMenu"
        (click)="isFilterClicked = true"
        [ngClass]="{ 'focus-button': isFilterClicked }"
      >
        <svg-icon class="orange-color incident-icon mr-s" src="{{ incidentTypeIcon }}"> </svg-icon>
        {{ incidentTypeText }}
      </button>

      <button class="hover-button" mat-button (click)="setIncidentStatus()">
        <span>
          <svg-icon class="orange-color mr-s" [src]="iconResolve"> </svg-icon>
        </span>
        {{ resolvedFilterText }}
      </button>
    </div>

    <div *ngIf="incidents && incidents.length > 0 && !this.filter.isAcknowledged">
      <button *ngIf="shouldResolveAll && !this.filter.isAcknowledged" mat-button (click)="resolveAll()">
        <svg-icon class="orange-color mr-s" src="/assets/icons/icon-16-ok-current-color.svg"> </svg-icon>
        Resolve all incidents
      </button>
      <ng-container *ngIf="!shouldResolveAll && this.selectedIncidents.length > 0">
        <button mat-button disabled>
          <svg-icon class="orange-color mr-s" src="/assets/icons/close.svg"> </svg-icon>

          {{ this.selectedIncidents.length }} Selected
        </button>
        <button mat-button (click)="acknowledgeMuntipleIncidents()">
          <svg-icon
            [svgStyle]="{ 'width.px': 14 }"
            class="orange-color"
            src="/assets/icons/icon-16-ok-current-color.svg"
          ></svg-icon>
          Resolve
        </button>
      </ng-container>
    </div>
  </div>
  <mat-card class="shadow-card">
    <mat-menu #typeMenu="matMenu" (closed)="isFilterClicked = false" (menuOpened)="isFilterClicked = true">
      <button style="width: 200px" mat-menu-item *ngFor="let type of incidentTypes" (click)="setIncidentType(type)">
        <svg-icon
          [svgStyle]="{ 'width.px': 14, 'margin-right.px': 12 }"
          class="black-icon"
          src="{{ type.icon }}"
        ></svg-icon>
        {{ type.label }}
      </button>
    </mat-menu>
    <div style="margin-top: 12px">
      <app-table
        [hidden]="!isIncidentsLoaded"
        [bulkEnabled]="dataSource.length > 0 ? true : false"
        [tableCols]="displayedColumns"
        [dataSource]="dataSource"
        [showSum]="true"
        (linkClicked)="openIncidentDialog($event)"
        (selectionChanged)="bulkSelection($event)"
        [selectedPageIndex]="this.pagination.pageNumber ?? 1"
        [pageSize]="this.pagination.pageSize ?? 50"
        [totalItems]="totalItemCount"
        (paginationChanged)="handlePaginationEvent($event)"
        (sortingChanged)="onSortChange($event)"
      ></app-table>
    </div>

    <mat-spinner class="spinner-position" *ngIf="!isIncidentsLoaded"></mat-spinner>
  </mat-card>
</div>
