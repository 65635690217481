import { IGroup } from './Interfaces';
import { GroupRect } from './GroupRect';
import { Renderer2 } from '@angular/core';

abstract class ClusterGroup {
  public abstract creator(renderer: Renderer2): IGroup;

  create(renderer: Renderer2): IGroup {
    const group: IGroup = this.creator(renderer);
    group.build(renderer);

    return group;
  }
}

class Rectangle extends ClusterGroup {
  public creator(renderer: Renderer2): IGroup {
    return new GroupRect('clusterWrapper');
  }
}

export function GroupFactory(groupType: string): ClusterGroup | null {
  let layer;

  switch (groupType) {
    case 'Rectangle':
      return new Rectangle();

    default:
      return null;
  }
}
