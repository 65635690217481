import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterIntervalOption } from '../filter.component';

@Component({
  selector: 'app-filter-temperature',
  templateUrl: './filter-temperature.component.html',
  styleUrls: ['./filter-temperature.component.scss'],
})
export class FilterTemperatureComponent {
  @Input() selectedIntervalOption?: FilterIntervalOption;

  @Output() apply = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() optionChange: EventEmitter<FilterIntervalOption> = new EventEmitter<FilterIntervalOption>();

  onApply() {
    this.apply.emit();
  }

  onBack() {
    this.back.emit();
  }

  onOptionChange(event: FilterIntervalOption) {
    this.optionChange.emit(event);
  }
}
