<div>
  <canvas
    id="utilizationChart"
    #lineChart
    baseChart
    [datasets]="lineChartData"
    [labels]="humidityChartLabels"
    [options]="options"
    [legend]="showLegend"
    chartType="line"
  >
  </canvas>
</div>
<button *ngIf="!isZoomHidden" class="flex float-right reset-button" (click)="resetZoom()" mat-mini-fab color="primary">
  <mat-icon>zoom_out</mat-icon>
</button>
