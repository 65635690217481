<div class="insights-component-wrapper">
  <mat-card *ngIf="solutionSpace === 'humidity'" class="shadow-card pl">
    <div>
      <div class="flex-space-between">
        <div class="hum-title mb-auto mt-auto">Humidity and Temperature</div>
        <polytech-ui-date-picker
          (dateChanged)="updateDateRange($event, 'HUMIDITY')"
          initialTimeRange="14"
          [canAlwaysConfirm]="true"
          timeZone="Africa/Abidjan"
          popupPosition="right"
          #datePickerUtilRef
        >
          <dt (click)="datePickerUtilRef.setDefinedRange('14d')">Last 14 days</dt>
          <dt (click)="datePickerUtilRef.setDefinedRange('3m')">Last 3 months</dt>
          <dt (click)="datePickerUtilRef.setDefinedRange('6m')">Last 6 months</dt>
          <dt (click)="selectAllData()">All Time</dt>
        </polytech-ui-date-picker>
      </div>
      <div class="hum-desc">
        The chart provides a picture of the humidity and temperature conditions over a given period of time.
      </div>
      <div>
        <app-meteorology-chart
          [showLegend]="false"
          [hasHumidityThreshold]="true"
          [hasTempThreshold]="true"
          [data]="humidityMetaData"
        ></app-meteorology-chart>
      </div>
      <div class="label-container">
        <div class="humidity-details grid-gap-desktop mt-l pr-m">
          <div *ngFor="let label of humLabels">
            <div [ngStyle]="{'background-color': label.color}" class="label-line"></div>
            <div class="flex-space-between">
              <div class="hum-title pb-l">{{label.title}}</div>
              <div class="hum-title pb-l">{{label.value}}</div>
            </div>
            <div class="flex-space-between pb-xs" *ngFor="let subLabel of label.label">
              <div class="label-desc grey">{{subLabel.key}}</div>
              <div class="label-desc">{{subLabel.value}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
  <!-- schock section chart -->

  <mat-card class="shadow-card mt-m pl">
    <div>
      <div class="flex-space-between">
        <div class="hum-title mb-auto mt-auto">Normalized Shock Energy</div>
        <polytech-ui-date-picker
          (dateChanged)="updateDateRange($event, 'ACCELERATION')"
          initialTimeRange="14"
          [canAlwaysConfirm]="true"
          timeZone="Africa/Abidjan"
          popupPosition="right"
          #datePickerChockRef
        >
          <dt (click)="datePickerChockRef.setDefinedRange('14d')">Last 14 days</dt>
          <dt (click)="datePickerChockRef.setDefinedRange('3m')">Last 3 months</dt>
          <dt (click)="datePickerChockRef.setDefinedRange('6m')">Last 6 months</dt>
          <dt (click)="selectAllData()">All Time</dt>
        </polytech-ui-date-picker>
      </div>
      <div class="hum-desc">
        Normalized shock energy (NSE) is a valuable metric for evaluating the impact of shock events on components.
      </div>
      <app-acceleration-chart [data]="accelerationMetData"></app-acceleration-chart>
      <div class="label-container pt-l">
        <div style="background-color: #4682b4" class="label-line"></div>
        <div class="humidity-details">
          <div>
            <div class="hum-title pb-l">NSE</div>
            <div class="flex-space-between pb-xs">
              <div class="label-desc grey">Threshold</div>
              <div class="label-desc">{{accelerationLegends.threshold}} NSE</div>
            </div>
            <div class="flex-space-between pb-xs">
              <div class="label-desc grey">Highest NSE</div>
              <div class="label-desc">{{accelerationLegends.highestNSE}} NSE</div>
            </div>
            <div class="flex-space-between">
              <div class="label-desc grey">Times above threshold</div>
              <div class="label-desc">{{accelerationLegends.aboveThres}} NSE</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
