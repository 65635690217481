<button mat-flat-button color="{{ theme }}" id="{{ id }}">
  <div
    [ngClass]="{
      single_column: !(buttonText && iconUrl) && (buttonText || iconUrl)
    }"
  >
    <svg-icon
      *ngIf="iconUrl"
      src="{{ iconUrl }}"
      [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
      [class]="'icon'"
    ></svg-icon>
    <span *ngIf="buttonText" [ngClass]="{ noIcon: iconUrl && buttonText }">
      {{ buttonText }}
    </span>
  </div>
</button>
